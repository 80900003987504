<template>
  <div class="home-discounts layout-padding mt-8 xl:container xl:mx-auto">
    <div class="flex items-center justify-between ">
      <div class="text-[20px] font-bold uppercase">{{ content.discounts }}</div>
      <router-link to="/search?section=discounts">
        <div class="flex items-center more-btn-home">
          <div class="text-purplePrimary cursor-pointer ltr:mr-3 rtl:ml-3">
            {{ content.more }}
          </div>
          <i class="fa fa-chevron-right text-purplePrimary  "></i></div
      ></router-link>
    </div>
    <div class="grid grid-cols-12 gap-5 lg:gap-10 mt-8 mb-28 xl:mb-8">
      <div
        class="col-span-6 md:col-span-4 lg:col-span-3"
        v-for="discount in discounts"
        :key="discount.id"
      >
        <DiscountCard :discount="discount" />
      </div>
    </div>
  </div>
</template>

<script>
import DiscountCard from "/src/components/UI/Cards/DiscountCard.vue";
export default {
  props: ["discounts"],
  inject: ["content"],
  components: {
    DiscountCard,
  },
};
</script>