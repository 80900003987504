import http from "../../request";

export default {
  categories: async (context) => {
    let response = await http.get("categories", localStorage.getItem("lang"));
    context.commit("setCategories", response);
  },
  categoryResults: async (context, payload) => {
    let formData = new FormData();
    formData.append("category_id", payload);

    let response = await http.post(
      "search",
      localStorage.getItem("lang"),
      formData
    );

    context.commit("setCategoryResults", JSON.parse(response));
  },
};
