<template>
  <div class="relative">
    <i
      class="
        fa
        fav-btn-w
        fa-heart
        h-max
        cursor-pointer
        text-[24px]
        mx-4
        absolute
        rtl:left-2
        ltr:right-2
        top-3
      "
      :class="{ 'fav-icon-fill-w': isFavorite }"
      @click="setFavorites"
    ></i>
    <router-link :to="`/discounts/${discount?.id}`">
      <div class="discount-card rounded-3xl">
        <div
          class="
            h-[130px]
            lg:h-[170px]
            rounded-t-3xl
            w-full
            img
            px-2
            py-3
            flex
            justify-end
          "
          :style="`background:url(${discount?.image})`"
        ></div>
        <div class="bg-yellowPrimary uppercase text-purplePrimary px-3 py-3">
          {{ content.discount }}
        </div>
        <div class="bg-white rounded-b-3xl pb-4 pt-2 px-3">
          <div class="leading-9 pb-1">{{ discount?.title }}</div>
          <div class="flex">
            <div class="ltr:mr-2 rtl:ml-2 text-purplePrimary">
              {{ discount?.new_price }} {{ content.iqd }}
            </div>
            <div class="ltr:mr-2 rtl:ml-2 line-through">
              {{ discount?.old_price }} {{ content.iqd }}
            </div>
          </div>
        </div>
      </div></router-link
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFavorite: false,
      type: "discount",
    };
  },
  props: ["discount"],
  inject: ["content"],
  methods: {
    setFavorites() {
      let data = {
        item: this.discount,
        type: this.type,
      };
      this.$store.dispatch("favorites/setFavorites", data);

      let name = this.type.toString() + "_" + this.discount?.id.toString();
      if (this.favorites[name]) {
        this.isFavorite = true;
      } else {
        this.isFavorite = false;
      }
    },
  },
  computed: {
    favorites() {
      return this.$store.getters["favorites/favorites"];
    },
  },
  mounted() {
    let name = this.type + "_" + this.discount?.id;
    if (this.favorites[name]) {
      this.isFavorite = true;
    } else {
      this.isFavorite = false;
    }
  },
};
</script>