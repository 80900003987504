<template>
  <div
    class="
      review-collapse
      w-full
      rounded-3xl
      transition-all
      duration-300
      ease-in-out
    "
    :class="{ 'review-collapse-opened': reviewOpened }"
  >
    <div
      class="rounded-3xl px-4 transition-all duration-300"
      :class="{ 'review-active': reviewOpened }"
    >
      <div class="flex justify-between items-center py-2">
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <div class="rating ltr:mr-2 rtl:ml-2">
              <div
                class="rating-upper"
                :style="`width:calc( ( ${reviewData?.rating} / 5 ) * 100%)`"
              >
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
              </div>
              <div class="rating-lower">
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
              </div>
            </div>
            <div>( {{ reviewData?.reviews }} {{ content.reviews }} )</div>
          </div>
        </div>
        <div
          v-if="!reviewOpened"
          @click="reviewOpened = !reviewOpened"
          class="
            bg-purplePrimary
            text-white
            overflow-hidden
            transition-all
            duration-300
            px-3
            cursor-pointer
            h-8
            flex
            items-center
            justify-center
            rounded-3xl
          "
        >
          {{ content.review }}
        </div>
        <div
          v-if="reviewOpened"
          @click="reviewOpened = !reviewOpened"
          class="
            text-purplePrimary
            bg-white
            w-8
            h-8
            overflow-hidden
            rounded-full
            transition-all
            duration-300
            cursor-pointer
            flex
            items-center
            justify-center
          "
        >
          <i class="fa fa-times"></i>
        </div>
      </div>
    </div>
    <div class="pb-4 px-4 rounded-x-3xl rounded-b-3xl bg-white">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["reviewData"],
  data() {
    return {
      reviewOpened: false,
    };
  },
  inject: ["content"],
};
</script>