<template>
  <div class="relative">
    <i
      class="
        fa
        fav-btn-w
        fa-heart
        h-max
        cursor-pointer
        text-[24px]
        mx-4
        absolute
        rtl:left-2
        ltr:right-2
        top-3
      "
      :class="{ 'fav-icon-fill-w': isFavorite }"
      @click="setFavorites"
    ></i>
    <div class="discount-card rounded-3xl bg-white">
      <div
        class="
          h-[150px]
          md:h-[160px]
          rounded-3xl
          w-full
          img
          px-2
          py-3
          flex
          justify-end
        "
        :style="`background:url(${upcoming?.image})`"
      ></div>

      <div class="rounded-b-3xl pb-3 pt-2 px-3">
        <div
          class="
            category
            bg-yellowPrimary
            rounded-3xl
            px-3
            mb-1
            w-max-content
            text-purplePrimary text-sm
            py-1
            text-center
          "
        >
          {{ upcoming.type }}
        </div>
        <div class="font-bold">{{ upcoming?.title }}</div>
        <div class="text-xs my-1">{{ content.by }} {{upcoming[0]?.entity}}</div>
        <div class="font-bold text-purplePrimary text-xs">
          {{ upcoming?.valid_from }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFavorite: false,
      type: "upcoming",
    };
  },
  props: ["upcoming"],
  inject: ["content"],
  methods: {
    setFavorites() {
      let data = {
        item: this.upcoming,
        type: this.type,
      };
      this.$store.dispatch("favorites/setFavorites", data);

      let name = this.type.toString() + "_" + this.upcoming.id.toString();
      if (this.favorites[name]) {
        this.isFavorite = true;
      } else {
        this.isFavorite = false;
      }
    },
  },
  computed: {
    favorites() {
      return this.$store.getters["favorites/favorites"];
    },
  },
  mounted() {
    let name = this.type + "_" + this.upcoming.id;
    if (this.favorites[name]) {
      this.isFavorite = true;
    } else {
      this.isFavorite = false;
    }
  },
};
</script>