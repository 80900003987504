export default {
  setAll: (state, payload) => {
    state.all = payload;
  },
  setDiscounts: (state, payload) => {
    state.discounts = payload;
  },
  setOffers: (state, payload) => {
    state.offers = payload;
  },
  setSales: (state, payload) => {
    state.sales = payload;
  },
  setShops: (state, payload) => {
    state.shops = payload;
  },
  setSupermarkets: (state, payload) => {
    state.supermarkets = payload;
  },
  setUpcomings: (state, payload) => {
    state.upcomings = payload;
  },
};
