<template>
  <div class="py-4 mt-3 flex items-center justify-center">
    <div class="flex items-center justify-between">
      <nav
        class="relative z-0 inline-flex rounded-md -space-x-px bg-white p-2"
        aria-label="Pagination"
      >
        <div
          @click="goto(data?.links?.prev)"
          class="
            relative
            inline-flex
            items-center
            px-2
            py-2
            rounded-l-md
            text-sm
            font-medium
            text-gray-500
            hover:bg-gray-50
          "
        >
          <i class="fas fa-chevron-left text-purplePrimary"></i>
        </div>
        <div
          @click="goto(data?.links?.first)"
          aria-current="page"
          class="pagination-link hover:bg-gray-50"
          :class="{ active: data?.meta?.current_page === 1 }"
        >
          1
        </div>
        <a
          v-if="data?.meta?.last_page > 5 && 1 < data?.meta?.current_page - 2"
          to="#"
          class="pagination-link hover:bg-gray-50"
        >
          ...
        </a>
        <div
          v-for="(item, index) in links"
          :key="index"
          @click="goto(item.label)"
          class="pagination-link"
          :class="{ active: data?.meta?.current_page == item.label }"
        >
          {{ item.label }}
        </div>
        <a
          v-if="
            data?.meta?.last_page > 5 &&
            data?.meta?.last_page > data?.meta?.current_page + 2
          "
          to="#"
          class="pagination-link hover:bg-gray-50"
        >
          ...
        </a>
        <div
          v-if="data?.meta?.last_page > 1"
          @click="goto(data?.meta?.last_page)"
          class="pagination-link hover:bg-gray-50"
          :class="{ active: data?.meta?.current_page == data?.meta?.last_page }"
        >
          {{ data?.meta?.last_page }}
        </div>
        <div
          @click="goto(data?.links?.next)"
          class="
            relative
            inline-flex
            items-center
            px-2
            py-2
            rounded-r-md
            text-sm
            font-medium
            text-gray-500
            hover:bg-gray-50
          "
        >
          <i class="fas fa-chevron-right text-purplePrimary"></i>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    // links() {
    //   let from = 2;
    //   if (this.data?.meta?.current_page > 2) {
    //     from = this.data?.meta?.current_page - 1;
    //   }
    //   let last = from + 3;
    //   if (this.data?.meta?.last_page < this.data?.meta?.current_page + 3) {
    //     last = this.data?.meta?.last_page;
    //     from = last - 3;
    //     if (from <= 1) from = 2;
    //   }
    //   let links = [];
    //   for (let i = from; i <= last; i++) {
    //     links.push(i);
    //   }
    //   links = links.slice(from, last);
    //   return links;
    // },
    links() {
      let from = 2;
      if (this.data?.meta?.current_page > 2) {
        from = this.data?.meta?.current_page - 1;
      }
      let last = from + 3;
      if (this.data?.meta?.last_page < this.data?.meta?.current_page + 3) {
        last = this.data?.meta?.last_page;
        from = last - 3;
        if (from <= 1) from = 2;
      }
      let links = this.data?.meta?.links.slice(from, last);
      return links;
    },
  },
  methods: {
    goto(page) {
      if (typeof page != "number") {
        if (page.includes("https://api.kweharzana.com/api/search?")) {
          page = page.replace(
            "https://api.kweharzana.com/api/search?page=",
            ""
          );
        }
        if (page.includes("https://api.kweharzana.com/api/shops")) {
          page = page.replace("https://api.kweharzana.com/api/shops", "");
        }
      }
      if (page == null) {
        return;
      } else {
        // int to string
        if (typeof page === "number") {
          page = page.toString();
        }
        let pageNumber = page.replace("?page=", "");
        this.$router.push({
          query: {
            section: this.$route.query.section,
            page: pageNumber,
          },
        });
      }
    },
  },
};
</script>