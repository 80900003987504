import http from "../../request";

export default {
  orders: async (context) => {
    let response = await http.get(
      "orders",
      localStorage.getItem("lang"),
      context.rootState.token
    );
    context.commit("setOrders", response);
  },
  createOrder: async (context, payload) => {
    let form = new FormData();
    form.append("item_id", payload.id);
    form.append("quantity", payload.quantity);
    form.append("address", payload.address);
    let response = await http.post(
      "orders",
      localStorage.getItem("lang"),
      form,
      context.rootState.token
    );
    return response;
  },
};
