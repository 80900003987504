<template>
  <div class="grid grid-cols-12 gap-5 mt-8 mb-24">
    <div
      class="
        col-span-6
        lg:col-span-4
        h-[220px]
        w-full
        bg-skeleton
        animate-pulse
        rounded-2xl
        items-end
        flex
      "
      v-for="n in 12"
      :key="n"
    >
      <div
        class="h-[40px] w-full bg-purple-100 animate-pulse rounded-b-2xl"
      ></div>
    </div>
  </div>
</template>

