<template>
  <div class="favorites mb-28 xl:mb-8 xl:container xl:mx-auto">
    <div
      class="
        xl:hidden
        bg-purplePrimary
        h-[70px]
        rounded-b-3xl
        flex
        items-center
        justify-center
        text-[20px]
        font-bold
        text-white
        relative
      "
      :class="{ 'phone-web-title': deviceType !== 'phone' }"
    >
      <i
        class="
          fa fa-chevron-left
          text-white
          absolute
          ltr:left-[3vw]
          rtl:right-[3vw]
          text-lg
          cursor-pointer
        "
        @click="$router.go(-1)"
      ></i>

      {{ content.myFavorites }}
    </div>
    <!-- <div
      class="
        xl:flex
        hidden
        layout-padding
        text-[24px]
        font-bold
        mt-8
        text-purplePrimary
      "
    >
      {{ content.myFavorites }}
    </div>
    <Breadcrumb :firstTitle="content.favorites" /> -->
    <div class="xl:min-h-[70vh]">
      <div class="layout-padding">
        <div
          class="bg-purple-100 border-l-4 border-purple-600 mt-8 p-4"
          role="alert"
          v-if="Object.keys(favorites).length == 0"
        >
          {{ content.noFavorites }}
        </div>
      </div>

      <div class="mt-8 mb-8 layout-padding grid grid-cols-12 gap-x-5 gap-y-5">
        <FavoriteCard
          v-for="favorite in favorites"
          :key="favorite"
          :favorite="favorite"
        />
      </div>
    </div>

    <div
      class="mb-16 text-base font-bold layout-padding"
      v-if="Object.keys(favorites).length > 0"
    >
      {{ content.youWillSave }} : %{{ parseFloat(willSave).toFixed(2) }}
    </div>
  </div>
</template>

<script>
import FavoriteCard from "/src/components/UI/Cards/FavoriteCard.vue";
export default {
  data() {
    return {
      willSave: 0,
      offersCount: 0,
    };
  },
  inject: ["content"],
  components: { FavoriteCard },
  methods: {
    saveCalc() {
      for (let i = 0; i < Object.keys(this.favorites).length; i++) {
        if (
          this.favorites[Object.keys(this.favorites)[i]].payload.name.includes(
            "offer"
          ) ||
          this.favorites[Object.keys(this.favorites)[i]].payload.name.includes(
            "upcoming"
          )
        ) {
          this.offersCount = this.offersCount + 1;
        }
        if (
          this.favorites[Object.keys(this.favorites)[i]].payload.name.includes(
            "discount"
          )
        ) {
          let discountPercentage =
            ((this.favorites[Object.keys(this.favorites)[i]].payload.data
              .old_price -
              this.favorites[Object.keys(this.favorites)[i]].payload.data
                .new_price) /
              this.favorites[Object.keys(this.favorites)[i]].payload.data
                .old_price) *
            100;
          this.willSave = this.willSave + discountPercentage;
        } else if (
          this.favorites[Object.keys(this.favorites)[i]].payload.name.includes(
            "sale"
          )
        ) {
          this.willSave =
            this.willSave +
            parseInt(
              this.favorites[Object.keys(this.favorites)[i]].payload.data
                .percentage
            );
        }
      }
      let lengthWithoutOffers =
        Object.keys(this.favorites).length - this.offersCount;
      this.willSave = this.willSave / lengthWithoutOffers;
    },
  },
  watch: {
    favorites() {
      if (this.favorites) {
        this.offersCount = 0;
        this.willSave = 0;
        this.saveCalc();
      }
    },
  },
  computed: {
    favorites() {
      return this.$store.getters["favorites/favorites"];
    },
    deviceType() {
      return localStorage.getItem("deviceType");
    },
  },

  mounted() {
    this.saveCalc();
  },
};
</script>