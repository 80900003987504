import Register from "../views/Auth/Register.vue";
import Login from "../views/Auth/Login.vue";
import ForgetPassword from "../views/Auth/ForgetPassword.vue";

export default [
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: { requiresUnAuth: true },
  },  {
    path: "/forgetpassword",
    name: "forgetpassword",
    component: ForgetPassword,
    meta: { requiresUnAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { requiresUnAuth: true },
  },

];
