<template>
<div :class="{'hide-nav-on-mobile-web':deviceType!='phone'}">
  <Modal :modalOpened="languagesOpened" @close="languagesOpened = false">
    >
    <div class="h-[max-content] w-[80vw] md:w-[400px] bg-white rounded-3xl">
      <div
        class="
          bg-purplePrimary
          h-20
          text-white
          flex
          items-center
          justify-center
          rounded-t-3xl
        "
      >
        {{ content.chooseLanguage }}
      </div>
      <div
        @click="changeLanguage('en')"
        class="
          flex
          justify-between
          layout-padding-2
          h-16
          cursor-pointer
          items-center
        "
        :class="{ 'text-purplePrimary': currentLanguage == 'en' }"
      >
        <div class="english-font">{{ content.english }}</div>
        <i class="fa fa-check" v-if="currentLanguage == 'en'"></i>
      </div>
      <div
        @click="changeLanguage('ku')"
        class="
          flex
          justify-between
          layout-padding-2
          h-16
          cursor-pointer
          items-center
          border-y border-black border-opacity-5
        "
        :class="{ 'text-purplePrimary': currentLanguage == 'ku' }"
      >
        <div class="kurdish-font">{{ content.kurdish }}</div>
        <i class="fa fa-check" v-if="currentLanguage == 'ku'"></i>
      </div>
      <div
        @click="changeLanguage('ar')"
        class="
          flex
          justify-between
          layout-padding-2
          h-16
          cursor-pointer
          items-center
        "
        :class="{ 'text-purplePrimary': currentLanguage == 'ar' }"
      >
        <div class="kurdish-font">{{ content.arabic }}</div>
        <i class="fa fa-check" v-if="currentLanguage == 'ar'"></i>
      </div></div
  ></Modal>
  <div
    id="mobile-nav"
    class="
      fixed
      bottom-0
      left-0
      h-16
      rounded-t-3xl
      bg-white
      w-full
      xl:hidden
      text-lg
      z-10 
    "
  >
    <div class="flex justify-between layout-padding items-center h-full">
      <router-link to="/favorites">
        <div
          class="cursor-pointer flex flex-col items-center"
          :class="{ 'text-purplePrimary': currentRoute == '/favorites' }"
        >
          <i class="fa fa-heart"></i>
          <div class="ltr:text-[10px] rtl:text-[14px] h-4">
            {{ content.favorite }}
          </div>
        </div></router-link
      >
      <router-link to="/search?section=all">
        <div
          class="cursor-pointer flex flex-col items-center"
          :class="{ 'text-purplePrimary': currentRoute == '/search' }"
        >
          <i class="fa fa-search"></i>
          <div class="ltr:text-[10px] rtl:text-[14px] h-4">
            {{ content.search }}
          </div>
        </div></router-link
      >
      <router-link to="/">
        <div
          class="
            cursor-pointer
            flex flex-col
            items-center
            justify-center
            mt-[-56px]
            w-[56px]
            h-[56px]
            rounded-full
            bg-yellowPrimary
          "
          :class="{ 'text-purplePrimary': currentRoute == '/' }"
        >
          <i class="fa fa-home text-2xl"></i>
          <!-- <div class="  ltr:text-[10px] rtl:text-[14px]   h-4">{{ content.home }}</div> -->
        </div></router-link
      >
      <div
        class="cursor-pointer flex flex-col items-center"
        @click="languagesOpened = true"
        :class="{ 'text-purplePrimary': languagesOpened }"
      >
        <i class="fa fa-globe"></i>
        <div class="ltr:text-[10px] rtl:text-[14px] h-4">
          {{ content.language }}
        </div>
      </div>
      <router-link to="/settings">
        <div
          class="cursor-pointer flex flex-col items-center"
          :class="{ 'text-purplePrimary': currentRoute == '/settings' }"
        >
          <div class="h-[20px] w-[14px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="20"
              viewBox="0 0 6 22"
            >
              <g
                id="Icon_feather-more-vertical"
                data-name="Icon feather-more-vertical"
                transform="translate(-15 -4.5)"
              >
                <path
                  id="Path_2444"
                  data-name="Path 2444"
                  d="M19.5,18A1.5,1.5,0,1,1,18,16.5,1.5,1.5,0,0,1,19.5,18Z"
                  transform="translate(0 -2.347)"
                  fill="none"
                  :stroke="currentRoute == '/settings' ? '#9c01ff' : '#b54cfc'"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
                <path
                  id="Path_2445"
                  data-name="Path 2445"
                  d="M19.5,7.5A1.5,1.5,0,1,1,18,6a1.5,1.5,0,0,1,1.5,1.5Z"
                  fill="none"
                  :stroke="currentRoute == '/settings' ? '#9c01ff' : '#b54cfc'"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
                <path
                  id="Path_2446"
                  data-name="Path 2446"
                  d="M19.5,28.5A1.5,1.5,0,1,1,18,27,1.5,1.5,0,0,1,19.5,28.5Z"
                  transform="translate(0 -4.693)"
                  fill="none"
                  :stroke="currentRoute == '/settings' ? '#9c01ff' : '#b54cfc'"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                />
              </g>
            </svg>
          </div>
          <div class="ltr:text-[10px] rtl:text-[14px] h-4">
            {{ content.settings }}
          </div>
        </div></router-link
      >
    </div>
  </div></div>
</template>

<script>
export default {
  data() {
    return {
      languagesOpened: false,
    };
  },
  methods: {
    changeLanguage(lang) {
      localStorage.setItem("lang", lang);

      this.$router.go();
    },
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    deviceType(){
      return localStorage.getItem('deviceType')
    },
    currentLanguage() {
      return localStorage.getItem("lang");
    },
  },
  inject: ["content"],
};
</script>