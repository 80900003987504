import http from "../../request";

export default {
  upcoming: async (context, payload) => {
    let currentPage = 1;
    if (payload) {
      currentPage = payload;
    }
    let response = await http.get(
      `upcoming?page=`,
      `${localStorage.getItem("lang")}?page=${currentPage}`
    );
    context.commit("setUpcoming", response);
  },
};
