import http from "../../request";

export default {
  search: async (context, payload) => {
    let pageNumber = payload.pageNumbers;
    let formData = new FormData();
    if (payload.discountCategory && payload.section == "discounts") {
      formData.append("category_id", payload.discountCategory);
    }

    if (payload.saleCategory && payload.section == "sales") {
      formData.append("category_id", payload.saleCategory);
    }
    if (payload.search) {
      formData.append("search", payload.search);
    }
    if (payload.section == "supermarkets" || payload.section == "shops") {
      formData.append("search", payload.search);
    }
    if (payload.section != "all") {
      formData.append("section", payload.section);
    }
    let response = await http.post(
      "search",
      `${localStorage.getItem("lang")}&page=${pageNumber}`,
      formData
    );

    if (payload.section === "all") {
      context.commit("setAll", JSON.parse(response));
    } else if (payload.section === "supermarkets") {
      context.commit("setSupermarkets", JSON.parse(response));
    } else if (payload.section === "shops") {
      context.commit("setShops", JSON.parse(response));
    } else if (payload.section === "offers") {
      context.commit("setOffers", JSON.parse(response));
    } else if (payload.section === "upcoming") {
      context.commit("setUpcomings", JSON.parse(response));
    } else if (payload.section === "sales") {
      context.commit("setSales", JSON.parse(response));
    } else if (payload.section === "discounts") {
      context.commit("setDiscounts", JSON.parse(response));
    }
  },
};
