<template>
  <ErrorAlert
    :show="alerts.EmailUsedAlert"
    @close="alerts.EmailUsedAlert = false"
    :message="alerts.message"
  ></ErrorAlert>
  <div class="login xl:container xl:mx-auto">
    <div
      class="
        bg-purplePrimary
        h-[90px]
        relative
        z-0
        section-header
        xl:hidden
        bg-purplePrimary
      "
    >
      <div class="overlay w-full h-[90px] absolute left-0 top-0"></div>
      <div
        class="
          h-[70px]
          flex
          items-center
          justify-center
          text-[20px]
          font-bold
          text-white
        "
      >
        <div class="relative">{{ content.register }}</div>
      </div>
    </div>

    <div
      class="
        lg:my-4
        pb-16
        rounded-3xl
        layout-padding
        w-full
        lg:bg-white
        mt-[-25px]
        lg:mt-4
        bg-gray2
        rounded-t-3xl
        lg:w-6/12
        relative
        mx-auto
        z-[1]
        pt-1
      "
    >
      <div class="mt-[5vh]">
        <div class="xl:block hidden">
          <div class="text-sm text-[24px] font-bold mt-8 text-purplePrimary">
            {{ content.register }}
          </div>
        </div>
        <div class="mb-1 mt-4 text-sm">
          {{ content.fullname }}
        </div>
        <input
          type="text"
          :placeholder="content.fullname"
          v-model="registerData.fullname"
          :class="{ 'field-required': errors.nameError }"
        />
        <div class="text-red-500 mb-4">{{ errors.nameError }}</div>

        <div class="mb-1 mt-4 text-sm">
          {{ content.email }}
        </div>
        <input
          type="email"
          :placeholder="content.email"
          v-model="registerData.email"
          :class="{ 'field-required': errors.emailError }"
        />
        <div class="text-red-500 mb-4">{{ errors.emailError }}</div>
        <div class="mb-1 mt-4 text-sm">
          {{ content.phoneNumber }}
        </div>
        <input
          type="tel"
          :placeholder="content.phoneNumber"
          v-model="registerData.phone"
          :class="{ 'field-required': errors.phoneError }"
        />
        <div class="text-red-500 mb-4">{{ errors.phoneError }}</div>

        <div class="mb-1 mt-4 text-sm">
          {{ content.password }}
        </div>
        <input
          type="password"
          :placeholder="content.password"
          v-model="registerData.password"
          :class="{ 'field-required': errors.passwordError }"
        />
        <div class="text-red-500 mb-4">{{ errors.passwordError }}</div>
        <div class="mb-1 mt-4 text-sm">
          {{ content.confirmPassword }}
        </div>
        <input
          type="password"
          :placeholder="content.confirmPassword"
          v-model="registerData.confirmPassword"
          :class="{ 'field-required': errors.passwordError }"
        />
        <div class="text-red-500 mb-4">{{ errors.passwordError }}</div>

        <div
          @click.prevent="register"
          class="
            bg-purplePrimary
            text-white
            overflow-hidden
            transition-all
            duration-300
            px-3
            cursor-pointer
            h-12
            mt-4
            flex
            items-center
            justify-center
            rounded-3xl
          "
        >
          {{ content.register }}
        </div>
      </div>
      <div class="mt-4 text-sm">
        {{ content.alreadyHaveAccount }}
        <router-link
          to="/login"
          class="font-bold hover:text-purple-600 transition-all duration-300"
          >{{ content.login }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  data() {
    return {
      registerData: {
        email: null,
        password: null,
        confirmPassword: null,
        fullname: null,
        phone: null,
      },
      errors: {
        emailError: null,
        passwordError: null,
        nameError: null,
        phoneError: null,
      },
      alerts: {
        EmailUsedAlert: false,
        phoneUsedAlert: false,
      },
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters['isAuth'];
    },
  },
  watch:{
      isAuth(){
        if(this.isAuth){
          this.$router.push('/');
        }
      }
  },
  methods: {
    async register() {
      this.errors.emailError = this.validateEmail(this.registerData.email);
      this.errors.passwordError = this.validatePassword(
        this.registerData.password,
        this.registerData.confirmPassword
      );
      this.errors.nameError = this.validateRequired(this.registerData.fullname);
      this.errors.phoneError = this.validatePhone(this.registerData.phone);

      if (
        !this.errors.emailError &&
        !this.errors.passwordError &&
        !this.errors.nameError &&
        !this.errors.phoneError
      ) {
        try {
          this.errors = {
            emailError: null,
            passwordError: null,
          };
           await this.$store.dispatch(
            "auth/register",
            this.registerData
          );
        } catch (error) {
          let errors = JSON.parse(JSON.parse(error).response).errors;
          if (errors.email) {
            if (errors.email[0] == "Email already exists") {
              this.alerts.EmailUsedAlert = true;
              this.alerts.message = this.content.emailAlreadyUsed;
            }
            if (errors.email[0] == "Email is invalid") {
              this.alerts.EmailUsedAlert = true;
              this.alerts.message = this.content.EmailIsInvalid;
            }
          }
          if (errors.phone_number) {
            if (errors.phone_number[0] == "Phone number already exists") {
              this.alerts.phoneUsedAlert = true;
              this.alerts.message = this.content.phoneAlreadyUsed;
            }
          }
        }
      }
    },
    validateRequired(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else {
        return null;
      }
    },
    validateEmail(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else if (!inputed.includes("@") || !inputed.includes(".")) {
        return this.content.emailFormat;
      } else {
        return null;
      }
    },
    validatePhone(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else if (!inputed.startsWith(0)) {
        return this.content.phoneFormat;
      } else if (inputed.length < 11 || inputed.length > 11) {
        return this.content.phoneFormat;
      } else {
        return null;
      }
    },
    validatePassword(inputed, inputed2) {
      if (inputed != inputed2) {
        return this.content.confirmPasswordFail;
      } else {
        var lowerCaseLetters = /[a-z]/g;
        var upperCaseLetters = /[A-Z]/g;
        var numbers = /[0-9]/g;
        if (inputed === "" || inputed == null) {
          return this.content.required;
        } else if (
          inputed.length < 8 ||
          !inputed.match(lowerCaseLetters) ||
          !inputed.match(upperCaseLetters) ||
          !inputed.match(numbers)
        ) {
          this.passwordError = this.content.passwordFormat;
          return this.content.passwordFormat;
        } else {
          return null;
        }
      }
    },
  },
};
</script>