<template>
  <div class="favorites xl:container xl:mx-auto">
    <div
      class="
        xl:hidden
        bg-purplePrimary
        h-[70px]
        rounded-b-3xl
        flex
        items-center
        justify-center
        text-[20px]
        font-bold
        text-white
        relative
      "
      :class="{ 'phone-web-title': deviceType !== 'phone' }"
    >
      <i
        class="
          fa fa-chevron-left
          text-white
          absolute
          ltr:left-[3vw]
          rtl:right-[3vw]
          text-lg
          cursor-pointer
        "
        @click="$router.go(-1)"
      ></i>

      {{ content.upcoming }}
    </div>
    <div
      class="
        xl:flex
        hidden
        layout-padding
        text-[24px]
        font-bold
        mt-8
        text-purplePrimary
      "
    >
      {{ content.upcoming }}
    </div>
    <Breadcrumb :firstTitle="content.upcoming" />
    <div class="mt-8 mb-16 layout-padding">
      <div class="home-discounts mt-8 xl:min-h-[60vh]">
        <div class="grid grid-cols-12 gap-5 mt-8 mb-24">
          <div
            class="col-span-6 md:col-span-4"
            v-for="upcoming in upcomings?.data"
            :key="upcoming.id"
          >
            <UpcomingCard
              :upcoming="upcoming"
              image="/static/images/discount-product.png"
              title="Silky Pink Dress"
              by="By Maximall"
              from="22/2/2022"
            />
          </div>
        </div>
      </div>
      <Pagination v-if="upcomings?.meta?.last_page != 1" :data="upcomings" />
    </div>
  </div>
</template>

<script>
import UpcomingCard from "/src/components/UI/Cards/UpcomingCard.vue";

export default {
  inject: ["content"],
  components: { UpcomingCard },
  methods: {
    getUpcomings(page) {
      this.$store.dispatch("upcoming/upcoming", page);
    },
  },
  computed: {
    upcomings() {
      return this.$store.getters["upcoming/upcoming"];
    },
    deviceType() {
      return localStorage.getItem("deviceType");
    },
  },
  watch: {
    "$route.query.page": {
      handler: function (value) {
        if (this.$route.query.page) {
          this.getUpcomings(value);
        }
      },
    },
    deep: true,
    immediate: true,
  },
};
</script>