<template>
  <div class="favorites relative xl:pb-12 pb-24">
    <div
      class="
        xl:hidden
        bg-purplePrimary
        h-[70px]
        rounded-b-3xl
        flex
        items-center
        justify-center
        text-[20px]
        font-bold
        text-white
        relative
      "
      :class="{ 'phone-web-title': deviceType !== 'phone' }"
    >
      <i
        class="
          fa fa-chevron-left
          text-white
          absolute
          ltr:left-[3vw]
          rtl:right-[3vw]
          text-lg
          cursor-pointer
        "
        @click="$router.go(-1)"
      ></i>

      {{ title }}
    </div>
    <div
      class="
        xl:flex xl:container xl:mx-auto
        hidden
        layout-padding
        text-[24px]
        font-bold
        mt-8
        text-purplePrimary
      "
    >
      {{ title }}
    </div>
    <Breadcrumb :firstTitle="title" />
    <div class="mt-5 mb-12" v-if="allDiscounts.length > 0">
      <div
        class="
          flex
          items-center
          justify-between
          text-purplePrimary
          layout-padding
          xl:container xl:mx-auto
        "
      >
        <div class="text-base font-bold">
          {{ content?.discounts }}
        </div>
        <router-link :to="`/search?section=discounts&category=${id}`">
          <div class="flex items-center more-btn-home">
            <div class="text-purplePrimary cursor-pointer ltr:mr-3 rtl:ml-3">
              {{ content.more }}
            </div>
            <i class="fa fa-chevron-right text-purplePrimary"></i>
          </div>
        </router-link>
      </div>
      <div
        class="
          relative
          grid grid-cols-12
          gap-5
          mt-5
          xl:container xl:mx-auto
          layout-padding
        "
      >
        <div
          v-for="discount in allDiscounts.slice(0, 4)"
          :key="discount?.id"
          class="col-span-6 lg:col-span-4 xl:col-span-3"
        >
          <DiscountCard :discount="discount" />
        </div>
      </div>
    </div>
    <div class="mt-5 mb-12" v-if="allSales.length > 0">
      <div
        class="
          flex
          items-center
          justify-between
          text-purplePrimary
          layout-padding
          xl:container xl:mx-auto
        "
      >
        <div class="text-base font-bold">
          {{ content?.sales }}
        </div>
        <router-link :to="`/search?section=sales&category=${id}`">
          <div class="flex items-center more-btn-home">
            <div class="text-purplePrimary cursor-pointer ltr:mr-3 rtl:ml-3">
              {{ content.more }}
            </div>
            <i class="fa fa-chevron-right text-purplePrimary"></i>
          </div>
        </router-link>
      </div>
      <div
        class="
          relative
          grid grid-cols-12
          gap-5
          mt-5
          xl:container xl:mx-auto
          layout-padding
        "
      >
        <div
          v-for="sale in allSales.slice(0, 4)"
          :key="sale?.id"
          class="col-span-6 lg:col-span-4 xl:col-span-3"
        >
          <SalesCard :sale="sale" />
        </div>
      </div>
    </div>
    
    <div class="layout-padding xl:container xl:mx-auto">
      <div
        v-if="
          allDiscounts.length == 0 &&
          allSales.length == 0
        "
        class="bg-purple-100 border-l-4 border-purple-600 mt-8 p-4"
        role="alert"
      >
        {{ content.noProducts }}
      </div>
    </div>
  </div>
</template>

<script>
import DiscountCard from "/src/components/UI/Cards/DiscountCard.vue";
import SalesCard from "/src/components/UI/Cards/SalesCard.vue";

export default {
  props: ["id"],
  data() {
    return {
      title: "",
      allSales: [],
      allDiscounts: [],
      breakpoints: {
        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        20: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
      },
    };
  },

  inject: ["content"],
  components: {
    DiscountCard,
    SalesCard,
  },
  computed: {
    categoryResults() {
      return this.$store.getters["categories/categoryResults"];
    },
    categories() {
      return this.$store.getters["categories/categories"];
    },
    deviceType() {
      return localStorage.getItem("deviceType");
    },
  },
  watch: {
    categoryResults(){
      if(this.categoryResults){
        this.categoryResults?.data?.filter(category => {
          if(category.type=='Discount') {this.allDiscounts.push(category);}
          if(category.type=='Sale') {this.allSales.push(category);}
        })
      }
            

    }
  },

  mounted() {
    if (this.categories) {
      this.categories?.data?.filter((category) => {
        if (category.id == this.id) {
          this.title = category.title;
        }
      });
    }
    this.$store.dispatch("categories/categoryResults", this.id);
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.add("hidden");

  },
  unmounted() {
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.remove("hidden");
  },
};
</script>

