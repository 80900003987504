<template>
  <div class="layout-padding">
    <div
      class="
        mt-3
        filter-collapse
        bg-white
        rounded-3xl
        transition-all
        duration-300
        ease-in-out
      "
      :class="{ 'filter-collapse-opened': openFilter }"
    >
      <div
        class="bg-white rounded-3xl layout-padding transition-all duration-300"
        @click="openFilter = !openFilter"
        :class="{ 'filter-active': openFilter }"
      >
        <div class="flex justify-between items-center py-3 title">
          <div>{{ content.filterItems }}</div>
          <i class="fa fa-chevron-down text-purplePrimary"></i>
        </div>
      </div>
      <div class="pb-4 layout-padding">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openFilter: false,
    };
  },
  inject: ["content"],
};
</script>