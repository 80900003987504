<template>
  <div class="about xl:container xl:mx-auto">
    <div
      class="
        bg-purplePrimary
        xl:hidden
        h-[70px]
        rounded-b-3xl
        flex
        items-center
        justify-center
        text-[20px]
        font-bold
        text-white
        relative
      "
      :class="{ 'phone-web-title': deviceType !== 'phone' }"
    >
      <i
        class="
          fa fa-chevron-left
          text-white
          absolute
          ltr:left-[3vw]
          rtl:right-[3vw]
          text-lg
          cursor-pointer
        "
        @click="$router.push('/settings')"
      ></i>

      {{ content.aboutUs }}
    </div>
    <!-- <div class="xl:block hidden">
      <div
        class="
          xl:flex
          hidden
          layout-padding
          text-[24px]
          font-bold
          mt-8
          text-purplePrimary
        "
      >
        {{ about?.data?.title }}
      </div>
      <Breadcrumb :firstTitle="about?.data?.title" />
    </div> -->
    <div class="mt-8 mb-16 layout-padding">
      <div
        class="
          bg-white
          rounded-2xl
          w-full
          layout-padding-block layout-padding
          min-h-[300px]
        "
      >
        <div v-html="about?.data?.description" class="font-normal"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  computed: {
    about() {
      return this.$store.getters["about/about"];
    },
    deviceType() {
      return localStorage.getItem("deviceType");
    },
  },
  mounted() {
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.add("hidden");
  },
  unmounted() {
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.remove("hidden");
  },
};
</script>