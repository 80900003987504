import http from "../../request";

export default {
  discounts: async (context) => {
    let response = await http.get("discounts", localStorage.getItem("lang"));
    context.commit("setDiscounts", response);
  },
  discountDetails: async (context, payload) => {
    let response = await http.get(
      `discounts/${payload}`,
      localStorage.getItem("lang")
    );
    context.commit("setDiscountDetails", response);
  },
  clearDiscountDetails: (context) => {
    context.commit("setDiscountDetails", null);
  },
};
