<template>
  <div
    class="
      section-card-two relative overflow-hidden
      rounded-3xl
      bg-white
      ltr:mr-2
      rtl:ml-2
      md:ltr:mr-6 md:rtl:ml-6
      h-40
      md:h-40
      text-white
    "
    :class="{ 'margin-0': noMargin }"
  >
    <router-link :to="to">
      <div class="h-40 w-full 
      md:h-40 absolute top-0 left-0 background" :style="`background:url(${image})`"></div>
      <div
        class="
          overlay
          rounded-3xl z-[1] relative
          py-2
          px-4
          h-full
          w-full
          flex
          items-center
          justify-center
        "
      >
        <div class="font-bold text-base lg:text-xl">{{ title }}</div>
      </div></router-link
    >
  </div>
</template>

<script>
export default {
  props: ["title", "to", "image", "noMargin"],
};
</script>