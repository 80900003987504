<template>

  <div class="favorites xl:container xl:mx-auto">
    <div
      class="bg-purplePrimary h-[90px] relative z-0 section-header xl:hidden"
      :style="`background:url(/static/images/mall.png)`"
    >
      <div class="overlay w-full h-[90px] absolute left-0 top-0"></div>
      <div
        class="
          h-[70px]
          flex
          items-center
          justify-center
          text-[20px]
          font-bold
          text-white
        "
      >
        <i
          class="
            fa fa-chevron-left
            text-white
            absolute
            ltr:left-[3vw]
            rtl:right-[3vw]
            text-lg
            cursor-pointer
          "
          @click="$router.go(-1)"
        ></i>

        <div class="relative">{{ content.supermarkets }}</div>
      </div>
    </div>
    <div class="xl:block hidden">
      <div class="layout-padding text-[24px] font-bold mt-8 text-purplePrimary">
        {{ content.supermarkets }}
      </div>
      <Breadcrumb :firstTitle="content.supermarkets" /> 
    </div>
    <div
      class="
        mt-[-25px]
        mb-16
        layout-padding
        bg-grayBody
        relative
        z-[1]
        pt-1
        rounded-t-3xl
      "
    >
      <div class="home-discounts mt-8" v-if="supermarkets">
        <div class="grid grid-cols-12 gap-5 mt-4 mb-28 xl:mb-8">
          <div
            class="col-span-12 md:col-span-6 lg:col-span-4"
            :class="
              supermarket?.discounted_items > 0 ||
                supermarket?.sales_items > 0 ||
                supermarket?.offers_items > 0?'block':'hidden'
                
            "
            v-for="supermarket in supermarkets.data"
            :key="supermarket?.id"
          >
            <SupermarketsCard :supermarket="supermarket" />
          </div>
        </div>
      </div>
      <MarketsSkeleton v-else />
      <Pagination
        v-if="supermarkets?.meta?.last_page != 1"
        :data="supermarkets"
      />
    </div>
  </div>
</template>

<script>
import SupermarketsCard from "/src/components/UI/Cards/SupermarketsCard.vue";
import MarketsSkeleton from "/src/components/UI/MarketsSkeleton.vue";

export default {
  inject: ["content"],
  components: { SupermarketsCard, MarketsSkeleton },

  computed: {
    supermarkets() {
      return this.$store.getters["supermarkets/supermarkets"];
    },
  
  },
  methods: {
    loadMarkets(page) {
      this.$store.dispatch("supermarkets/supermarkets", page);
    },
  },
  watch: {
    "$route.query.page": {
      handler: function (value) {
        this.loadMarkets(value);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>