import http from "../../request";

export default {
  offers: async (context) => {
    let response = await http.get("offers", localStorage.getItem("lang"));
    context.commit("setOffers", response);
  },
  offerDetails: async (context, payload) => {
    let response = await http.get(
      `offers/${payload}`,
      localStorage.getItem("lang")
    );
    context.commit("setOfferDetails", response);
  },
  clearOfferDetails: (context) => {
    context.commit("setOfferDetails", null);
  },
};
