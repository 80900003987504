<template>
  <div
    class="
      w-full
      favorite-card
      col-span-12
      md:col-span-6
      xl:col-span-4
      bg-white
      flex
      xl:flex-col
      border border-black border-opacity-5
      rounded-3xl
      mb-4
    "
  >
    <div
      class="
        h-[250px]
        md:h-[200px]
        ltr:rounded-l-3xl
        rtl:rounded-r-3xl
        min-w-[33vw]
        md:min-w-[17vw]
        lg:min-w-[12vw]
        xl:min-w-[12vw] xl:rounded-3xl
        img
      "
      :style="`background:url(${reservation?.restaurant?.image})`"
    ></div>
    <div class="p-3 w-full flex flex-col justify-between">
      <div class="flex justify-between w-full items-center my-2">
        <div>
          {{ reservation?.restaurant?.title }}
        </div>
        <div
          class="
            category
            bg-yellowPrimary 
            rounded-3xl hidden sm:flex
            px-3
            text-purplePrimary text-sm
            py-1
            text-center
          "
        >
         {{ reservation?.date }}
        </div>
      </div>
      <div class="mb-2">
       
        <span
          v-if="reservation?.status == 'pending'"
          class="bg-yellowPrimary text-xs px-2 py-1 rounded-3xl text-purple-600"
          >{{ content[reservation?.status] }}</span
        >
        <span
          v-if="reservation?.status == 'accepted'"
          class="bg-green-700 text-xs px-2 py-1 rounded-3xl text-white"
          >{{ content[reservation?.status] }}</span
        >
        <span
          v-if="reservation?.status == 'rejected'"
          class="bg-red-500 text-xs px-2 py-1 rounded-3xl text-white"
          >{{ content[reservation?.status] }}</span
        >
      </div>

<div class="my-2 flex sm:hidden">         {{ reservation?.date }}
</div>
      <div>{{ content.address }} : {{ reservation?.restaurant?.location }}</div>
        <div class="ltr:mr-2 mt-4 rtl:ml-2 text-purplePrimary">
       {{content.time }} :  {{ reservation?.time }}
        </div>
        <div class="ltr:mr-2 mt-4 rtl:ml-2 ">
          {{ reservation?.number_of_people }} {{ content.persons }}
        </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  props: ["reservation"],
  methods: {
    deleteFavorite() {
      let name = this.favorite.payload.name;
      this.$store.dispatch("favorites/deleteFavorite", name);
    },
  },
};
</script>