<template>
  <router-link :to="to">
    <div
      class="
        border border-black border-opacity-5
        flex
        bg-white
        px-5
        w-max-content
        py-2
        rounded-full
        ltr:mr-2
        rtl:ml-2
        text-purplePrimary
      "
      :class="{ 'active-search-section': searchSection == section }"
    >
      <img
        :src="`/static/images/icon/${icon}.svg`"
        alt="sectionIcon"
        class="ltr:mr-2 rtl:ml-2"
      />
      <div class="uppercase">{{ title }}</div>
    </div></router-link
  >
</template>

<script>
export default {
  props: ["title", "to", "icon", "section", "searchSection"],
};
</script>