import http from "../../request";
import router from "../../router";
export default {
  login: async (context, payload) => {
    let form = new FormData();
    form.append("email", payload.email);
    form.append("password", payload.password);
    let response = await http.post("login", localStorage.getItem("lang"), form);
    let res = JSON.parse(response);
    if (res.status_code == 200) {
      context.dispatch("setAuth", res, { root: true });
      await router.push("/");
    }
    if (res.status_code == 500) {
      return res.message;
    }
  },
  register: async (context, payload) => {
    let form = new FormData();
    form.append("fullname", payload.fullname);
    form.append("email", payload.email);
    form.append("phone_number", payload.phone);
    form.append("password", payload.password);
    form.append("c_password", payload.confirmPassword);

    let response = await http.post(
      "registerUser",
      localStorage.getItem("lang"),
      form
    );
    let res = JSON.parse(response);
    if (res.status_code == 200) {
      context.dispatch("setAuth", res, { root: true });
      await router.push("/");
    } else {
      return response;
    }
  },
  forgetpassword: async (context, payload) => {
    let form = new FormData();
    form.append("email", payload.email);

    let response = await http.post(
      "forgot-password",
      localStorage.getItem("lang"),
      form
    );
    let res = JSON.parse(response);

    return res;
  },
  resetPassword: async (context, payload) => {
    let form = new FormData();
    form.append("email", payload.email);
    form.append("password", payload.password);
    form.append("c_password", payload.confirmPassword);
    form.append("token", payload.token);

    let response = await http.post(
      "reset-password",
      localStorage.getItem("lang"),
      form
    );
    let res = JSON.parse(response);

    return res;
  },
  async logout(context) {
    localStorage.removeItem("token");
    await http.post(
      "logoutUser",
      context.rootState.lang,
      null,
      context.rootState.token
    );
    await router.go();
  },
};
