<template>
  <div
    class="
      w-full
      favorite-card
      col-span-12
      md:col-span-6
      xl:col-span-4
      bg-white
      flex
      xl:flex-col
      border border-black border-opacity-5
      rounded-3xl
      mb-4
    "
  >
    <div
      class="
        h-[160px]
        md:h-[160px]
        ltr:rounded-l-3xl
        rtl:rounded-r-3xl
        min-w-[33vw]
        md:min-w-[17vw]
        lg:min-w-[12vw]
        xl:min-w-[12vw] xl:rounded-3xl
        img
      "
      :style="`background:url(${order?.item?.image})`"
    ></div>
    <div class="p-3 w-full flex flex-col justify-between">
      <div class="flex justify-between w-full items-center my-2">
        <div>
          {{ order?.item?.title }}
        </div>
        <div
          class="
            category
            bg-yellowPrimary
            sm:flex
            hidden
            rounded-3xl
            px-3
            text-purplePrimary text-sm
            py-1
            text-center
          "
        >
          {{ content.quantity }} : {{ order?.quantity }}
        </div>
      </div>
      <div class="mb-2">
        <span
          v-if="order?.status == 'pending'"
          class="bg-yellowPrimary text-xs px-2 py-1 rounded-3xl text-purple-600"
          >{{ content[order?.status] }}</span
        >
        <span
          v-if="order?.status == 'accepted'"
          class="bg-green-700 text-xs px-2 py-1 rounded-3xl text-white"
          >{{ content[order?.status] }}</span
        >
        <span
          v-if="order?.status == 'rejected'"
          class="bg-red-500 text-xs px-2 py-1 rounded-3xl text-white"
          >{{ content[order?.status] }}</span
        >
      </div>
      <div class="my-2 flex sm:hidden">{{ content.quantity }} : {{ order?.quantity }}</div>
      <div>{{ content.deliveryAddress }} : {{ order?.address }}</div>
      <div class="flex mt-2">
        <div class="ltr:mr-2 rtl:ml-2 text-purplePrimary">
          {{ order?.item?.new_price }} {{ content.iqd }}
        </div>
        <div class="ltr:mr-2 rtl:ml-2 line-through">
          {{ order?.item?.old_price }} {{ content.iqd }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  props: ["order"],
  methods: {
    deleteFavorite() {
      let name = this.favorite.payload.name;
      this.$store.dispatch("favorites/deleteFavorite", name);
    },
  },
};
</script>