<template>
  <div class="relative">
    <i
      class="
        fa
        fav-btn-w
        fa-heart
        h-max
        cursor-pointer
        text-[24px]
        mx-4
        absolute
        rtl:left-2
        ltr:right-2
        top-3
      "
      :class="{ 'fav-icon-fill-w': isFavorite }"
      @click="setFavorites"
    ></i>

    <router-link :to="`/sales/${sale.id}`">
      <div class="discount-card rounded-3xl bg-white">
        <div
          class="h-[130px] rounded-3xl w-full img px-2 py-3 flex justify-end"
          :style="`background:url(${sale.image})`"
        ></div>
        <div class="rounded-b-3xl pb-3 pt-2 px-3">
          <div class="font-bold text-base">{{ sale.title }}</div>
          <div class="text-xs my-1">
            {{ content.by }} {{ sale?.entity?.title }}
          </div>
          <div class="font-bold text-purplePrimary text-sm">
            %{{ sale.percentage }}
          </div>
        </div>
      </div></router-link
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      isFavorite: false,
      type: "sale",
    };
  },
  props: ["sale"],
  inject: ["content"],
  methods: {
    setFavorites() {
      let data = {
        item: this.sale,
        type: this.type,
      };
      this.$store.dispatch("favorites/setFavorites", data);

      let name = this.type + "_" + this.sale.id;
      if (this.favorites[name]) {
        this.isFavorite = true;
      } else {
        this.isFavorite = false;
      }
    },
  },
  computed: {
    favorites() {
      return this.$store.getters["favorites/favorites"];
    },
  },
  mounted() {
    let name = this.type + "_" + this.sale.id;
    if (this.favorites[name]) {
      this.isFavorite = true;
    } else {
      this.isFavorite = false;
    }
  },
};
</script>