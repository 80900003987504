<template>
  <div class="mb-28 xl:mb-8" @keyup.enter="startSearch">
    <div class="pt-6">
         <div class="flex justify-between xl:container xl:mx-auto layout-padding">
     <div class="search-input-s w-full ltr:mr-4 rtl:ml-4 relative">
        <input
          type="text"
          v-model="keyword"
          :placeholder="content.searchInKweHarzana"
          class="rounded-full border border-black border-opacity-5 w-full"
        />
        <i
          class="fa fa-search text-purplePrimary absolute"
          @click="startSearch"
        ></i>
      </div>
      <router-link to="/categories">
        <div
          class="
            w-[38px]
            cursor-pointer
            h-[38px]
            xl:w-[54px] xl:h-[54px]
            rounded-full
            bg-yellowPrimary
            flex
            items-center
            justify-center
          "
        >
          <img
            src="/static/images/icon/category.svg"
            alt="category"
            class="w-[17px] h-[17px]"
          /></div
      ></router-link>
    </div>
   

      <div
        class="
          mt-4
          layout-padding
          xl:container xl:mx-auto
          flex
          w-[100vw]
          overflow-x-auto
          home-sections
          ltr:pr-4
          rtl:pl-4
          pb-4
        "
      >
        <SectionCardSearch
          :searchSection="searchSection"
          section="all"
          :title="content.all"
          :to="`/search?section=all&page=${pageNumbers['all']}`"
          icon="all"
        />
        <SectionCardSearch
          :searchSection="searchSection"
          section="discounts"
          :title="content.discount"
          :to="`/search?section=discounts&page=${pageNumbers['discounts']}`"
          icon="discount"
        />
        <SectionCardSearch
          :searchSection="searchSection"
          section="sales"
          :title="content.sale"
          :to="`/search?section=sales&page=${pageNumbers['sales']}`"
          icon="sale"
        />
        <SectionCardSearch
          :searchSection="searchSection"
          section="offers"
          :title="content.offer"
          :to="`/search?section=offers&page=${pageNumbers['offers']}`"
          icon="offer"
        />
        <SectionCardSearch
          :searchSection="searchSection"
          section="supermarkets"
          :title="content.supermarket"
          :to="`/search?section=supermarkets&page=${pageNumbers['supermarkets']}`"
          icon="supermarket"
        />
        <SectionCardSearch
          :searchSection="searchSection"
          section="shops"
          :title="content.shops"
          :to="`/search?section=shops&page=${pageNumbers['shops']}`"
          icon="shops"
        />
        <SectionCardSearch
          :searchSection="searchSection"
          section="upcoming"
          :title="content.upcoming"
          :to="`/search?section=upcoming&page=${pageNumbers['upcomings']}`"
          icon="upcoming"
        />
      </div>
    </div>
    <div
      class="
        flex
        items-center
        layout-padding
        justify-between
        xl:container xl:mx-auto
      "
    >
      <div
        v-if="searchSection == 'discounts'"
        class="
          w-20
          circle-btn
          xl:block
          hidden
          text-center text-purplePrimary
          py-3
          rounded-full
          bg-yellowPrimary
          cursor-pointer
        "
        @click="scrollTo('left')"
      >
        <i class="fa fa-arrow-left rtl:rotate-180"></i>
      </div>

      <div
        v-if="searchSection == 'discounts'"
        id="discount-categories"
        class="
          scroll-smooth
          rounded-xl
          mt-4
          flex
          w-[100vw]
          xl:mx-4
          overflow-x-auto
          category-sections
          ltr:pr-4
          rtl:pl-4
          pb-4
        "
      >
        <CategoryCard
          @click="setCategory(category.id)"
          v-for="category in categories.data"
          :key="category.id"
          :category="category"
          :selectedCategory="discountCategory"
        />
      </div>
      <div
        v-if="searchSection == 'discounts'"
        class="
          xl:block
          hidden
          w-20
          circle-btn
          text-center text-purplePrimary
          py-3
          rounded-full
          bg-yellowPrimary
          cursor-pointer
        "
        @click="scrollTo('right')"
      >
        <i class="fa fa-arrow-right rtl:rotate-180"></i>
      </div>
    </div>
    <div
      class="
        flex
        items-center
        layout-padding
        justify-between
        xl:container xl:mx-auto
      "
    >
      <div
        v-if="searchSection == 'sales'"
        class="
          w-20
          circle-btn
          xl:block
          hidden
          text-center text-purplePrimary
          py-3
          rounded-full
          bg-yellowPrimary
          cursor-pointer
        "
        @click="scrollTo('left')"
      >
        <i class="fa fa-arrow-left rtl:rotate-180"></i>
      </div>

      <div
        id="sale-categories"
        v-if="searchSection == 'sales'"
        class="
          mt-4
          scroll-smooth
          rounded-xl
          flex
          xl:mx-4
          w-[100vw]
          overflow-x-auto
          category-sections
          ltr:pr-4
          rtl:pl-4
          pb-4
        "
      >
        <CategoryCard
          @click="setCategory(category.id)"
          v-for="category in categories.data"
          :key="category.id"
          :category="category"
          :selectedCategory="saleCategory"
        />
      </div>
      <div
        v-if="searchSection == 'sales'"
        class="
          xl:block
          hidden
          w-20
          circle-btn
          text-center text-purplePrimary
          py-3
          rounded-full
          bg-yellowPrimary
          cursor-pointer
        "
        @click="scrollTo('right')"
      >
        <i class="fa fa-arrow-right rtl:rotate-180"></i>
      </div>
    </div>

    <div class="home-discounts mt-8 layout-padding xl:container xl:mx-auto">
      <AllResult v-if="searchSection == 'all'" :result="all" />
      <DiscountResult v-if="searchSection == 'discounts'" :result="discounts" />
      <SaleResult v-if="searchSection == 'sales'" :result="sales" />
      <OfferResult v-if="searchSection == 'offers'" :result="offers" />
      <SupermarketResult
        v-if="searchSection == 'supermarkets'"
        :result="supermarkets"
      />
      <ShopResult v-if="searchSection == 'shops'" :result="shops" />
      <UpcomingResult v-if="searchSection == 'upcoming'" :result="upcomings" />
    </div>
  </div>
</template>

<script>
import SectionCardSearch from "/src/components/UI/SectionCardSearch.vue";
import CategoryCard from "/src/components/UI/CategoryCard.vue";
import AllResult from "/src/components/Search/AllResult.vue";
import DiscountResult from "/src/components/Search/DiscountResult.vue";
import SaleResult from "/src/components/Search/SaleResult.vue";
import OfferResult from "/src/components/Search/OfferReuslt.vue";
import SupermarketResult from "/src/components/Search/SupermarketReuslt.vue";
import ShopResult from "/src/components/Search/ShopResult.vue";
import UpcomingResult from "/src/components/Search/UpcomingResult.vue";
export default {
  data() {
    return {
      keyword: "",
      lastKeyword: {
        all: null,
        sales: null,
        discounts: null,
        shops: null,
        supermarkets: null,
        offers: null,
        upcomings: null,
      },
      lastCategoryId: {
        sale: "",
        discount: "",
      },
      searchSection: "all",
      saleCategory: null,
      discountCategory: null,
      pageNumbers: {
        all: 1,
        sales: 1,
        discounts: 1,
        shops: 1,
        supermarkets: 1,
        offers: 1,
        upcomings: 1,
      },
    };
  },
  inject: ["content"],
  components: {
    SectionCardSearch,
    CategoryCard,
    AllResult,
    DiscountResult,
    SaleResult,
    OfferResult,
    SupermarketResult,
    ShopResult,
    UpcomingResult,
  },
  computed: {
    categories() {
      return this.$store.getters["categories/categories"];
    },
    all() {
      return this.$store.getters["search/all"];
    },
    discounts() {
      return this.$store.getters["search/discounts"];
    },
    sales() {
      return this.$store.getters["search/sales"];
    },
    offers() {
      return this.$store.getters["search/offers"];
    },
    shops() {
      return this.$store.getters["search/shops"];
    },
    supermarkets() {
      return this.$store.getters["search/supermarkets"];
    },
    upcomings() {
      return this.$store.getters["search/upcomings"];
    },
    language() {
      return localStorage.getItem("lang");
    },
  },
  methods: {
    scrollTo(direction) {
      if (this.searchSection == "discounts") {
        let scrollDiv = document.getElementById("discount-categories");
        if (direction == "left") {
          if (this.language == "ar" || this.language == "ku") {
            scrollDiv.scrollLeft += 100;
          } else {
            scrollDiv.scrollLeft -= 100;
          }
        } else if (direction == "right") {
          if (this.language == "ar" || this.language == "ku") {
            scrollDiv.scrollLeft -= 100;
          } else {
            scrollDiv.scrollLeft += 100;
          }
        }
      } else if (this.searchSection == "sales") {
        let scrollDiv = document.getElementById("sale-categories");
        if (direction == "left") {
          if (this.language == "ar" || this.language == "ku") {
            scrollDiv.scrollLeft += 100;
          } else {
            scrollDiv.scrollLeft -= 100;
          }
        } else if (direction == "right") {
          if (this.language == "ar" || this.language == "ku") {
            scrollDiv.scrollLeft -= 100;
          } else {
            scrollDiv.scrollLeft += 100;
          }
        }
      }
    },
    setCategory(category) {
      if (this.searchSection == "discounts") {
        if (this.discountCategory == category) {
          this.discountCategory = null;
        } else {
          this.discountCategory = category;
        }
      } else if (this.searchSection == "sales") {
        if (this.saleCategory == category) {
          this.saleCategory = null;
        } else {
          this.saleCategory = category;
        }
      }
    },
    startSearch() {
      this.lastKeyword[this.searchSection] = this.keyword;
      if (this.searchSection == "discounts") {
        this.lastCategoryId.discount = this.discountCategory;
      } else if (this.searchSection == "sales") {
        this.lastCategoryId.sale = this.saleCategory;
      }

      this.$store.dispatch("search/search", {
        section: this.searchSection,
        discountCategory: this.discountCategory,
        search: this.keyword,
        saleCategory: this.saleCategory,
        pageNumbers: this.pageNumbers[this.searchSection],
      });
    },
  },
  watch: {
    $route() {
      this.searchSection = this.$route.query.section;
    },
    "$route.query.page": {
      handler: function (value) {
        if (this.pageNumbers[this.searchSection] != value) {
          if (this.$route.query.page) {
            this.pageNumbers[this.searchSection] = value;
          } else {
            this.pageNumbers[this.searchSection] = 1;
          }
          this.startSearch();
        }
      },
      deep: true,
      immediate: true,
    },
    saleCategory() {
      if (this.saleCategory != null) {
        this.startSearch();
      }
    },
    discountCategory() {
      if (this.discountCategory != null) {
        this.startSearch();
      }
    },
    searchSection() {
      if (this.searchSection == "sales") {
        if (
          this.keyword != this.lastKeyword[this.searchSection] ||
          this.saleCategory != this.lastCategoryId.sale
        ) {
          this.startSearch();
        }
      } else if (this.searchSection == "discounts") {
        if (
          this.keyword != this.lastKeyword[this.searchSection] ||
          this.discountCategory != this.lastCategoryId.discount
        ) {
          this.startSearch();
        }
      } else {
        if (this.keyword != this.lastKeyword[this.searchSection]) {
          this.startSearch();
        }
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.searchSection = this.$route.query.section;
      if (this.$route.query.page) {
        this.pageNumbers[this.searchSection] = this.$route.query.page;
      }
      if (this.$route.query.keyword) {
        this.keyword = this.$route.query.keyword;
      }
      if (this.$route.query.category) {
        this.setCategory(this.$route.query.category);
      }
      this.startSearch();
    }, 2);
  },
};
</script>