<template>
  <div style="direction: ltr">
    <Slider v-model="value" :format="format" :max="maxValue" :min="1000" :step="250" />

  </div>
</template>
<script>
import Slider from "@vueform/slider";
export default {
  data() {
    return {
      value: [1000, 100000],
      maxValue: 100000,
      format: {
        prefix: "IQD",
        decimals: 0,
      },
    };
  },
  props: ["maxPrice", "clear"],
  watch: {
    value() {
      this.$emit("update-range", this.value[0], this.value[1]);
    },
    maxPrice() {
      if (parseInt(this.maxPrice) != null) {

        this.value[1] = parseInt(this.maxPrice);
        this.maxValue = parseInt(this.maxPrice);
      }
    },
    clear() {
      if (parseInt(this.maxPrice) != null) {
        this.value[0] = 1;
        this.value[1] = parseInt(this.maxPrice);
      } else {
        this.value[0] = 1;
        this.value[1] = 100;
      }
    },
  },
  computed: {
    startPrice() {
      return this.minValue;
    },
    MaxPrice() {
      return this.maxValue;
    },
  },
  mounted() {
    setTimeout(() => {
      this.value[1] = parseInt(this.maxPrice);
      this.maxValue = parseInt(this.maxPrice);
    }, 200);
  },
  components: {
    Slider,
  },
  inject: ["content"],
};
</script>
