import http from "../../request";

export default {
  supermarkets: async (context,payload) => {
    let page;
    if(!payload){
      page=1
    }
    else{
      page=payload
    }
    let response = await http.get("supermarkets", `${localStorage.getItem("lang")}&page=${page}`);
    context.commit("setSupermarkets", response);
  },
  supermarketDetails: async (context, payload) => {
    let response = await http.get(
      `supermarkets/${payload}`,
      localStorage.getItem("lang")
    );
    context.commit("setSupermarketDetails", response);
  },
  resetData: async (context) => {
    context.commit("setSupermarketDetails", null);
  },
  filterSuperMarkets: async (context, payload) => {
    let formData = new FormData();
    if (payload.min) {
      formData.append("price_from", payload.min);
    }
    if (payload.max) {
      formData.append("price_to", payload.max);
    }
    if (payload.category_id) {
      formData.append("category_id", payload.category_id);
    }
    formData.append("entity_id", payload.entity_id);
    let response = await http.post(
      `search`,
      localStorage.getItem("lang"),
      formData
    );

    context.commit("setSupermarketFilter", {
      response: response,
      categoryName: payload.categoryName,
      id:payload.category_id
    });
  },
};
