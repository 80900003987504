import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: () => ({
    all: null,
    discounts: null,
    offers: null,
    sales: null,
    shops: null,
    supermarkets: null,
    upcomings: null,
  }),
  actions,
  getters,
  mutations,
};
