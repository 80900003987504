<template>
  <div class="layout-padding xl:container xl:mx-auto">
    <div class="flex justify-between">
      <div class="search-input relative w-full ltr:mr-4 rtl:ml-4">
        <input
          type="text"
          :placeholder="content.searchInKweHarzana"
          class="rounded-2xl"
          v-model="keyword"
        />
        <i
          class="fa fa-search text-purplePrimary absolute"
          @click="goToSearch"
        ></i>
      </div>
      <router-link to="/categories">
        <div
          class="
            w-[38px]
            cursor-pointer
            h-[38px]
            xl:w-[54px] xl:h-[54px]
            rounded-full
            bg-yellowPrimary
            flex
            items-center
            justify-center
          "
        >
          <img
            src="/static/images/icon/category.svg"
            alt="category"
            class="w-[17px] h-[17px]"
          /></div
      ></router-link>
    </div>
    <div
      class="
        mt-4
        xl:container xl:mx-auto
        flex
        w-[100vw]
        overflow-x-auto
        home-sections
        ltr:pr-4
        rtl:pl-4
      "
    >
      <SectionCard
        :title="content.discount"
        to="/search?section=discounts"
        icon="discount"
      />
                  <SectionCard :title="content.sales" to="/search?section=sales" icon="sale" />
      <SectionCard :title="content.offer" to="/offers" icon="offer" />
  <SectionCard
        :title="content.supermarket"
        to="/supermarkets"
        icon="supermarket"
      />
      <SectionCard :title="content.shops" to="/shops" icon="shops" />

    
      <SectionCard
        :title="content.restaurants"
        to="/restaurants"
        icon="restaurant-ico"
      />
      <SectionCard :title="content.upcoming" to="/upcoming" icon="upcoming" />
    </div>
  </div>
  <div class="xl:container xl:mx-auto xl:px-[2vw] xl:rounded-3xl">
    <swiper
      :slides-per-view="1"
      :autoplay="autoplay"
      :effect="'fade'"
      :space-between="10"
    >
      <swiper-slide v-for="slide in sliders" :key="slide.id">
        <div
          class="
            h-[180px]
            md:h-[300px]
            xl:h-[35vh]
            w-full
            my-5
            xl:rounded-3xl
            md:my-8
            upcoming-home
          "
          :style="`background: url(${slide.image})`"
        ></div>
      </swiper-slide>
    </swiper>
  </div>
  <div
    class="
      layout-padding
      flex
      home-sections-two
      justify-between
      xl:container xl:mx-auto
    "
  >
    <SectionCardTwo
      :title="content.supermarket"
      to="/supermarkets"
      image="/static/images/supermarket.png"
    />
    <SectionCardTwo
      :title="content.offer"
      to="/offers"
      image="/static/images/offer.png"
    />
    <SectionCardTwo
      :title="content.shops"
      :noMargin="true"
      to="/shops"
      image="/static/images/sale.png"
    />
  </div>
</template>

<script>
import SectionCard from "/src/components/UI/SectionCard.vue";
import SectionCardTwo from "/src/components/UI/SectionCardTwo.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore from "swiper";
import { EffectFade, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
SwiperCore.use([EffectFade, Pagination, Autoplay]);
export default {
  props: ["sliders"],
  data() {
    return {
      keyword: "",
      autoplay: {
        delay: 2500,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 0,
        },

        20: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    };
  },
  inject: ["content"],
  methods: {
    goToSearch() {
      this.$router.push("/search?section=all&page=1&keyword=" + this.keyword);
    },
  },
  components: {
    SectionCard,
    SectionCardTwo,
    Swiper,
    SwiperSlide,
  },
};
</script>