<template>
  <router-link :to="`/restaurants/${restaurant?.id}`">
    <div class="discount-card rounded-3xl bg-white">
      <div
        class="h-[130px] rounded-t-3xl w-full img"
        :style="`background:url(${restaurant?.image})`"
      ></div>

      <div
        class="
          rounded-b-3xl
          py-2
          xl:py-4
          px-3
          flex
          items-center
          justify-between
          text-purplePrimary
        "
      >
        <div class="font-bold">{{ restaurant?.title }}</div>
        <div
          class="
            category
            bg-yellowPrimary
            rounded-3xl
            px-3
            w-max-content
            text-sm
            py-1
            text-center
          "
        >
          {{ restaurant?.offers_items }}
          {{ content.offers }}
        </div>
      </div>
    </div></router-link
  >
</template>

<script>
export default {
  props: ["restaurant"],
  inject: ["content"],
  mounted() {},
};
</script>