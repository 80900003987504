import http from "../../request";

export default {
  restaurants: async (context,payload) => {
    let page;
    if(!payload){
      page=1
    }
    else{
      page=payload
    }
    let response = await http.get("restaurants",  `${localStorage.getItem("lang")}&page=${page}`);
    context.commit("setRestaurants", response);
  },
  restaurantDetails: async (context, payload) => {
    let response = await http.get(
      `restaurants/${payload}`,
      localStorage.getItem("lang")
    );
    context.commit("setRestaurantDetails", response);
  },
  resetData: async (context) => {
    context.commit("setRestaurantDetails", null);
  },
  filterRestaurant: async (context, payload) => {
    let formData = new FormData();
    if (payload.min) {
      formData.append("price_from", payload.min);
    }
    if (payload.max) {
      formData.append("price_to", payload.max);
    }
    if (payload.category_id) {
      formData.append("category_id", payload.category_id);
    }
    formData.append("entity_id", payload.entity_id);
    let response = await http.post(
      `search`,
      localStorage.getItem("lang"),
      formData
    );

    context.commit("setRestaurantFilter", {
      response: response,
      categoryName: payload.categoryName,
    });
  },
};
