import http from "../../request";

export default {
  shops: async (context,payload) => {
    let page;
    if(!payload){
      page=1
    }
    else{
      page=payload
    }
    let response = await http.get("shops",  `${localStorage.getItem("lang")}&page=${page}`);
    context.commit("setShops", response);
  },
  shopDetails: async (context, payload) => {
    let response = await http.get(
      `shops/${payload}`,
      localStorage.getItem("lang")
    );
    context.commit("setShopDetails", response);
  },
  resetData: (context) => {
    context.commit("setShopDetails", null);
  },
  filterShops: async (context, payload) => {
    let formData = new FormData();
    if (payload.category_id) {
      formData.append("category_id", payload.category_id);
    }
    formData.append("entity_id", payload.entity_id);
    let response = await http.post(
      `search`,
      localStorage.getItem("lang"),
      formData
    );

    context.commit("setShopFilter", {
      response: response,
      categoryName: payload.categoryName,
    });
  },
};
