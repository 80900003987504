<template>
  <div class="grid grid-cols-12 xl:container xl:mx-auto">
    <div
      class="setting col-span-12 xl:col-span-4"
      v-if="routeName == 'settings' || screenWidth >= 1280"
    >
      <div
        class="
          xl:hidden
          bg-purplePrimary
          h-[70px]
          rounded-b-3xl
          flex
          items-center
          justify-center
          text-[20px]
          font-bold
          text-white
        "
      >
        {{ content.settings }}
      </div>
      <div class="xl:block hidden">
        <div
          class="
            xl:flex
            hidden
            layout-padding
            text-[24px]
            font-bold
            mt-8
            text-purplePrimary
          "
        >
          {{ content.settings }} 
        </div>
        <Breadcrumb :firstTitle="content.settings" :secondTitle='content[routeName]'  />
      </div>
      <div class="mt-8 mb-16 px-[2vw] layout-padding-custom">
        <div class="bg-white rounded-2xl w-full">
          <SettingItem
            :title="content.myProfile"
            to="/myprofile"
            name="myProfile"
            img="/static/images/icon/profile.svg"
          /><SettingItem
            :title="content.myOrders"
            to="/myorders"
            name="orderHistory"
            img="/static/images/icon/order.svg"
          /><SettingItem
            :title="content.reservations"
            to="/reservations"
            name="reservations"
            img="/static/images/icon/reservation.svg"
          />
          <SettingItem
            :title="content.aboutUs"
            to="/about"
            name="about"
            img="/static/images/icon/about.svg"
          />
          <SettingItem
            :title="content.myFavorites"
            to="/favorites"
            name="favorites"
            img="/static/images/icon/favorites.svg"
          />
          <SettingItem
            class="xl:hidden"
            :title="content.rateOurApplication"
            to="/rating"
            name="rating"
            img="/static/images/icon/rate.svg"
          />
          <SettingItem
            :title="content.privacyPolicy"
            to="/privacyPolicy"
            name="privacyPolicy"
            img="/static/images/icon/privacy.svg"
          />
          <SettingItem
            :title="content.faq"
            to="/faq"
            name="faq"
            img="/static/images/icon/faq.svg"
          />

          <SettingItem
            :title="content.contactUs"
            to="/contact"
            name="contact"
            img="/static/images/icon/contact.svg"
          />
        </div>
      </div>
    </div>
    <div class="col-span-12 xl:col-span-8 xl:mt-[115px]">
      <div class="mb-4 mt-8 layout-padding text-lg xl:flex hidden">{{content[routeName]}}</div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import SettingItem from "/src/components/UI/SettingItem.vue";
export default {
  inject: ["content"],
  computed: {
    routeName() {
      return this.$route.name;
    },
    screenWidth() {
      return window.innerWidth;
    },
  },
  components: {
    SettingItem,
  },
};
</script>