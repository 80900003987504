<template>
  <div class="favorites relative">
    <div class="h-[90px] xl:h-[290px] relative z-0 bg-skeleton animate-pulse">
      <div
        class="overlay w-full h-[90px] xl:h-[290px] absolute left-0 top-0"
      ></div>
      <div
        class="
          h-[70px]
          xl:h-[270px] xl:container xl:mx-auto
          layout-padding
          flex
          xl:flex-col xl:items-start
          items-center
          xl:justify-center
          justify-center
          font-bold
          text-white
        "
      ></div>
    </div>
    <div
      class="
        mt-[-25px]
        mb-8
        bg-grayBody
        relative
        z-[1]
        pt-1
        rounded-t-3xl
        xl:rounded-none
      "
    >
      <div class="xl:container xl:mx-auto layout-padding">
        <div
          class="w-full bg-skeleton animate-pulse h-12 rounded-2xl mt-8"
        ></div>
      </div>
    </div>
    <div class="mt-5 mb-12 xl:container xl:mx-auto" v-for="n in 2" :key="n">
      <div class="flex items-center justify-between text-purplePrimary">
        <div class="text-base font-bold layout-padding xl:container xl:mx-auto">
          <div class="animate-pulse rounded-2xl h-6 w-20 bg-skeleton"></div>
        </div>
      </div>
      <div class="relative mt-5">
        <swiper
          :slides-per-view="2"
          :space-between="10"
          :breakpoints="breakpoints"
          :loop="true"
        >
          <swiper-slide v-for="r in 10" :key="r">
            <div
              class="w-full h-[180px] bg-skeleton animate-pulse rounded-2xl"
            ></div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore from "swiper";
import { EffectFade, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
SwiperCore.use([EffectFade, Pagination]);
export default {
  props: ["id"],
  data() {
    return {
      breakpoints: {
        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        20: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
      },
      entity_id: this.id,
      selectedCategory: "",
      categoryName: "",
      clear: false,
      filter: {
        min: 0,
        max: 0,
      },
    };
  },

  inject: ["content"],
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    supermarketDetails() {
      return this.$store.getters["supermarkets/supermarketDetails"];
    },
    categories() {
      return this.$store.getters["categories/categories"];
    },
  },

  mounted() {
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.add("hidden");
  },
  unmounted() {
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.remove("hidden");
  },
};
</script>

