<template>
  <div
    class="
      w-full
      favorite-card
      col-span-12
      md:col-span-6
      xl:col-span-4
      bg-white
      flex
      xl:flex-col
      border border-black border-opacity-5
      rounded-3xl
      mb-4
    "
  >
    <div
      class="
        h-[160px]
        md:h-[160px]
        ltr:rounded-l-3xl
        rtl:rounded-r-3xl
        min-w-[33vw]
        md:min-w-[17vw]
        lg:min-w-[12vw]
        xl:min-w-[12vw]
        xl:rounded-3xl
        img
      "
      :style="`background:url(${favorite.payload.data.image})`"
    ></div>
    <div class="p-3 w-full flex flex-col justify-between">
      <div class="flex justify-between w-full items-center  my-2 ">
        <div
          class="
            category
            bg-yellowPrimary
            rounded-3xl
            px-3
            text-purplePrimary text-sm
            py-1
           
            text-center
          "
        >
          {{ favorite.payload.name.split("_")[0] }}
        </div>
        <i
          class="fa fa-heart text-purplePrimary text-xl"
          @click="deleteFavorite"
        ></i>
      </div>
      <div>
        <router-link
          v-if="favorite.payload.name.split('_')[0] != 'upcoming'"
          :to="`/${favorite.payload.name.split('_')[0]}s/${
            favorite.payload.data.id
          }`"
        >
          <div class="text-base py-4">
            {{ favorite.payload.data.title }}
          </div>
        </router-link>

        <div class="text-base py-4" v-else>
          {{ favorite.payload.data.title }}
        </div>
        <div
          class="flex mt-2"
          v-if="favorite.payload.name.split('_')[0] == 'discount'"
        >
          <div class="ltr:mr-2 rtl:ml-2 text-purplePrimary">
          {{ favorite.payload.data.new_price }} {{content.iqd}}
          </div>
          <div class="ltr:mr-2 rtl:ml-2 line-through">
            {{ favorite.payload.data.old_price }} {{content.iqd}}
          </div>
        </div>
        <div
          class="flex mt-2"
          v-if="favorite.payload.name.split('_')[0] == 'sale'"
        >
          <div class="ltr:mr-2 rtl:ml-2 text-purplePrimary">
            %{{ favorite.payload.data.percentage }}
          </div>
        </div>
        <div
          class="flex mt-2"
          v-if="favorite.payload.name.split('_')[0] == 'upcoming'"
        >
          <div class="ltr:mr-2 rtl:ml-2 text-purplePrimary">
            {{ favorite.payload.data.valid_from }}
          </div>
        </div>
        <div
          v-if="favorite.payload.name.split('_')[0] == 'offer'"
          class="h-[32px]"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject:['content'],
  props: ["image", "title", "currentPrice", "previousPrice", "favorite"],
  methods: {
    deleteFavorite() {
      let name = this.favorite.payload.name;
      this.$store.dispatch("favorites/deleteFavorite", name);
    },
  },
};
</script>