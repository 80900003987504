import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Modal from "/src/components/UI/Modal.vue";
import RadioButton from "/src/components/UI/RadioButton/RadioButton.vue";
import Breadcrumb from "/src/components/UI/Breadcrumb.vue";
import Pagination from "/src/components/UI/Pagination.vue";
import Alert from "/src/components/UI/Alert.vue";
import ErrorAlert from "/src/components/UI/ErrorAlert.vue";
import SuccessAlert from "/src/components/UI/SuccessAlert.vue";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import VueEasyLightbox from "vue-easy-lightbox";
import VueGtag from "vue-gtag"

let app = createApp(App);
app.use(VueGtag, {
  config: { id: "UA-152033585-3" }
}, router)
app.component("RadioButton", RadioButton);
app.component('Datepicker', Datepicker);
app.component("Breadcrumb", Breadcrumb);
app.component("Pagination", Pagination);
app.component("Alert", Alert);
app.component("SuccessAlert", SuccessAlert);
app.component('ErrorAlert', ErrorAlert);
app.component("Modal", Modal);
app.use(VueEasyLightbox);

app.use(store);
app.use(router);
app.mount("#app");
