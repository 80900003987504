import { createStore } from "vuex";
import Home from "../store/home/index.js";
import About from "../store/about/index.js";
import Discounts from "../store/discounts/index.js";
import Offers from "../store/offers/index.js";
import Sales from "../store/sales/index.js";
import Supermarkets from "../store/supermarkets/index.js";
import Contacts from "../store/contacts/index.js";
import Faqs from "../store/faqs/index.js";
import Privacy from "../store/privacy/index.js";
import Favorites from "../store/favorites/index.js";
import Upcoming from "../store/upcoming/index.js";
import Reviews from "../store/reviews/index.js";
import Categories from "../store/categories/index.js";
import Shops from "../store/shops/index.js";
import Restaurants from "../store/restaurants/index.js";
import Search from "../store/search/index.js";
import Auth from "../store/auth/index.js";
import Account from "../store/account/index.js";
import Orders from '../store/orders/index.js';
import Reservation from '../store/reservation/index.js';
export default createStore({
  modules: {
    home: Home,
    about: About,
    discounts: Discounts,
    offers: Offers,
    sales: Sales,
    supermarkets: Supermarkets,
    privacy: Privacy,
    faqs: Faqs,
    contacts: Contacts,
    favorites: Favorites,
    upcoming: Upcoming,
    reviews: Reviews,
    categories: Categories,
    shops: Shops,
    search: Search,
    restaurants: Restaurants,
    auth: Auth,
    account: Account,
    orders: Orders,
    reservations: Reservation,
  },
  state: {
    lastRoute: [],
    loading: true,
    isAuth: false,
    token:null,
  },
  mutations: {
    setLastRoute(state, route) {
      state.lastRoute.push(route);
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setAuth(state, payload) {
      state.token = payload;
    state.isAuth = true;
      localStorage.setItem("token", state.token);
    },
    unsetAuth(state) {
      state.token = null;
      localStorage.removeItem("token");
      state.isAuth = false;
    },
  },
  actions: {
    setLastRoute(context, payload) {
      context.commit("setLastRoute", payload);
    },
    async setAuth(context, payload) {
      if (!payload) {
        if (localStorage.getItem("token")) {
          context.commit("setAuth", localStorage.getItem("token"));
        } else {
          context.commit("unsetAuth");
        }
      } else {
        context.commit("setAuth", payload.access_token);
        await context.dispatch("account/myProfile");
        await context.dispatch("orders/orders");
      }
    },
    async getAll(context, payload) {
      await context.dispatch("categories/categories");
      await context.dispatch("contacts/contacts");

      let RoutesAndActions = {
        home: "home/home",
        discounts: "discounts/discounts",
        offers: "offers/offers",
        sales: "sales/sales",
        faq: "faqs/faqs",
        privacyPolicy: "privacy/privacy",
        supermarkets: "supermarkets/supermarkets",
        about: "about/about",
        upcoming: "upcoming/upcoming",
        shops: "shops/shops",
        restaurants: "restaurants/restaurants",
      };
      if (RoutesAndActions[payload] != undefined) {
        await context.dispatch(RoutesAndActions[payload]);
      }
      delete RoutesAndActions[payload];
      await context.commit("setLoading", false);

      for (let route in RoutesAndActions) {
        await context.dispatch(RoutesAndActions[route]);
      }
    },
  },
  getters: {
    lastRoute: (state) => state.lastRoute[state.lastRoute.length - 1],
    loading: (state) => state.loading,
    isAuth: (state) => state.isAuth,
  },
});
