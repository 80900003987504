<template>
  <Loading v-if="loading" />
  <div v-else class="min-h-[100vh]">
    <TheHeader />
    <router-view />
    <MobileNav />
    <TheFooter />
  </div>
</template>

<script>
import MobileNav from "@/components/layout/MobileNav.vue";
import TheFooter from "@/components/layout/TheFooter.vue";
import TheHeader from "@/components/layout/TheHeader.vue";
import Loading from "@/views/Loading.vue";
import languages from "@/assets/languages.js";
let language = null;
if (
  localStorage.getItem("lang") == null ||
  localStorage.getItem("lang") == undefined
) {
  if (window.location.href.includes("taxfezat")) {
    localStorage.setItem("lang", "ar");
    language = "ar";
  } else {
    localStorage.setItem("lang", "en");
    language = "en";
  }
} else {
  language = localStorage.getItem("lang");
}

export default {
  components: {
    MobileNav,
    Loading,
    TheHeader,
    TheFooter,
  },
  computed: {
    loading() {
      return this.$store.getters["loading"];
    },
    favorites() {
      return this.$store.getters["favorites/favorites"];
    },
    isAuth() {
      return this.$store.getters["isAuth"];
    },
  },
  watch: {
    loading() {
      if (!this.loading) {
        this.$store.dispatch("favorites/setAllFavorites");
        document.title = "Kwe Harzana";
      }
    },
  },

  provide: {
    content: languages[language],
  },
  created() {
    this.$store.dispatch("setAuth");

    if (!localStorage.getItem("favorites")) {
      localStorage.setItem("favorites", JSON.stringify({}));
    }
  },

  mounted() {
    
    setTimeout(() => {
      this.$store.dispatch("getAll", this.$route.name);
      if (this.$route.query.deviceType) {
        localStorage.setItem("deviceType", this.$route.query.deviceType);
      }
    }, 2);

    if (language == "en") {
      document.body.setAttribute("dir", "ltr");
    } else {
      document.body.setAttribute("dir", "rtl");
      document.body.classList.add("rtl");
    }
  },
};
</script>


<style >
.grecaptcha-badge { 
    visibility: hidden;
}
</style>