import http from "../../request";

export default {
  reservations: async (context) => {
    let response = await http.get(
      "reservations",
      localStorage.getItem("lang"),
      context.rootState.token
    );
    context.commit("setReservations", response);
  },
  createReservation: async (context, payload) => {
    let form = new FormData();
    form.append("rest_id", parseInt(payload.rest_id));
    form.append("number_of_people", payload.number_of_people);
    form.append("date", payload.date);
    form.append("time", payload.time);
    let response = await http.post(
      "reservations",
      localStorage.getItem("lang"),
      form,
      context.rootState.token
    );
    return response;
  },
};
