<template>
  <ErrorAlert
    :show="alerts.loginFailed"
    @close="alerts.loginFailed = false"
    :message="alerts.message"
  ></ErrorAlert>
  <div class="login xl:container xl:mx-auto">
    <div
      class="
        bg-purplePrimary
        h-[90px]
        relative
        z-0
        section-header
        xl:hidden
        bg-purplePrimary
      "
    >
      <div class="overlay w-full h-[90px] absolute left-0 top-0"></div>
      <div
        class="
          h-[70px]
          flex
          items-center
          justify-center
          text-[20px]
          font-bold
          text-white
        "
      >
        <div class="relative">{{ content.login }}</div>
      </div>
    </div>

    <div
      class="
        lg:my-4
        pb-16
        rounded-3xl
        layout-padding
        w-full
        lg:bg-white
        mt-[-25px]
        lg:mt-4
        bg-gray2
        rounded-t-3xl
        lg:w-6/12
        relative
        mx-auto
        z-[1]
        pt-1
      "
    >
      <div class="mt-[5vh]">
        <div class="xl:block hidden">
          <div class="text-sm text-[24px] font-bold mt-8 text-purplePrimary">
            {{ content.login }}
          </div>
        </div>
        <div class="mb-1 mt-4 text-sm">
          {{ content.email }}
        </div>
        <input
          type="email"
          :placeholder="content.email"
          v-model="loginData.email"
          :class="{ 'field-required': errors.emailError }"
        />
        <div class="text-red-500 mb-4">{{ errors.emailError }}</div>

        <div class="mb-1 mt-4 text-sm">
          {{ content.password }}
        </div>
        <input
          type="password"
          :placeholder="content.password"
          v-model="loginData.password"
          :class="{ 'field-required': errors.passwordError }"
        />
        <div class="text-red-500 mb-4">{{ errors.passwordError }}</div>
        <div
          class="
            mt-4
            hover:text-purple-600
            transition-all
            duration-300
            text-sm
            cursor-pointer
          "
        >
          <router-link to="/forgetpassword">{{
            content.forgotPassword
          }}</router-link>
        </div>
        <div
          @click.prevent="login"
          class="
            bg-purplePrimary
            text-white
            overflow-hidden
            transition-all
            duration-300
            px-3
            cursor-pointer
            h-12
            mt-4
            flex
            items-center
            justify-center
            rounded-3xl
          "
        >
          {{ content.login }}
        </div>
      </div>
      <div class="mt-4 text-sm">
        {{ content.dontHaveAccount }}
        <router-link
          to="/register"
          class="font-bold hover:text-purple-600 transition-all duration-300"
          >{{ content.register }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  data() {
    return {
      loginData: {
        email: null,
        password: null,
      },
      errors: {
        emailError: null,
        passwordError: null,
      },
      alerts: {
        loginFailed: false,
        message: null,
      },
    };
  },
  computed: {
    isAuth() {
      return this.$store.gettersisAuth;
    },
  },
  watch: {
    isAuth() {
      if (this.isAuth) {
        this.$router.push("/");
      }
    },
  },
  methods: {
    async login() {
      this.errors.emailError = this.validateEmail(this.loginData.email);
      this.errors.passwordError = this.validateRequired(
        this.loginData.password
      );
      if (!this.errors.emailError && !this.errors.passwordError) {
        try {
          this.errors = {
            emailError: null,
            passwordError: null,
          };
          let response = await this.$store.dispatch(
            "auth/login",
            this.loginData
          );
          if (response == "Error in Login") {
            this.alerts.loginFailed = true;
            this.alerts.message = this.content.invalidEmailOrPassword;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    validateRequired(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else {
        return null;
      }
    },
    validateEmail(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else if (!inputed.includes("@") || !inputed.includes(".")) {
        return this.content.emailFormat;
      } else {
        return null;
      }
    },
  },
};
</script>