import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index.js";
import authRoutes from "./authRoutes";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import Faq from "../views/Faq.vue";
import Offers from "../views/Offers.vue";
import AllOffers from "../views/AllOffers.vue";
import Supermarket from "../views/Supermarket.vue";
import Shops from "../views/Shops.vue";
import Search from "../views/Search.vue";
import Setting from "../views/Setting.vue";
import Upcoming from "../views/Upcoming.vue";
import Favorites from "../views/Favorites.vue";
import Contact from "../views/Contact.vue";
import DiscountDetails from "../components/Details/DiscountDetails.vue";
import OfferDetails from "../components/Details/OfferDetails.vue";
import SaleDetails from "../components/Details/SaleDetails.vue";
import SupermarketDetails from "../components/Details/SupermarketDetails.vue";
import RestaurantDetails from "../components/Details/RestaurantDetails.vue";
import ShopDetails from "../components/Details/ShopDetails.vue";
import Categories from "../views/Categories.vue";
import CategoriesSearchResult from "../components/Search/CategoriesSearchResult.vue";
import Restaurants from "../views/Restaurants.vue";
import MyProfile from "../views/Account/MyProfile.vue";
import MyOrders from "../views/Account/MyOrders.vue";
import Reservations from "../views/Account/Reservations.vue";
const routes = [
  ...authRoutes,
  {
    path: "/",
    name: "home",
    component: Home,
  },

  {
    path: "/offers",
    name: "offers",
    component: Offers,
  },
  {
    path: "/restaurants",
    name: "restaurants",
    component: Restaurants,
  },
  {
    path: "/restaurants/:id",
    name: "RestaurantDetails",
    component: RestaurantDetails,
    props: true,
  },
  {
    path: "/offers",
    name: "offers",
    component: Offers,
  },
  {
    props: true,
    path: "/offers/:id/:name",
    name: "AllOffers",
    component: AllOffers,
  },
  {
    path: "/supermarkets",
    name: "supermarkets",
    component: Supermarket,
  },
  {
    path: "/shops",
    name: "shops",
    component: Shops,
  },
  {
    path: "/categories",
    name: "categories",
    component: Categories,
  },
  {
    props: true,
    path: "/categories/:id",
    name: "categoriesSearch",
    component: CategoriesSearchResult,
  },
  {
    path: "/search",
    name: "search",
    component: Search,
  },
  {
    path: "/settings",
    name: "settings",
    component: Setting,
    children: [
      {
        path: "/favorites",
        name: "favorites",
        component: Favorites,
      },
      {
        path: "/about",
        name: "about",
        component: About,
      },
      {
        path: "/privacyPolicy",
        name: "privacyPolicy",
        component: PrivacyPolicy,
      },
      {
        path: "/faq",
        name: "faq",
        component: Faq,
      },
      {
        path: "/contact",
        name: "contact",
        component: Contact,
      },
      {
        path: "/myprofile",
        name: "myProfile",
        component: MyProfile,
        meta: { requiresAuth: true },
      },
      {
        path: "/myorders",
        name: "orderHistory",
        component: MyOrders,
        meta: { requiresAuth: true },
      },
      {
        path: "/reservations",
        name: "reservations",
        component: Reservations,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/upcoming",
    name: "upcoming",
    component: Upcoming,
  },

  {
    props: true,
    path: "/discounts/:id",
    name: "discountDetails",
    component: DiscountDetails,
  },
  {
    props: true,
    path: "/offers/:id",
    name: "offerDetails",
    component: OfferDetails,
  },
  {
    props: true,
    path: "/sales/:id",
    name: "saleDetails",
    component: SaleDetails,
  },
  {
    props: true,
    path: "/supermarkets/:id",
    name: "supermarketDetails",
    component: SupermarketDetails,
  },
  {
    props: true,
    path: "/shops/:id",
    name: "shopDetails",
    component: ShopDetails,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { top: 0, left: 0 };
  },
});
router.beforeEach(function (to, from) {
  if (from.name != to.name) {
    store.dispatch("setLastRoute", from.path);
  }
});
router.beforeEach(function (to, from, next) {
  if (to.meta.requiresAuth && store.getters.isAuth) {
    next();
  } else if (to.meta.requiresUnAuth && store.getters.isAuth) {
    next("/");
  } else if (to.meta.requiresAuth && !store.getters.isAuth) {
    next("/login");
  } else {
    next();
  }
});
export default router;
