<template>
  <Modal :modalOpened="languagesOpened" @close="languagesOpened = false">
    >
    <div
      class="
        h-[max-content]
        max-w-[300px]
        min-w-[300px]
        w-[30vw]
        bg-white
        rounded-3xl
      "
    >
      <div
        class="
          bg-purplePrimary
          h-20
          text-white
          flex
          items-center
          justify-center
          rounded-t-3xl
        "
      >
        {{ content.chooseLanguage }}
      </div>
      <div
        @click="changeLanguage('en')"
        class="
          flex
          justify-between
          layout-padding
          h-16
          cursor-pointer
          items-center
        "
        :class="{ 'text-purplePrimary': currentLanguage == 'en' }"
      >
        <div class="english-font">{{ content.english }}</div>
        <i class="fa fa-check" v-if="currentLanguage == 'en'"></i>
      </div>
      <div
        @click="changeLanguage('ku')"
        class="
          flex
          justify-between
          layout-padding
          h-16
          cursor-pointer
          items-center
          border-y border-black border-opacity-5
        "
        :class="{ 'text-purplePrimary': currentLanguage == 'ku' }"
      >
        <div class="kurdish-font">{{ content.kurdish }}</div>
        <i class="fa fa-check" v-if="currentLanguage == 'ku'"></i>
      </div>
      <div
        @click="changeLanguage('ar')"
        class="
          flex
          justify-between
          layout-padding
          h-16
          cursor-pointer
          items-center
        "
        :class="{ 'text-purplePrimary': currentLanguage == 'ar' }"
      >
        <div class="kurdish-font">{{ content.arabic }}</div>
        <i class="fa fa-check" v-if="currentLanguage == 'ar'"></i>
      </div></div
  ></Modal>
  <div
    class="
      header
      min-h-[90px]
      w-full
      xl:flex
      hidden
      bg-white
      border-b border-black border-opacity-5
    "
  >
    <div
      class="
        xl:container xl:mx-auto
        layout-padding
        flex
        items-center
        justify-between
      "
    >
      <router-link to="/">
        <img
          src="/static/images/logo-web.png"
          alt="logo-web"
          class="h-8 ltr:pr-8 rtl:pl-8"
      /></router-link>
      <div class="flex items-center h-full w-8/12 justify-end">
        <router-link class="h-full" to="/">
          <div
            class="
              cursor-pointer
              flex
              hover:bg-gray-50
              items-center
              justify-center
              min-w-[190px]
              border-x border-gray-600 border-opacity-5
              h-full
            "
            :class="{ 'text-purplePrimary': currentRoute == '/' }"
          >
            <i class="fa fa-home text-xl"></i>
            <div class="text-sm h-[28px] flex items-center mx-2">
              {{ content.home }}
            </div>
          </div></router-link
        >
        <router-link class="h-full" to="/favorites">
          <div
            class="
              cursor-pointer
              flex
              hover:bg-gray-50
              items-center
              justify-center
              min-w-[190px]
              border-x border-gray-600 border-opacity-5
              h-full
            "
            :class="{ 'text-purplePrimary': currentRoute == '/favorites' }"
          >
            <i class="fa fa-heart text-xl"></i>
            <div class="text-sm h-[28px] flex items-center mx-2">
              {{ content.favorite }}
            </div>
          </div></router-link
        >
        <router-link class="h-full" to="/search?section=all">
          <div
            class="
              cursor-pointer
              flex
              hover:bg-gray-50
              items-center
              justify-center
              min-w-[190px]
              border-x border-gray-600 border-opacity-5
              h-full
            "
            :class="{ 'text-purplePrimary': currentRoute == '/search' }"
          >
            <i class="fa fa-search text-xl"></i>
            <div class="text-sm h-[28px] flex items-center mx-2">
              {{ content.search }}
            </div>
          </div></router-link
        >

        <div
          class="
            cursor-pointer
            flex
            hover:bg-gray-50
            items-center
            justify-center
            min-w-[190px]
            border-x border-gray-600 border-opacity-5
            h-full
          "
          @click="languagesOpened = true"
          :class="{ 'text-purplePrimary': languagesOpened }"
        >
          <i class="fa fa-globe text-xl"></i>
          <div class="text-sm h-[28px] flex items-center mx-2">
            {{ content.language }}
          </div>
        </div>
        <router-link class="h-full" to="/about">
          <div
            class="
              cursor-pointer
              flex
              hover:bg-gray-50
              items-center
              justify-center
              min-w-[190px]
              border-x border-gray-600 border-opacity-5
              h-full
            "
            :class="{ 'text-purplePrimary': currentRoute == '/about' }"
          >
            <div class="h-[28px] w-[20px] flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="20"
                viewBox="0 0 6 22"
              >
                <g
                  id="Icon_feather-more-vertical"
                  data-name="Icon feather-more-vertical"
                  transform="translate(-15 -4.5)"
                >
                  <path
                    id="Path_2444"
                    data-name="Path 2444"
                    d="M19.5,18A1.5,1.5,0,1,1,18,16.5,1.5,1.5,0,0,1,19.5,18Z"
                    transform="translate(0 -2.347)"
                    fill="none"
                    :stroke="currentRoute == '/about' ? '#9c01ff' : '#222'"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <path
                    id="Path_2445"
                    data-name="Path 2445"
                    d="M19.5,7.5A1.5,1.5,0,1,1,18,6a1.5,1.5,0,0,1,1.5,1.5Z"
                    fill="none"
                    :stroke="currentRoute == '/about' ? '#9c01ff' : '#222'"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                  <path
                    id="Path_2446"
                    data-name="Path 2446"
                    d="M19.5,28.5A1.5,1.5,0,1,1,18,27,1.5,1.5,0,0,1,19.5,28.5Z"
                    transform="translate(0 -4.693)"
                    fill="none"
                    :stroke="currentRoute == '/about' ? '#9c01ff' : '#222'"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                  />
                </g>
              </svg>
            </div>
            <div class="text-sm h-[28px] flex items-center mx-2">
              {{ content.settings }}
            </div>
          </div></router-link
        >
      </div>
    </div>
  </div>
  <div
    v-if="deviceType != 'phone'"
    class="
      xl:hidden
      layout-padding
      h-16
      flex
      items-center
      justify-between
      bg-white
    "
  >
    <router-link to="/">
      <img
        src="/static/images/logo-web.png"
        alt="logo-web "
        class="w-40 relative mb-1 z-50"
      />
    </router-link>
    <div class="flex items-center">
      <div
        class="mb-1"
        @click="languagesOpened = true"
        :class="{ 'text-purplePrimary': languagesOpened }"
      >
        <i class="fa fa-globe text-2xl ltr:mr-4 rtl:ml-4"></i>
      </div>
      <button
        class="menu z-50"
        :class="{ opened: menuOpened }"
        aria-label="Main Menu"
        @click="openMenu"
      >
        <svg width="50" height="50" viewBox="0 0 100 100">
          <path
            class="line line1"
            :style="`stroke:#333`"
            d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
          />
          <path class="line line2" d="M 20,45 H 80" :style="`stroke:#333`" />
          <path
            class="line line3"
            :style="`stroke:#333`"
            d="M 20,60.999954 H 80.000231 C 80.000231,60.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
          />
        </svg>
      </button>
    </div>
    <div
      class="
        ltr:right-[-100vw]
        rtl:left-[-100vw]
        w-[100vw]
        top-0
        bg-white
        h-[100vh]
        transition-all
        duration-700
        fixed
        z-40
        layout-padding
        pt-[80px]
      "
      :class="{ 'web-menu-opened': menuOpened }"
    >
      <div>
        <router-link @click="menuOpened = false" to="/"
          ><div class="py-3 text-base border-b">
            {{ content.home }}
          </div></router-link
        >
        <router-link @click="menuOpened = false" to="/favorites"
          ><div class="py-3 text-base border-b">
            {{ content.myFavorites }}
          </div></router-link
        >
        <router-link @click="menuOpened = false" to="/search?section=discounts"
          ><div class="py-3 text-base border-b">
            {{ content.search }}
          </div></router-link
        >
        <router-link @click="menuOpened = false" to="/about"
          ><div class="py-3 text-base border-b">
            {{ content.aboutUs }}
          </div></router-link
        >
        <router-link @click="menuOpened = false" to="/privacyPolicy"
          ><div class="py-3 text-base border-b">
            {{ content.privacyPolicy }}
          </div></router-link
        >
        <router-link @click="menuOpened = false" to="/faq"
          ><div class="py-3 text-base border-b">
            {{ content.faq }}
          </div></router-link
        >
        <router-link @click="menuOpened = false" to="/contact"
          ><div class="py-3 text-base border-b">
            {{ content.contactUs }}
          </div></router-link
        >
            <router-link @click="menuOpened = false" to="/myprofile"
          ><div class="py-3 text-base border-b">
            {{ content.myProfile }}
          </div></router-link
        >
            <router-link @click="menuOpened = false" to="/myorders"
          ><div class="py-3 text-base border-b">
            {{ content.myOrders }}
          </div></router-link
        >
            <router-link @click="menuOpened = false" to="/reservations"
          ><div class="py-3 text-base ">
            {{ content.reservations }}
          </div></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      languagesOpened: false,
      menuOpened: false,
    };
  },
  methods: {
    changeLanguage(lang) {
      localStorage.setItem("lang", lang);

      this.$router.go();
    },
    openMenu() {
      this.menuOpened = !this.menuOpened;
    },
  },
  watch: {
    menuOpened() {
      if (this.menuOpened) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    },
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    deviceType() {
      return localStorage.getItem("deviceType");
    },
    currentLanguage() {
      return localStorage.getItem("lang");
    },
  },
  inject: ["content"],
};
</script>
<style scoped>
.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
.line {
  fill: none;
  stroke-width: 3;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 3;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 3;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 3;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 3;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 3;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 3;
}
.web-menu-opened {
  right: 0;
}
.rtl .web-menu-opened {
  left: 0;
}
</style>