<template>
  <SuccessAlert
    :show="alerts.resetLinkSent"
    @close="alerts.resetLinkSent = false"
    :message="alerts.message"
  ></SuccessAlert
  ><SuccessAlert
    :show="alerts.resetSuccess"
    @close="alerts.resetSuccess = false"
    :message="alerts.message"
  ></SuccessAlert>
    <ErrorAlert
    :show="alerts.emailNotFound"
    @close="alerts.emailNotFound = false"
    :message="alerts.message"
  ></ErrorAlert>
  <div class="login xl:container xl:mx-auto">
    <div
      class="
        bg-purplePrimary
        h-[90px]
        relative
        z-0
        section-header
        xl:hidden
        bg-purplePrimary
      "
    >
      <div class="overlay w-full h-[90px] absolute left-0 top-0"></div>
      <div
        class="
          h-[70px]
          flex
          items-center
          justify-center
          text-[20px]
          font-bold
          text-white
        "
      >
        <div class="relative">{{ content.forgotPassword }}</div>
      </div>
    </div>

    <div
      class="
        lg:my-4
        pb-16
        rounded-3xl
        layout-padding
        w-full
        lg:bg-white
        mt-[-25px]
        lg:mt-4
        bg-gray2
        rounded-t-3xl
        lg:w-6/12
        relative
        mx-auto
        z-[1]
        pt-1
      "
    >
      <div class="mt-[5vh]">
        <div class="xl:block hidden">
          <div class="text-sm text-[24px] font-bold mt-8 text-purplePrimary">
            {{ content.forgotPassword }}
          </div>
        </div>
        <div class="mb-1 mt-4 text-sm">
          {{ content.email }}
        </div>
        <input
          type="email"
          :placeholder="content.email"
          :disabled="step == 2"
          v-model="forgetData.email"
          :class="{
            'field-required': errors.emailError,
            'field-disabled': step == 2,
          }"
        />
        <div class="text-red-500 mb-4" v-if="step==2">{{ errors.emailError }}</div>
        <div class="mb-1 mt-4 text-sm" v-if="step==2">
          {{ content.resetPasswordCode }}
        </div>
        <input v-if="step==2"
          type="text"
          :placeholder="content.resetPasswordCode"
          v-model="forgetData.token"
          :class="{ 'field-required': errors.codeError }"
        />
        <div class="text-red-500 mb-4" >{{ errors.codeError }}</div>
        <div class="mb-1 mt-4 text-sm" v-if="step==2">
          {{ content.password }}
        </div>
        <input v-if="step==2"
          type="password"
          :placeholder="content.password"
          v-model="forgetData.password"
          :class="{ 'field-required': errors.passwordError }"
        />
        <div class="text-red-500 mb-4" v-if="step==2">{{ errors.passwordError }}</div>
        <div class="mb-1 mt-4 text-sm" v-if="step==2">
          {{ content.confirmPassword }}
        </div>
        <input v-if="step==2"
          type="password"
          :placeholder="content.confirmPassword"
          v-model="forgetData.confirmPassword"
          :class="{ 'field-required': errors.passwordError }"
        />
        <div class="text-red-500 mb-4" v-if="step==2">{{ errors.passwordError }}</div>
        <div
          v-if="step == 1"
          @click.prevent="forgetPassword"
          class="
            bg-purplePrimary
            text-white
            overflow-hidden
            transition-all
            duration-300
            px-3
            cursor-pointer
            h-12
            mt-4
            flex
            items-center
            justify-center
            rounded-3xl
          "
        >
          {{ content.requestPasswordResetLink }}
        </div>
        <div
          v-else-if="step == 2"
          @click.prevent="resetPassword"
          class="
            bg-purplePrimary
            text-white
            overflow-hidden
            transition-all
            duration-300
            px-3
            cursor-pointer
            h-12
            mt-4
            flex
            items-center
            justify-center
            rounded-3xl
          "
        >
          {{ content.resetPassword }}
        </div>
      </div>
      <div class="mt-4 text-sm border-t border-gray-100">
        <div class="w-full flex justify-center mt-2">{{ content.or }}</div>
        <router-link
          to="/login"
          class="font-bold hover:text-purple-600 transition-all duration-300"
          ><div
            class="
              bg-purplePrimary
              text-white
              overflow-hidden
              transition-all
              duration-300
              px-3
              cursor-pointer
              h-12
              mt-4
              flex
              items-center
              justify-center
              rounded-3xl
            "
          >
            {{ content.login }}
          </div></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  data() {
    return {
      step: 1,
      forgetData: {
        email: null,
        password: null,
        confirmPassword: null,
        token: null,
      },
      errors: {
        emailError: null,
        passwordError: null,
        codeError: null,
      },
      alerts: {
        resetLinkSent: false,
        resetSuccess: false,
        emailNotFound:false,
        message: null,
      },
    };
  },
  computed: {
    isAuth() {
      return this.$store.gettersisAuth;
    },
  },
  watch: {
    isAuth() {
      if (this.isAuth) {
        this.$router.push("/");
      }
    },
  },
  methods: {
   async forgetPassword() {
      this.errors.emailError = this.validateEmail(this.forgetData.email);

      if (!this.errors.emailError) {
        try {
          this.errors = {
            emailError: null,
          };
         await this.$store.dispatch("auth/forgetpassword", this.forgetData);
          this.alerts.resetLinkSent = true;
          this.alerts.message = this.content.forgetPasswordSuccess;
          this.step = 2;
        } catch (error) {
          let status = JSON.parse(error).status;
          if(status==401) {
            this.alerts.emailNotFound=true;
            this.alerts.message = this.content.emailNotFound;
          } 
          if(status==429) {
            this.alerts.emailNotFound=true;
            this.alerts.message = this.content.tooManyRequests;
          } 
        }
      }
    },
    async resetPassword() {
      this.errors.emailError = this.validateEmail(this.forgetData.email);
      this.errors.passwordError = this.validatePassword(
        this.forgetData.password,
        this.forgetData.confirmPassword
      );
      this.errors.codeError = this.validateCode(this.forgetData.token);
      if (!this.errors.emailError && !this.errors.passwordError && !this.errors.codeError) {
        try {
          this.errors = {
            emailError: null,
            passwordError: null,
            codeError: null,
          };
          await this.$store.dispatch("auth/resetPassword", this.forgetData);
          this.alerts.resetSuccess = true;
          this.alerts.message = this.content.passwordUpdatedSuccessfully;
          this.$router.push("/login");
        } catch (error) {
           let status = JSON.parse(error).status;
          if(status==401) {
            this.alerts.emailNotFound=true;
            this.alerts.message = this.content.invalidCode;
          } 
        }
      }
    },

    validateCode(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else if(inputed.length<6){
        return this.content.codeMustBe6Digits;
      } else {
        return null;
      }
    },
    validateEmail(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else if (!inputed.includes("@") || !inputed.includes(".")) {
        return this.content.emailFormat;
      } else {
        return null;
      }
    },
    validatePhone(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else if (!inputed.startsWith(0)) {
        return this.content.phoneFormat;
      } else if (inputed.length < 11 || inputed.length > 11) {
        return this.content.phoneFormat;
      } else {
        return null;
      }
    },
    validatePassword(inputed, inputed2) {
      if (inputed != inputed2) {
        return this.content.confirmPasswordFail;
      } else {
        var lowerCaseLetters = /[a-z]/g;
        var upperCaseLetters = /[A-Z]/g;
        var numbers = /[0-9]/g;
        if (inputed === "" || inputed == null) {
          return this.content.required;
        } else if (
          inputed.length < 8 ||
          !inputed.match(lowerCaseLetters) ||
          !inputed.match(upperCaseLetters) ||
          !inputed.match(numbers)
        ) {
          this.passwordError = this.content.passwordFormat;
          return this.content.passwordFormat;
        } else {
          return null;
        }
      }
    },
  },
};
</script>