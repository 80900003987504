<template>
  <div class="skeleton">
    <div
      class="
        mt-[-25px]
        mb-28
        xl:mb-12
        bg-grayBody
        relative
        z-[1]
        pt-1
        rounded-t-3xl
        xl:rounded-none
      "
    >
      <div class="home-discounts xl:container xl:mx-auto layout-padding">
        <div class="mt-4">
          <div class="relative">
            <swiper
              :slides-per-view="1"
              :space-between="0"
              :effect="'fade'"
              :pagination="pagination"
              :loop="true"
            >
              <swiper-slide>
                <div
                  class="
                    h-[170px]
                    md:h-[200px]
                    lg:h-[250px]
                    xl:h-[350px]
                    rounded-3xl
                    bg-skeleton
                    discount-gallery-img
                    animate-pulse
                    bg-opacity-25
                  "
                ></div>
              </swiper-slide>
            </swiper>
          </div>
          <div
            class="
              text-lg
              xl:text-2xl
              my-2
              xl:my-4
              bg-skeleton
              animate-pulse
              h-6
              w-44
              rounded-2xl
            "
          ></div>
          <div class="flex my-4">
            <div
              class="
                ltr:mr-2
                rtl:ml-2
                text-purplePrimary
                bg-skeleton
                animate-pulse
                h-6
                w-10
                rounded-2xl
              "
            ></div>
            <div
              class="
                ltr:mr-2
                rtl:ml-2
                line-through
                bg-skeleton
                animate-pulse
                h-6
                w-10
                rounded-2xl
              "
            ></div>
          </div>
          <div
            class="
              flex
              w-full
              items-center
              justify-between
              md:border
              rounded-3xl
              bg-skeleton
              animate-pulse
              h-8
              border-black border-opacity-5
            "
          ></div>
        </div>
        <hr class="my-6 xl:opacity-0" />
        <div class="">
          <div
            class="mb-4 bg-skeleton animate-pulse h-6 w-44 rounded-2xl"
          ></div>
          <div class="flex items-center mb-3">
            <div
              class="
                ltr:mr-2
                rtl:ml-2
                bg-skeleton
                animate-pulse
                h-6
                w-64
                rounded-2xl
              "
            ></div>

            <div
              class="
                ltr:ml-2
                rtl:
                ltr:mr-2
                rtl:ml-2
                bg-skeleton
                animate-pulse
                h-6
                w-52
                rounded-2xl
              "
            ></div>
          </div>
          <div
            class="
              flex
              items-center
              mb-3
              bg-skeleton
              animate-pulse
              h-6
              w-80
              rounded-2xl
            "
          >
            <div></div>
          </div>
        </div>
        <div class="xl:container xl:mx-auto hidden xl:flex justify-end">
          <div
            class="
              rounded-3xl
              xl:flex
              hidden
              w-56
              h-[40px]
              md:right-[3vw]
              z-[2]
              text-purplePrimary
              items-center
              px-3
              cursor-pointer
              bg-skeleton
              animate-pulse
            "
            @click="setFavorites"
          ></div>
        </div>
        <hr class="my-6 xl:opacity-0" />

        <div class="grid grid-cols-12 gap-5">
          <div
            v-for="n in 4"
            :key="n"
            class="col-span-12 md:col-span-6 lg:col-span-4"
          >
            <div
              class="bg-skeleton animate-pulse h-[200px] rounded-3xl w-full"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        h-[25vh]
        xl:hidden
        w-full
        fixed
        bottom-0
        left-0
        favorite-underlay
        z-[2]
      "
    >
      <div
        class="
          rounded-3xl
          w-[58vw]
          h-[40px]
          fixed
          bottom-[2vh]
          right-[-5vw]
          md:right-[3vw] md:w-max
          z-[2]
          flex
          items-center
          px-3
          cursor-pointer
          bg-skeleton
          animate-pulse
        "
      ></div>
    </div>
  </div>
</template>

