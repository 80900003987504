<template>
  <div class="about xl:container xl:mx-auto">
    <div
      class="
        xl:hidden
        bg-purplePrimary
        h-[70px]
        rounded-b-3xl
        flex
        items-center
        justify-center
        text-[20px]
        font-bold
        text-white
        relative
      "
      :class="{ 'phone-web-title': deviceType !== 'phone' }"
    >
      <i
        class="
          fa fa-chevron-left
          text-white
          absolute
          ltr:left-[3vw]
          rtl:right-[3vw]
          text-lg
          cursor-pointer
        "
        @click="$router.push('/settings')"
      ></i>

      {{ content.contactUs }}
    </div>
    <!-- <div class="xl:block hidden">
      <div
        class="
          xl:flex
          hidden
          layout-padding
          text-[24px]
          font-bold
          mt-8
          text-purplePrimary
        "
      >
        {{ content.contactUs }}
      </div>
      <Breadcrumb :firstTitle="content.contactUs" />
    </div> -->
    <div class="grid grid-cols-12 mt-8 mb-16 gap-x-0 md:gap-x-5 layout-padding">
      <div
        class="
          col-span-12
          md:col-span-6
          mb-4
          bg-white
          rounded-2xl
          px-4
          py-2
          flex
          justify-between
          items-center
        "
      >
        <div class="font-bold">{{ content.callUsAt }}</div>
        <div style="direction: ltr">{{ contacts?.phones[0]?.value }}</div>
        <a :href="`tel:${contacts?.phones[0]?.value}`">
          <div
            class="
              w-[38px]
              cursor-pointer
              h-[38px]
              rounded-full
              bg-yellowPrimary
              flex
              items-center
              justify-center
            "
          >
            <img src="/static/images/icon/contact.svg" alt="" /></div
        ></a>
      </div>
      <div
        class="
          col-span-12
          md:col-span-6
          mb-4
          bg-white
          rounded-2xl
          px-4
          py-2
          flex
          justify-between
          items-center
        "
      >
        <div class="font-bold">{{ content.EmailUsAt }}</div>
        <div>{{ contacts?.emails[0]?.value }}</div>
        <a :href="`mailto:${contacts?.emails[0]?.value}`">
          <div
            class="
              w-[38px]
              cursor-pointer
              h-[38px]
              rounded-full
              bg-yellowPrimary
              flex
              items-center
              justify-center
            "
          >
            <img src="/static/images/icon/mail.svg" alt="" /></div
        ></a>
      </div>
      <div
        class="
          col-span-12
          md:col-span-6
          bg-white
          rounded-2xl
          xl:mb-4
          px-4
          py-2
          flex
          justify-between
          items-center
        "
      >
        <div class="font-bold">{{ content.followUs }}</div>
        <div class="flex">
          <a
            :href="social.link"
            target="_blank"
            v-for="social in contacts?.socials"
            :key="social.id"
          >
            <div
              class="
                w-[38px]
                cursor-pointer
                h-[38px]
                rounded-full
                bg-yellowPrimary
                flex
                items-center
                justify-center
                ltr:mr-2
                rtl:ml-2
              "
            >
              <i :class="social.icon" class="text-purplePrimary text-xl" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  computed: {
    contacts() {
      return this.$store.getters["contacts/contacts"];
    },
    deviceType() {
      return localStorage.getItem("deviceType");
    },
  },
  mounted() {
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.add("hidden");
  },
  unmounted() {
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.remove("hidden");
  },
};
</script>