<template>
  <router-link
    :to="`/categories/${category?.id}`"
    class="col-span-6 md:col-span-3 lg:col-span-2"
  >
    <div
      class="
        border border-black border-opacity-5
        flex flex-col
        items-center
        bg-white
        px-2
        w-full
        py-2
        rounded-xl
        ltr:mr-2
        rtl:ml-2
        cursor-pointer
      "
    >
      <div
        :style="`background:url(${category?.icon})`"
        alt="sectionIcon"
        class="mb-3 h-[80px] w-full category-card-img rounded-xl"
      ></div>
      <div class="my-2">{{ category?.title }}</div>
    </div></router-link
  >
</template>

<script>
export default {
  props: ["category"],
};
</script>