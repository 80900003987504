<template>
  <div class="skeleton">
    <div
      class="
        mt-[-25px]
        mb-28
        xl:mb-12
        bg-grayBody
        relative
        z-[1]
        pt-1
        rounded-t-3xl
        xl:rounded-none
      "
    >
      <div class="home-discounts xl:container xl:mx-auto layout-padding">
        <div class="grid grid-cols-12 gap-5 mt-4">
          <div
            v-for="n in 4"
            :key="n"
            class="col-span-12 md:col-span-6 lg:col-span-4"
          >
            <div
              class="bg-skeleton animate-pulse h-[200px] rounded-3xl w-full"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

