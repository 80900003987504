<template>
  <div v-if="result">
    <div class="flex items-center justify-between">
      <div>{{ result?.meta?.total }} {{ content.resultsHaveBeenFound }}</div>
    </div>
    <div class="grid grid-cols-12 gap-5 mt-8 mb-24">
      <div
        class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3"
        v-for="(item, index) in result?.data"
        :key="index"
      >
        <ShopCard :shop="item" />
      </div>
    </div>

    <Pagination v-if="result?.meta?.last_page != 1" :data="result" />
  </div>
  <SearchSkeleton v-else></SearchSkeleton>
</template>

<script>
import ShopCard from "/src/components/UI/Cards/ShopCard.vue";
import SearchSkeleton from "/src/components/UI/SearchSkeleton.vue";

export default {
  props: ["result"],
  components: {
    ShopCard,
    SearchSkeleton,
  },
  inject: ["content"],
};
</script>