import http from "../../request";

export default {
  newReview: async (context, payload) => {
    let formData = new FormData();
    formData.append("entity_id", payload.entityId);
    formData.append("item_id", payload.itemId);
    formData.append("item_type", payload.itemType);
    formData.append("item_review", payload.itemReview);
    formData.append("entity_review", payload.entityReview);
    formData.append("token", payload.token);
    let response = await http.post(
      "reviews",
      localStorage.getItem("lang"),
      formData
    );

    context.commit("setAbout", response);
  },
};
