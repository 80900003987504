<template>
  <div v-if="modalOpened" class="modal">
    <div class="modal-bg" @click="$emit('close', false)"></div>
    <div class="p-6">
      <div class="modal-inner flex justify-center">
        <!-- <div
          @click="$emit('close', false)"
          class="
            w-8
            h-8
            absolute
            modal-close-btn
            flex
            item
            justify-center
            rounded-full
            bg-red-100
            cursor-pointer
          "
        >
          <i class="fas fa-times text-red-700 mt-2"></i>
        </div> -->
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["modalOpened"],
};
</script>