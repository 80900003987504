<template>
  <div class="privacy xl:container xl:mx-auto">
    <div
      class="
        xl:hidden
        bg-purplePrimary
        h-[70px]
        rounded-b-3xl
        flex
        items-center
        justify-center
        text-[20px]
        font-bold
        text-white
        relative
      "
      :class="{ 'phone-web-title': deviceType !== 'phone' }"
    >
      <i
        class="
          fa fa-chevron-left
          text-white
          absolute
          ltr:left-[3vw]
          rtl:right-[3vw]
          text-lg
          cursor-pointer
        "
        @click="$router.push('/settings')"
      ></i>

      {{ content.privacyPolicy }}
    </div>
    <!-- <div class="xl:block hidden">
      <div
        class="
          xl:flex
          hidden
          layout-padding
          text-[24px]
          font-bold
          mt-8
          text-purplePrimary
        "
      >
        {{ content.privacyPolicy }}
      </div>
      <Breadcrumb :firstTitle="content.privacyPolicy" />
    </div> -->
    <div class="mt-8 mb-16 layout-padding">
      <div
        class="
          bg-white
          rounded-2xl
          w-full
          layout-padding-block layout-padding
          min-h-[300px]
        "
      >
        <div v-for="privacy in privacyPolicy.data" :key="privacy.id">
          <div class="font-bold text-base">{{ privacy?.title }}</div>
          <div class="mt-1 font-normal">
            {{ privacy?.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  computed: {
    privacyPolicy() {
      return this.$store.getters["privacy/privacy"];
    },
    deviceType() {
      return localStorage.getItem("deviceType");
    },
  },
  mounted() {
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.add("hidden");
  },
  unmounted() {
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.remove("hidden");
  },
};
</script>