<template>
  <router-link :to="`/shops/${shop?.id}`">
    <div class="discount-card rounded-3xl bg-white">
      <div
        class="h-[130px] lg:h-[160px] rounded-3xl w-full img px-2 py-3 flex justify-end"
        :style="`background:url(${shop?.image})`"
      ></div>

      <div class="rounded-b-3xl pb-3 pt-2 px-3">
        <div
          class="
            category
            bg-yellowPrimary
            rounded-3xl
            px-3
            mb-1
            w-max-content
            text-purplePrimary text-sm
            py-1
            text-center my-2
          "
        >
          {{ shop?.title }}
        </div>
        <!-- <div class="truncate font-thin">{{ shop?.description }}</div> -->
        <div class="md:my-4 my-2 font-thin">{{ shop?.location }}</div>
        <div class="font-bold text-purplePrimary" v-if="shop?.max_percentage">
          {{ content.upTo }} %{{ shop?.max_percentage }}
        </div>
        <div class="font-bold text-purplePrimary h-[21px]" v-else>
        
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["shop"],
  inject: ["content"],
};
</script>