// import http from "../../request";

export default {
  setFavorites: async (context, payload) => {
    let itemName = payload["type"] + "_" + payload["item"].id;
    let itemData = {
      name: itemName,
      data: payload["item"],
    };

    if (context.state.favorites[itemName]) {
      context.commit("removeFavorites", itemName);
    } else {
      context.commit("addFavorites", itemData);
    }
    let formData = new FormData();
    formData.append("item_id", payload["item"].id);
    formData.append("type", payload["type"]);
    localStorage.setItem("favorites", JSON.stringify(context.state.favorites));
    // await http.post("favorite", localStorage.getItem("lang"), formData);
  },
  deleteFavorite: async (context, payload) => {
    context.commit("removeFavorites", payload);
    context.commit("setFavoritesCount");

    localStorage.setItem("favorites", JSON.stringify(context.state.favorites));
  },
  setAllFavorites: async (context) => {
    let favorites = localStorage.getItem("favorites");

    let favs = JSON.parse(favorites);
    for (const key in favs) {
      let firstDateSplit = favs[key].payload.data.valid_to.split("-");
      let expireDate = new Date(
        firstDateSplit[0],
        firstDateSplit[1] - 1,
        firstDateSplit[2]
      );
      if (expireDate < new Date()) {
        delete favs[key];
      }
    }
    localStorage.setItem("favorites", JSON.stringify(favs));
    await context.commit("setAllFavorites", favs);
  },
};
