import http from "../../request";

export default {
  myProfile: async (context) => {
    let response = await http.get(
      "user",
      localStorage.getItem("lang"),
      context.rootState.token
    );
    context.commit("setMyProfile", response);
  },
  updateProfile: async (context, payload) => {
    let form = new FormData();
    form.append("fullname", payload.fullname);
    if (payload.email) {
      form.append("email", payload.email);
    }
    if(payload.phone) {
    form.append("phone_number", payload.phone);}
    let response = await http.post(
      "user/update",
      localStorage.getItem("lang"),
      form,
      context.rootState.token
    );
    return response;
  },
};
