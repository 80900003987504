<template>
  <div class="favorites xl:container xl:mx-auto">
    <div
      class="bg-purplePrimary h-[90px] relative z-0 section-header xl:hidden"
      :style="`background:url(/static/images/mall.png)`"
    >
      <div class="overlay w-full h-[90px] absolute left-0 top-0"></div>
      <div
        class="
          h-[70px]
          flex
          items-center
          justify-center
          text-[20px]
          font-bold
          text-white
        "
      >
        <i
          class="
            fa fa-chevron-left
            text-white
            absolute
            ltr:left-[3vw]
            rtl:right-[3vw]
            text-lg
            cursor-pointer
          "
          @click="$router.go(-1)"
        ></i>

        <div class="relative">{{ content.restaurants }}</div>
      </div>
    </div>
    <div class="xl:block hidden">
      <div class="layout-padding text-[24px] font-bold mt-8 text-purplePrimary">
        {{ content.restaurants }}
      </div>
      <Breadcrumb :firstTitle="content.restaurants" />
    </div>
    <div
      class="
        mt-[-25px]
        mb-16
        layout-padding
        bg-grayBody
        relative
        z-[1]
        pt-1
        rounded-t-3xl
      "
    >
        <div
      v-if="restaurants?.data?.length == 0"
      class=" xl:container xl:mx-auto mt-5 font-bold"
    >
      {{ content.noRestaurants }}
    </div> 
      <div class="home-discounts mt-8" v-if="restaurants">
        <div class="grid grid-cols-12 gap-5 mt-4 mb-28 xl:mb-8">
          <div
            class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3"
            v-for="restaurant in restaurants.data"
            :key="restaurant?.id"
          >
            <RestaurantCard :restaurant="restaurant" />
          </div>
        </div>
      </div>
      <MarketsSkeleton v-else />
      <Pagination
        v-if="restaurants?.meta?.last_page != 1 && restaurants?.meta"
        :data="restaurants"
      />
    </div>
  </div>
</template>

<script>
import RestaurantCard from "/src/components/UI/Cards/RestaurantCard.vue";
import MarketsSkeleton from "/src/components/UI/MarketsSkeleton.vue";

export default {
  inject: ["content"],
  components: { RestaurantCard, MarketsSkeleton },

  computed: {
    restaurants() {
      return this.$store.getters["restaurants/restaurants"];
    },
  },
   methods:{
loadRestaurants(page){
  this.$store.dispatch("restaurants/restaurants",page);
}
  },
  watch:{
     "$route.query.page": {
      handler: function (value) {
          this.loadRestaurants(value);
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>