<template>
  <div class="home py-6">
    <SearchAndCategory :sliders="home?.sliders" />
    <HomeDiscounts :discounts="home?.discounts" />
  </div>
</template>

<script>
import SearchAndCategory from "/src/components/Home/SearchAndCategory.vue";
import HomeDiscounts from "/src/components/Home/HomeDiscounts.vue";

export default {
  inject: ["content"],
  components: {
    SearchAndCategory,
    HomeDiscounts,
  },
  computed: {
    home() {
      return this.$store.getters["home/home"];
    },
  },
};
</script>