<template>
  <div
    class="
      setting-item-card
      py-4
      layout-padding
      border-b border-black border-opacity-5
    "
  >
    <router-link :to="to" class="w-full flex justify-between items-center">
      <div class="flex">
        <img :src="img" class="h-5 w-5" alt="icon" />
        <div
          class="w-full mx-2"
          :class="{ 'text-purplePrimary': name == routeName }"
        >
          {{ title }}
        </div>
      </div>
      <i class="fa fa-chevron-right ltr:mr-4 rtl:ml-4 text-xs"></i
    ></router-link>
  </div>
</template>

<script>
export default {
  props: ["img", "title", "to", "name"],
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
};
</script>