<template>
  <div
    class="
      border border-black border-opacity-5
      flex
      items-center
      bg-white
      px-3
      w-max-content
      py-2
      rounded-xl
      ltr:mr-2
      rtl:ml-2
      cursor-pointer
    "
    :class="{ 'active-category': selectedCategory == category.id }"
  >
    <div
      :style="`background:url(${category.icon})`"
      alt="sectionIcon"
      class="ltr:mr-4 rtl:ml-4 h-[40px] w-[60px] category-card-img rounded-sm"
    ></div>
    <div class="text-xs">{{ category.title }}</div>
  </div>
</template>

<script>
export default {
  props: ["category", "selectedCategory"],
};
</script>