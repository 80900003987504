<template>
  <div class="flex items-center justify-between">
    <div>
      <div
        class="
          ltr:mr-2
          rtl:ml-2
          line-through
          bg-skeleton
          animate-pulse
          h-6
          xl:w-[30vw]
          w-[250px]
          rounded-2xl
        "
      ></div>
    </div>
  </div>
  <div class="grid grid-cols-12 gap-5 mt-8 mb-24">
    <div
      class="
        col-span-6
        lg:col-span-4
        xl:col-span-3
        h-[180px]
        w-full
        bg-skeleton
        animate-pulse
        rounded-2xl
        flex
        items-end
      "
      v-for="n in 12"
      :key="n"
    >
      <div
        class="h-[40px] w-full bg-purple-100 animate-pulse rounded-b-2xl"
      ></div>
    </div>
  </div>

  <div class="flex w-full justify-center">
    <div
      class="
        ltr:mr-2
        rtl:ml-2
        line-through
        bg-skeleton
        animate-pulse
        h-6
        w-[250px]
        rounded-2xl
      "
    ></div>
  </div>
</template>

