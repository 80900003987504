export default {
  setShops: (state, payload) => {
    state.shops = payload;
  },
  setShopDetails: (state, payload) => {
    state.shopDetails = payload;
  },
  setShopFilter: (state, payload) => {
    let result = JSON.parse(payload.response);
    let discounts=[];
    let sales=[];
    let offers=[];
    result?.data?.filter(item => {
      if (item.type == 'Discount') {
        discounts.push(item);
      }
      if (item.type == 'Sale') {
        sales.push(item);
      }
      if (item.type == 'Offer') {
        offers.push(item);
      }
    })
    let discountsWithCategory,salesWithCategory;
    if(discounts.length>0){

    discountsWithCategory = {
      id: payload.id,
      category: payload.categoryName,
      discounts: discounts,
    };
  }
    if(sales.length>0){
       salesWithCategory = {
        id: payload.id,
        category: payload.categoryName,
        sales: sales,
      };}

   
    state.shopDetails.data.available_discounts = [discountsWithCategory];
    state.shopDetails.data.available_sales = [salesWithCategory];
    state.shopDetails.data.available_offers = offers;
  },
};
