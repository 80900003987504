export default {
  en: {
    chooseLanguage: "Choose language",
    english: "English",
    EmailIsInvalid: "Email is invalid",
    invalidCode: "Invalid code",
    tooManyRequests: "Too many attempts , try again after an hour",
    kurdish: "کوردی",
    arabic: "العربية",
    searchInKweHarzana: "Search in Kwe Harzana",
    discount: "Discount",
    discounts: "Discounts",
    supermarket: "Supermarket",
    supermarkets: "Supermarkets",
    sale: "Sale",
    sales: "Sales",
    offer: "Offer",
    offers: "Offers",
    reviews: "Reviews",
    review: "Review",
    upcoming: "Upcoming",
    more: "more",
    settings: "Settings",
    myFavorites: "My Favorites",
    favorite: "Favorite",
    favorites: "Favorites",
    rateOurApplication: "Rate our Application",
    privacyPolicy: "Privacy Policy",
    faq: "Frequestly Asked Questions",
    FAQ: "FAQ",
    aboutUs: "About us",
    contactUs: "Contact us",
    callUsAt: "Call Us at",
    EmailUsAt: "Email us at",
    followUs: "Follow us",
    search: "Search",
    home: "Home",
    language: "language",
    filterItems: "Filter items",
    categories: "Categories",
    priceRange: "Price range",
    apply: "Apply",
    ReviewThisItem: "Review this item",
    storeReviews: "Store reviews",
    discountReviews: "Discount reviews",
    submitReview: "Submit review",
    youWillSave: "You will save",
    noFavorites: "No favorites",
    daysAgoAvailableUntil: "days ago , available until",
    todayAvailableUntil: "today , available until",
    availableUntil: "available until",
    saleDetails: "Sale details",
    youHaveAlreadyMakeReservation: "You have already made reservation",
    youHaveAlreadyMadeOrder: "You have already made order",
    status: "Status",
    accepted: "Accepted",
    reservations: "Reservations",
    persons: "Persons",
    rejected: "Rejected",
    pending: "Pending",
    discountDetails: "Discount details",
    offerDetails: "Offer details",
    all: "All",
    shops: "Shops",
    noRestaurants: "No restaurants",
    upTo: "Up to",
    addToFavorite: "Add to favorite",
    discountedItems: "Discounted items",
    noProducts: "No products",
    resultsHaveBeenFound: "results have been found",
    by: "By",
    clear: "Clear",
    haveQuestions: "Have questions?",
    address: "Address",
    phoneNumber: "Phone number",
    email: "Email",
    reviewCreatedSuccessfully: "Review created successfully",
    about: "About us",
    contact: "Contact us",
    restaurants: "Restaurants",
    iqd: "IQD",
    moreDiscounted: "More discounted items",
    moreOffers: "More offer items",
    moreSales: "More sale items",
    myOrders: "My orders",
    myProfile: "My profile",
    login: "Login",
    register: "Register",
    logout: "Logout",
    password: "Password",
    forgotPassword: "Forgot password ?",
    resetPassword: "Reset password",
    passwordUpdatedSuccessfully: "Password updated successfully",
    confirmPassword: "Confirm password",
    fullname: "Fullname",
    quantity: "Quantity",
    deliveryAddress: "Delivery address",
    order: "Order",
    orderSuccess: "Order created successfully",
    numberOfPeople: "Number of people",
    reserve: "Reserve",
    tableReservedSuccessfully: "Table reserved successfully",
    date: "Date",
    time: "Time",
    alreadyHaveAccount: "Already have account ?",

    confirmPasswordFail: "password and confirm password does not match",
    requestPasswordResetLink: "Request password reset link",
    dontHaveAccount: "Don't have account?",
    required: "This field is required",
    emailFormat: "Wrong Email Format.",
    emailAlreadyUsed: "Email already used.",
    phoneAlreadyUsed: "Phone number already used.",
    phoneFormat:
      "Your phone number is invalid. It should be in this format 077x xxx xxxx",
    passwordFormat:
      "Password must be more than 8 characters, including lower-case and upper-case letters and digits",
    or: "or",
    forgetPasswordSuccess: "password reset code has been sent successfully",
    resetPasswordCode: "reset password code",
    codeMustBe6Digits: "Code must be 6 digits",
    failed: "failed",
    orderHistory: "Order history",
    invalidEmailOrPassword: "Invalid email or password",
    emailNotFound: "Email not found",
    update: "Update",
    updateSuccess: "Updated successfully",
  },
  ar: {
    chooseLanguage: "اختر اللغة",
    english: "English",
    kurdish: "کوردی",
    EmailIsInvalid: "البريد الإلكتروني غير صحيح",
    invalidCode: "كود غير صحيح",
    tooManyRequests: "عدد محاولات تجاوز حد الأقصى , حاول مرة أخرى بعد ساعة",
    arabic: "العربية",
    searchInKweHarzana: "البحث في کوي هەرزانة",
    discount: "خصم",
    discounts: "الخصومات",
    supermarket: "سوبر ماركت",
    supermarkets: "سوبر ماركت",
    sale: "تخفیضات",
    sales: "مبيعات",
    offer: "عرض",
    reviews: "التعليقات",
    review: "التعليق",
    offers: "العروض",
    upcoming: "قادم",
    more: "مزيد",
    settings: "الاعدادات",
    myFavorites: "المفضلاتي",
    favorite: "المفضل",
    favorites: "المفضلات",
    rateOurApplication: "تقييم التطبيق",
    privacyPolicy: "سياسة الخصوصية",
    faq: "الأسئلة الشائعة",
    FAQ: "FAQ",
    aboutUs: "من نحن",
    contactUs: "اتصل بنا",
    callUsAt: "اتصل بنا على",
    EmailUsAt: "راسلنا على",
    followUs: "تابعنا",
    search: "بحث",
    home: "الرئيسية",
    language: "اللغة",
    filterItems: "تصفية العناصر",
    categories: "الاقسام",
    priceRange: "السعر",
    apply: "تطبيق",
    ReviewThisItem: "تقييم هذا المنتج",
    storeReviews: "تقييمات المتجر",
    discountReviews: "تقييمات الخصم",
    submitReview: "ارسال التقييم",
    youWillSave: "سوف تقوم بحفظ",
    noFavorites: "لا يوجد مفضلات",
    daysAgoAvailableUntil: "يوجد منتجات متاحة خلال",
    todayAvailableUntil: "يوجد منتجات متاحة خلال",
    availableUntil: "متاحة حتى",
    saleDetails: "تفاصيل البيع",
    discountDetails: "تفاصيل الخصم",
    offerDetails: "تفاصيل العرض",
    all: "الكل",
    shops: "تخفیضات",
    noRestaurants: "لا يوجد مطاعم",
    upTo: "حتى",
    addToFavorite: "اضافة الى المفضلة",
    discountedItems: "المنتجات المخفضة",
    noProducts: "لا يوجد منتجات",
    resultsHaveBeenFound: "نتائج تم العثور عليها",
    by: "بواسطة",
    clear: "مسح",
    haveQuestions: "لديك أسئلة ؟",
    address: "العنوان",
    phoneNumber: "رقم الهاتف",
    email: "البريد الالكتروني",
    reviewCreatedSuccessfully: "تم انشاء التقييم بنجاح",
    about: "من نحن",
    contact: "اتصل بنا",
    restaurants: "مطاعم",
    iqd: "د.ع",
    moreDiscounted: "المزيد من المنتجات المخفضة",
    moreOffers: "عروض أخرى",
    moreSales: "مبيعات أخرى",
    myOrders: "طلباتي",
    myProfile: "ملفي شخصي",
    login: "تسجيل الدخول",
    register: "تسجيل",
    logout: "تسجيل الخروج",
    forgotPassword: "نسيت كلمة المرور ؟",
    resetPassword: "اعادة ضبط كلمة المرور",
    passwordUpdatedSuccessfully: "تم تحديث كلمة المرور بنجاح",
    confirmPassword: "تأكيد كلمة المرور",
    fullname: "الاسم الكامل",
    quantity: "الكمية",
    deliveryAddress: "عنوان التوصيل",
    order: "طلب",
    alreadyHaveAccount: "هل لديك حساب بالفعل؟",
    orderSuccess: "تم إرسال الطلب بنجاح",
    numberOfPeople: "عدد الأشخاص",
    reserve: "حجز",
    tableReservedSuccessfully: "تم حجز الطاولة بنجاح",
    youHaveAlreadyMakeReservation: "لقد قمت بالحجز مسبقا",
    youHaveAlreadyMadeOrder: "لقد قمت بالطلب مسبقا",
    status: "الحالة",
    accepted: "مقبول",
    reservations: "المحجوزاتک",
    persons: "الأشخاص",
    rejected: "مرفوض",
    pending: "قيد الانتظار",
    date: "التاريخ",
    time: "الوقت",
    confirmPasswordFail: "كلمة المرور وتأكيد كلمة المرور غير متطابقتين",
    password: "كلمة المرور",
    emailAlreadyUsed: "البريد الالكتروني مستخدم بالفعل",
    phoneAlreadyUsed: "رقم الهاتف مستخدم بالفعل",
    dontHaveAccount: "ليس لديك حساب ؟",
    required: "هذا الحقل مطلوب",
    emailFormat: "خطأ في تنسيق البريد الالكتروني",
    phoneFormat: "رقم الهاتف غير صحيح. يجب أن يكون في هذا الشكل 077x xxx xxxx",
    passwordFormat:
      "يجب أن تكون كلمة المرور أكثر من 8 أحرف ، بما في ذلك الأحرف الصغيرة والكبيرة والأرقام",
    orderHistory: "سجل الطلبات",
    requestPasswordResetLink: "طلب إعادة تعيين كلمة المرور",

    or: "أو",
    forgetPasswordSuccess:
      "تم إرسال رسالة إلى بريدك الالكتروني بالرابط الموجود فيه",
     resetPasswordCode: "رمز إعادة تعيين كلمة المرور",
     codeMustBe6Digits: "يجب أن يكون رمز 6 أرقام",
    failed: "فشل",
    invalidEmailOrPassword: "البريد الالكتروني أو كلمة المرور غير صحيحة",
    emailNotFound: "البريد الالكتروني غير موجود",
    update: "تحديث",
    updateSuccess: "تم تحديث البيانات بنجاح",
  },
  ku: {
    chooseLanguage: "زمانێک ھەڵبژێرە",
    english: "English",
    EmailIsInvalid:'ئیمەیڵەکەت هەڵەیە',
    invalidCode: "کۆدەکە هەڵەیە",
    tooManyRequests: "پاش کاتژمێریکی کە هەوڵ بەرەوە",
    kurdish: "کوردی",
    arabic: "العربية",
    searchInKweHarzana: "گەڕان لەناو کوێ هەرزانە",
    discount: "داشکاندن",
    discounts: "داشکاندنەکان",
    supermarket: "سوپەرمارکێت",
    supermarkets: "سوپەرمارکێتەکان",
    sale: "نرخە کەمکراوەکان",
    sales: "نرخە کەمکراوەکان",
    offer: "ئۆفەر",
    offers: "ئۆفەرەکان",
    reviews: "پێداچوونەوە",
    review: "پێداچوونەوە",
    upcoming: "بەمزوانە",
    more: "زیاتر",
    settings: "ڕێکخستنەکان",
    myFavorites: "دڵخوازەکانم",
    favorite: "دڵخواز",
    favorites: "دڵخوازەکان",
    rateOurApplication: "هەڵسەنگاندنی ئەپڵیکەیشنەکەمان",
    privacyPolicy: " زانیاری کەسی",
    faq: "پرسیارە باوەکان",
    FAQ: "FAQ",
    aboutUs: "دەربارەی ئێمە",
    contactUs: "پەیوەندی",
    callUsAt: "پەیوەندی بکە",
    EmailUsAt: "ئیمەیل بکە",
    followUs: "فۆڵۆمان بکە",
    search: "گەڕان",
    home: "سەرەتا",
    language: "زمان",
    filterItems: "پاڵاوتنی کاڵاکان",
    categories: "بەشەکان",
    priceRange: "نرخ",
    apply: "فیلتەرکردن",
    ReviewThisItem: "هەڵسەنگاندن بکە",
    storeReviews: "هەڵسەنگاندنەکان",
    discountReviews: "هەڵسەنگاندنی داشکاندن",
    submitReview: "هەڵسەنگاندن بکە",
    youWillSave: "بڕی پاشەکەوتکردنەکەت",
    noFavorites: "هیچ دڵخوازێکی تێدا نییە",
    daysAgoAvailableUntil: "ڕۆژە بەردەستە تا",
    todayAvailableUntil: "ئەمڕۆ بەردەستە تا",
    availableUntil: "بەردەستە تا",
    saleDetails: "وردەکاری",
    discountDetails: "وردەکاری داشکاندن",
    offerDetails: "وردەکاری ئۆفەر",
    all: "هەموو",
    shops: "بازاڕەکان",
    noRestaurants: "هیچ ڕێستۆرانتێکی  نییە",
    upTo: "هەتا",
    addToFavorite: "زیادکردن بۆ دڵخوازەکان",
    discountedItems: "کاڵای داشکێندراو",
    noProducts: "هیچ کاڵایێک نییە",
    resultsHaveBeenFound: "ئەنجام دۆزرایەوە",
    by: "لە",
    clear: "سڕینەوە",
    haveQuestions: "پرسیارت هەیە؟",
    address: "ناونیشان",
    phoneNumber: "تەلەفۆن",
    email: "ئیمەیل",
    reviewCreatedSuccessfully: "هەڵسەنگاندنەکە سەرکەوتوو بوو",
    about: "دەربارەی ئێمە",
    contact: "پەیوەندی",
    restaurants: "رێستۆرانت",
    iqd: "د.ع",
    moreDiscounted: "کاڵای داشکاندراوی زیاتر",
    moreOffers: "ئۆفەری زیاتر",
    moreSales: "کاڵای زیاتر",
    myOrders: "داواکاریەکانم",
    myProfile: "پروفایلەکانم",
    update: "نوێکردنەوە",
    updateSuccess: "نوێکردنەوە سەرکەوتوو بوو",
    orderHistory: "داواکاریەکانم",
    login: "چوونەژوورەوە",
    register: "خۆتۆمارکردن",
    logout: "چوونەدەرەوە",
    password: "وشەی نهێنی",
    confirmPassword: "دڵنیابوونەوە لە وشەی نهێنی",
    youHaveAlreadyMakeReservation: "پێشتر لەم ڕێستورانتە جێگەت حیجز کردوە",
    youHaveAlreadyMadeOrder: "پێشتر داواکاریت کردوە",
    status:'دۆخ',
    accepted:'وەرگیرا',
    reservations:'حیجزەکانت',
    
    persons:'کەس',
    rejected:'رەتکرایەوە',
    pending:'چاوەڕوانی',
    fullname: "ناوی تەواو",
    quantity: "بڕ",
    deliveryAddress: "ناونیشانی وەرگرتن",
    order: "داواکردن",
    alreadyHaveAccount: "هەژمارت هەیە؟",
    orderSuccess: "داواکردنەکە سەرکەوتوو بوو",
    numberOfPeople: "ژمارەی کەسەکان",
    reserve: "حیجزکردن",
    tableReservedSuccessfully: "حیجزکردنەکە سەرکەوتوو بوو",
    date: "بەروار",
    time: "کات",
    confirmPasswordFail:
      "خانەی وشەی نهێنی و دڵنیابوونەوە لە وشەی نهێنی جیاوازان",
    emailAlreadyUsed: "ئەم ئیمەیڵە پێشتر بەکارهاتووە",
    phoneAlreadyUsed: "ئەم ژمارەی موبایلە پێشتر بەکارهاتووە",
    forgotPassword: "وشەی نهێنیم لەبیرکردوە ؟",
    resetPassword: "گۆڕینی وشەی نهێنی",
    passwordUpdatedSuccessfully:'نوێکردنەوەی وشەی نهێنی سەرکەوتوو بوو',
    dontHaveAccount: "هەژمارت نییە؟",
    required: "ئەم خانەیە داواکراوە",
    emailFormat: "ئیمەیلەکەت هەڵەیە.",
    phoneFormat: "ژمارەکەت هەڵەیە. پێویستە بەم شێوازە بێت 07xx xxx xxxx",
    passwordFormat:
      "پێویستە تێپەڕەوشەەکەت لە ٨ خانە کەمتر نەبێت پیتێکی گەورەو پیتێکی بچووک و ژمارەیەک لەخۆبگرێت",
    requestPasswordResetLink: "داواکردنی لینکی نوێ کردنەوەی وشەی نهێنی ",
    forgetPasswordSuccess: "کۆدی نوێکردنەوەی تێپەڕەوشە بە سەرکەوتووی نێردرا",
    resetPasswordCode: "کۆدی نوێکردنەوەی وشەی نهێنی",
    codeMustBe6Digits: "کۆدی نوێکردنەوەی وشەی نهێنی پێویستە ٦ ژمارە بێت",
    failed: "کارەکە سەرکەوتوو نەبوو",
    invalidEmailOrPassword: "ئیمەیلەکەت یان تێپەڕەوشەکەت دروست نییە",
    emailNotFound: "ئیمەیلەکەت نەدۆزرایەوە",

    or: "یان",
  },
};
