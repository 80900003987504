<template>
  <div class="relative">
    <i
      class="
        fa
        fav-btn-w
        fa-heart
        h-max
        cursor-pointer
        text-[24px]
        mx-4
        absolute
        rtl:left-2
        ltr:right-2
        top-3
        z-[1]
      "
      :class="{ 'fav-icon-fill-w ': isFavorite }"
      @click="setFavorites"
    ></i>

    <router-link :to="`/offers/${offer?.id}`">
      <div class="offer-card rounded-3xl bg-white">
        <div
          class="h-[150px] lg:h-[200px] rounded-t-3xl w-full img relative"
          :style="`background:url(${offer.image})`"
        >
          <div
            class="
              overlay
              h-[150px]
              lg:h-[200px]
              w-full
              rounded-t-3xl
              absolute
              left-0
              top-0
            "
          ></div>

          <div
            class="
              text-white
              py-3
              flex flex-col
              justify-between
              h-full
              relative
            "
          >
            <div class="flex justify-end"></div>
            <div class="px-4">
              <div class="font-bold text-lg">{{ offer.title }}</div>
              <div
                class="
                  text-sm
                  font-bold
                  mt-2
                  lg:mt-3
                  flex
                  justify-between
                  items-center
                "
              >
                <div>{{ offer?.entity?.title }}</div>
                <div class="flex">
                  <div class="rating ltr:mr-2 rtl:ml-2">
                    <div
                      class="rating-upper"
                      :style="`width:calc( ( ${offer?.entity?.rating} / 5 ) * 100%)`"
                    >
                      <span><i class="fas fa-star"></i></span>
                      <span><i class="fas fa-star"></i></span>
                      <span><i class="fas fa-star"></i></span>
                      <span><i class="fas fa-star"></i></span>
                      <span><i class="fas fa-star"></i></span>
                    </div>
                    <div class="rating-lower">
                      <span><i class="fas fa-star"></i></span>
                      <span><i class="fas fa-star"></i></span>
                      <span><i class="fas fa-star"></i></span>
                      <span><i class="fas fa-star"></i></span>
                      <span><i class="fas fa-star"></i></span>
                    </div>
                  </div>
                  <div>( {{ offer.reviews }} {{ content.reviews }} )</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="
            rounded-b-3xl
            bg-yellowPrimary
            text-purplePrimary
            pb-2
            pt-2
            px-3
            flex
            items-center
            justify-between
          "
        >
          <div v-if="daysBetween(offer.valid_from)>1"> 

            {{ daysBetween(offer.valid_from) }}
            {{ content.daysAgoAvailableUntil }}
            {{ offer.valid_to }}
          </div>
          <div v-else> 

          
            {{ content.todayAvailableUntil }}
            {{ offer.valid_to }}
          </div>
        </div>
      </div></router-link
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFavorite: false,
      type: "offer",
    };
  },
  computed: {
    favorites() {
      return this.$store.getters["favorites/favorites"];
    },
  },
  props: ["offer"],
  methods: {
    setFavorites() {
      let data = {
        item: this.offer,
        type: this.type,
      };
      this.$store.dispatch("favorites/setFavorites", data);

      let name = this.type + "_" + this.offer.id;
      if (this.favorites[name]) {
        this.isFavorite = true;
      } else {
        this.isFavorite = false;
      }
    },
    daysBetween(date1) {
      let firstDateSplit = date1.split("-");
      let oneDay = 24 * 60 * 60 * 1000;
      let firstDate = new Date(
        firstDateSplit[0],
        firstDateSplit[1]-1,
        firstDateSplit[2]
      );
      let secondDate = new Date();
      let diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      return Math.round(diffDays);
    },
  },
  mounted() {
    let name = this.type + "_" + this.offer.id;
    if (this.favorites[name]) {
      this.isFavorite = true;
    } else {
      this.isFavorite = false;
    }
  },
  inject: ["content"],
};
</script>



 
 
