<template>
  <div class="relative cursor-pointer" @click="gotoItem">
    <!-- <i
        class="
          fa
          fav-btn-w
          fa-heart
          h-max
          cursor-pointer
          text-[24px]
          mx-4
          absolute
          rtl:left-2
          ltr:right-2
          top-3
        "
        :class="{ 'fav-icon-fill-w': isFavorite }"
        @click="setFavorites"
      ></i> -->

    <div class="discount-card rounded-3xl bg-white">
      <div
        class="h-[130px] rounded-3xl w-full img px-2 py-3 flex justify-end"
        :style="`background:url(${item?.image})`"
      ></div>

      <div class="rounded-b-3xl pb-3 pt-2 px-3">
        <div
          class="
            category
            bg-yellowPrimary
            rounded-3xl
            px-3
            mb-1
            w-max-content
            text-purplePrimary text-sm
            py-1
            text-center
          "
        >
          
          {{ ItemType[language][item?.type] }}
        </div>
        <div class="font-bold text-base">{{ item?.title }}</div>
        <div class="flex" v-if="item.type == 'Discount'">
          <div class="ltr:mr-2 rtl:ml-2 text-purplePrimary">
           {{  item?.new_price }} {{content.iqd}}
          </div>
          <div class="ltr:mr-2 rtl:ml-2 line-through">
            {{ item?.old_price }} {{content.iqd}}
          </div>
        </div>
        <div class="flex" v-if="item.type == 'Sale'">
          <div class="ltr:mr-2 rtl:ml-2 text-purplePrimary">
            %{{ item?.percentage }}
          </div>
        </div>
        <div class="flex" v-if="item.type == 'Supermarket'">
          <div class="ltr:mr-2 rtl:ml-2 text-purplePrimary">
            {{ content.discountedItems }} ({{ item?.discounted_items }})
          </div>
        </div>
        <div
          class="flex"
          v-if="item.type == 'Restaurant' || item.type == 'Offer'"
        >
          <div class="ltr:mr-2 rtl:ml-2 text-purplePrimary h-[21px]"></div>
        </div>
        <div class="flex" v-if="item.type == 'Shop'">
          <div class="ltr:mr-2 rtl:ml-2 text-purplePrimary h-[21px]"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ItemType: {
        en:{
          Discount: 'Discount',
          Sale: 'Sale',
          Supermarket: 'Supermarket',
          Restaurant: 'Restaurant',
          Offer: 'Offer',
          Shop: 'Shop',
        
        },
        ar:{
          Discount: 'خصم',
          Sale: 'بيع',
          Supermarket: 'محل بيع',
          Restaurant: 'مطعم',
          Offer: 'عرض',
          Shop: 'متجر',
        
        },
        ku:{
          Discount: 'داشکاندن',
          Sale: 'فرۆشتن',
          Supermarket: 'سوپەرمارکێت',
          Restaurant: 'چێشتخانە',
          Offer: 'ئۆفەر',
          Shop: 'بازاڕ',
        }
      },
    };
  },
  computed:{
    language(){
      return localStorage.getItem('lang')
    }
  },
  props: ["item"],
  inject: ["content"],
  methods: {
    gotoItem() {
      if (
        this.item?.type != "Upcoming" &&
        this.item?.type != "Upcomings"
      ) {
        this.$router.push(`/${this.item?.type}s/${this.item?.id}`);
      }
    },
  },
};
</script>