<template>
  <div class="favorites xl:container xl:mx-auto">
    <div
      class="bg-purplePrimary h-[90px] relative z-0 section-header xl:hidden"
      :style="`background:url(/static/images/mall.png)`"
    >
      <div class="overlay w-full h-[90px] absolute left-0 top-0"></div>
      <div
        class="
          h-[70px]
          flex
          items-center
          justify-center
          text-[20px]
          font-bold
          text-white
        "
      >
        <i
          class="
            fa fa-chevron-left
            text-white
            absolute
            ltr:left-[3vw]
            rtl:right-[3vw]
            text-lg
            cursor-pointer
          "
          @click="$router.go(-1)"
        ></i>

        <div class="relative">{{ content.shops }}</div>
      </div>
    </div>
    <div class="xl:block hidden">
      <div
        class="
          xl:flex
          hidden
          layout-padding
          text-[24px]
          font-bold
          mt-8
          text-purplePrimary
        "
      >
        {{ content.shops }}
      </div>
      <Breadcrumb :firstTitle="content.shops" />
    </div>
    <div
      class="
        mt-[-25px]
        mb-16
        layout-padding
        bg-grayBody
        relative
        z-[1]
        pt-1
        rounded-t-3xl
      "
    >
      <div class="home-discounts" v-if="shops">
        <div class="grid grid-cols-12 gap-5 mt-4 mb-28 xl:mb-8">
          <div
            class="col-span-6 lg:col-span-4"
            v-for="shop in shops?.data"
            :key="shop.id"
          >
            <ShopCard :shop="shop" />
          </div>
        </div>
      </div>
      <MarketsSkeleton v-else />
    
      <Pagination v-if="shops?.meta?.last_page != 1 &&shops?.meta" :data="shops" />
    </div>
  </div>
</template>

<script>
import ShopCard from "/src/components/UI/Cards/ShopCard.vue";
import MarketsSkeleton from "/src/components/UI/MarketsSkeleton.vue";

export default {
  inject: ["content"],
  components: { ShopCard, MarketsSkeleton },
  computed: {
    shops() {
      return this.$store.getters["shops/shops"];
    },
  },
   methods:{
loadShops(page){
  this.$store.dispatch("shops/shops",page);
}
  },
  watch:{
     "$route.query.page": {
      handler: function (value) {
          this.loadShops(value);
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>