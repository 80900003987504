<template>
  <div class="favorites xl:container xl:mx-auto">
    <div
      class="
        bg-purplePrimary
        h-[90px]
        xl:h-[290px]
        relative
        z-0
        section-header
        xl:hidden
      "
      :style="`background:url(/static/images/mall.png)`"
    >
      <div
        class="overlay w-full h-[90px] xl:h-[290px] absolute left-0 top-0"
      ></div>
      <div
        class="
          h-[70px]
          xl:h-[270px] xl:container xl:mx-auto
          layout-padding
          flex
          xl:flex-col xl:items-start
          items-center
          xl:justify-center
          justify-center
          font-bold
          text-white
        "
      >
        <i
          class="
            xl:hidden
            fa fa-chevron-left
            text-white
            absolute
            ltr:left-[3vw]
            rtl:right-[3vw]
            text-lg
            cursor-pointer
          "
          @click="$router.go(-1)"
        ></i>

        <div class="relative text-[20px] xl:text-[30px]">
          {{ content.offers }}
        </div>
        <div
          class="
            hidden
            breadcrumb
            relative
            text-white
            xl:flex xl:container xl:mx-auto
            my-4
            h-8
          "
        >
          <div
            class="font-bold text-white cursor-pointer"
            @click="$router.push('/')"
          >
            {{ content.home }} <i class="fa fa-chevron-right"></i>
          </div>
          <div class="font-bold mx-1 text-white select-none">
            {{ content.supermarkets }}
          </div>
        </div>
      </div>
    </div>
    <div class="xl:block hidden">
      <div
        class="
          xl:flex
          hidden
          layout-padding
          text-[24px]
          font-bold
          mt-8
          text-purplePrimary
        "
      >
        {{ content.offers }}
      </div>
      <Breadcrumb :firstTitle="content.offers" />
    </div>
    <div
      class="
        mt-[-25px]
        mb-16
        bg-grayBody
        relative
        z-[1]
        pt-1
        rounded-t-3xl
        xl:rounded-none
      "
    >
      <div class="home-discounts xl:container xl:mx-auto layout-padding">
        <div class="grid grid-cols-12 gap-5 mt-4 mb-28 xl:mb-8" v-if="offers">
          <div
            class="col-span-12 md:col-span-6 lg:col-span-4 mb-4"
            v-for="offer in offers.data"
            :key="offer?.id"
          >
            <OfferCard :offer="offer" />
          </div>
        </div>
        <MarketsSkeleton v-else />
      </div>
      <Pagination v-if="offers?.meta?.last_page != 1" :data="offers" />
    </div>
  </div>
</template>

<script>
import OfferCard from "/src/components/UI/Cards/OfferCard.vue";
import MarketsSkeleton from "/src/components/UI/MarketsSkeleton.vue";

export default {
  inject: ["content"],
  components: { OfferCard, MarketsSkeleton },
  computed: {
    offers() {
      return this.$store.getters["offers/offers"];
    },
  },
};
</script>