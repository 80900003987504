<template>
  <div
    class="py-1 flex items-center cursor-pointer"
    @click="$emit('select', id, title)"
  >
    <div
      class="
        w-[18px]
        h-[18px]
        rounded-full
        border border-purplePrimary
        flex
        justify-center
        items-center
      "
    >
      <div
        class="w-[10px] h-[10px] rounded-full bg-purplePrimary"
        v-show="selectedItem == id"
      ></div>
    </div>
    <div class="mx-2 font-light">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: ["title", "selectedItem", "id"],
};
</script>