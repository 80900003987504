<template>
  <SuccessAlert
    :show="alerts.reserveSuccess"
    @close="alerts.reserveSuccess = false"
    :message="alerts.message"
  >
  </SuccessAlert>
  <ErrorAlert
    :show="alerts.reservefail"
    @close="alerts.reservefail = false"
    :message="alerts.message"
  ></ErrorAlert>
  <Modal :modalOpened="reservationModal" @close="reservationModal = false">
    <div
      class="
        w-full
        bg-white
        rounded-2xl
        flex flex-col
        order-item
        justify-between
        p-6
      "
    >
      <div>
        <div class="mb-2">{{ content.numberOfPeople }}</div>
        <input
          :placeholder="content.numberOfPeople"
          type="number"
          v-model="resturantItem.number_of_people"
        />

        <div class="text-red-500">{{ errors.numberOfPeopleError }}</div>
        <div class="mb-2 mt-4">{{ content.date }}</div>
        <input
          :placeholder="content.date"
          type="date"
          v-model="resturantItem.date"
        />
        <div class="text-red-500">{{ errors.dateError }}</div>
        <div class="mb-2 mt-4">{{ content.time }}</div>
        <!-- <input
          :placeholder="content.time"
          type="time"
          v-model="resturantItem.time"
        /> -->
        <input
          :placeholder="content.time"
          type="time"
          v-model="resturantItem.time"
        />
        <div class="text-red-500">{{ errors.timeError }}</div>
      </div>
      <div
        @click="reserveTable"
        class="
          bg-purplePrimary
          text-white
          overflow-hidden
          transition-all
          duration-300
          px-6
          cursor-pointer
          h-10
          mb-4
          mt-4
          flex
          items-center
          justify-center
          rounded-3xl
        "
      >
        {{ content.reserve }}
      </div>
    </div>
  </Modal>
  <Alert :show="showReviewAlert" @close="showReviewAlert = false"></Alert>
  <div class="favorites relative">
    <div
      class="bg-purplePrimary h-[90px] relative z-0 section-header xl:hidden"
      :style="`background:url(${offerDetails?.data?.entity?.image})`"
    >
      <div class="overlay w-full h-[90px] absolute left-0 top-0"></div>
      <div
        class="
          h-[70px]
          flex
          items-center
          justify-center
          text-[20px]
          font-bold
          text-white
        "
      >
        <i
          class="
            fa fa-chevron-left
            text-white
            absolute
            ltr:left-[3vw]
            rtl:right-[3vw]
            text-lg
            cursor-pointer
          "
          @click="$router.go(-1)"
        ></i>

        <div class="relative">{{ content.offers }}</div>
      </div>
    </div>
    <div class="hidden xl:block">
      <div
        class="
          xl:flex xl:container xl:mx-auto
          hidden
          layout-padding
          text-[24px]
          font-bold
          mt-8
          text-purplePrimary
        "
      >
        {{ offerDetails?.data?.title }}
      </div>
      <Breadcrumb
        :firstTitle="content.offers"
        :secondTitle="content.offerDetails"
      />
    </div>
    <div v-if="offerDetails">
      <div
        class="
          mt-[-25px]
          mb-28
          xl:mb-12
          bg-grayBody
          relative
          z-[1]
          pt-1
          rounded-t-3xl
          xl:rounded-none
        "
      >
        <teleport to="body">
          <vue-easy-lightbox
            :visible="visible"
            :imgs="lightBoxImages"
            :index="index"
            @hide="handleHide"
          >
          </vue-easy-lightbox>
        </teleport>
        <div class="home-discounts xl:container xl:mx-auto layout-padding">
          <div class="mt-4 xl:grid grid-cols-2 xl:gap-x-10">
            <div class="relative col-span-1 order-last">
              <swiper
                :slides-per-view="1"
                :space-between="0"
                :navigation="navigation"
                :loop="true"
                :effect="'fade'"
                :pagination="pagination"
              >
                <swiper-slide
                  v-for="slide in offerDetails?.data?.gallery"
                  :key="slide.id"
                >
                  <div
                    @click="() => showImg(index)"
                    class="
                      h-[300px]
                      xl:h-[350px]
                      rounded-3xl
                      discount-gallery-img
                    "
                    :style="`background: url(${slide?.image})`"
                  ></div>
                </swiper-slide>
                <swiper-slide v-if="offerDetails?.data?.gallery?.length == 0">
                  <div
                    @click="() => showImg(index)"
                    class="
                      h-[300px]
                      xl:h-[350px]
                      rounded-3xl
                      discount-gallery-img
                    "
                    :style="`background: url(${offerDetails?.data?.image})`"
                  ></div>
                </swiper-slide>
              </swiper>
              <div
                class="
                  swiper-pagination
                  w-full
                  lg:hidden
                  flex
                  justify-center
                  mt-[-20px]
                "
              ></div>
              <div
                class="
                  absolute
                  slider-btn
                  ltr:right-0
                  rtl:left-0
                  xl:flex
                  hidden
                  justify-between
                  z-[2]
                  px-4
                "
              >
                <div
                  class="
                    w-10
                    h-10
                    rounded-full
                    bg-yellowPrimary
                    flex
                    justify-center
                    items-center
                    text-purplePrimary
                    next-offer
                    cursor-pointer
                  "
                >
                  <i class="fa fa-arrow-right rtl:rotate-180"></i>
                </div>
              </div>
              <div
                class="
                  absolute
                  slider-btn
                  xl:flex
                  hidden
                  justify-between
                  z-[2]
                  px-4
                "
              >
                <div
                  class="
                    w-10
                    h-10
                    rounded-full
                    bg-yellowPrimary
                    flex
                    justify-center
                    items-center
                    text-purplePrimary
                    prev-offer
                    cursor-pointer
                  "
                >
                  <i class="fa fa-arrow-left rtl:rotate-180"></i>
                </div>
              </div>
            </div>
            <div class="col-span-1 order-first">
              <div class="text-lg xl:text-2xl my-2 xl:my-4">
                {{ offerDetails?.data?.title }}
              </div>

              <div
                class="
                  flex
                  w-full
                  xl:hidden
                  items-center
                  justify-between
                  md:border
                  rounded-3xl
                  border-black border-opacity-5
                "
              >
                <ReviewCollapse :reviewData="offerDetails?.data">
                  <div class="font-bold pb-2 pt-4 text-sm text-purplePrimary">
                    {{ content.ReviewThisItem }}
                  </div>
                  <div class="grid grid-cols-2 gap-x-2">
                    <div class="col-span-1">
                      <div class="font-bold my-2 text-sm">
                        {{ content.storeReviews }}
                      </div>
                      <div
                        class="py-1 border-t border-black border-opacity-5"
                      ></div>
                      <RadioButton
                        title="Very Good"
                        @select="setStoreReview"
                        :selectedItem="storeReview"
                        id="5"
                      />
                      <RadioButton
                        title="Good"
                        @select="setStoreReview"
                        :selectedItem="storeReview"
                        id="4"
                      />
                      <RadioButton
                        title="Ok"
                        @select="setStoreReview"
                        :selectedItem="storeReview"
                        id="3"
                      />
                      <RadioButton
                        title="Not Ok"
                        @select="setStoreReview"
                        :selectedItem="storeReview"
                        id="2"
                      />
                      <RadioButton
                        title="Too Bad"
                        @select="setStoreReview"
                        :selectedItem="storeReview"
                        id="1"
                      />
                    </div>
                    <div class="col-span-1">
                      <div class="font-bold my-2 text-sm">
                        {{ content.discountReviews }}
                      </div>
                      <div
                        class="py-1 border-t border-black border-opacity-5"
                      ></div>
                      <RadioButton
                        title="Very Good"
                        @select="setDiscountReview"
                        :selectedItem="discountReview"
                        id="5"
                      />
                      <RadioButton
                        title="Good"
                        @select="setDiscountReview"
                        :selectedItem="discountReview"
                        id="4"
                      />
                      <RadioButton
                        title="Ok"
                        @select="setDiscountReview"
                        :selectedItem="discountReview"
                        id="3"
                      />
                      <RadioButton
                        title="Not Ok"
                        @select="setDiscountReview"
                        :selectedItem="discountReview"
                        id="2"
                      />
                      <RadioButton
                        title="Too Bad"
                        @select="setDiscountReview"
                        :selectedItem="discountReview"
                        id="1"
                      />
                    </div>
                  </div>

                  <div
                    class="
                      mt-5
                      w-max
                      px-8
                      text-center
                      bg-yellowPrimary
                      py-2
                      rounded-3xl
                      text-purplePrimary
                      cursor-pointer
                    "
                    @click="recaptcha"
                  >
                    {{ content.submitReview }}
                  </div>
                </ReviewCollapse>
              </div>

              <hr class="my-6 xl:opacity-0" />
              <div>
                <div class="flex items-center mb-3">
                  <i class="details-icon ltr:mr-2 rtl:ml-2 fas fa-store" />
                  <div>{{ offerDetails?.data?.entity?.title }}</div>
                  <!-- <router-link :to="`/offers/${id}/${offerDetails?.data?.entity?.title}`">

                  </router-link> -->
                </div>
                <div class="mb-4">
                  {{ offerDetails?.data?.description }}
                </div>
                <div
                  class="flex items-center flex-wrap mb-3"
                  v-if="offerDetails?.data?.entity?.phone"
                >
                  <img
                    src="/static/images/icon/phone-dark.svg"
                    class="details-icon ltr:mr-2 rtl:ml-2"
                  />
                  <div>
                    <div class="ltr:mr-2 rtl:ml-2">
                      <a :href="`tel:${offerDetails?.data?.entity?.phone}`"></a>
                      {{ offerDetails?.data?.entity?.phone }}
                    </div>
                  </div>
                </div>
                <div
                  class="flex items-center mb-3"
                  v-if="offerDetails?.data?.entity?.location"
                >
                  <img
                    src="/static/images/icon/location-dark.svg"
                    class="details-icon ltr:mr-2 rtl:ml-2"
                  />
                  <div>{{ offerDetails?.data?.entity?.location }}</div>
                </div>

                <div
                  class="flex items-center flex-wrap mb-3"
                  v-if="offerDetails?.data?.entity?.contacts.length > 0"
                >
                  <img
                    src="/static/images/icon/phone-dark.svg"
                    class="details-icon ltr:mr-2 rtl:ml-2"
                  />
                  <div>
                    <div class="ltr:mr-2 rtl:ml-2">
                      <a
                        :href="`tel:${phone?.value}`"
                        v-for="(phone, index) in offerDetails?.data?.entity
                          ?.contacts"
                        :key="phone.value"
                      >
                        {{ phone?.value }}
                        <span
                          v-if="
                            index <
                            offerDetails?.data?.entity?.contacts.length - 1
                          "
                        >
                          -
                        </span></a
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="flex items-center mb-3"
                  v-if="offerDetails?.data?.valid_to"
                >
                  <img
                    src="/static/images/icon/time-dark.svg"
                    class="details-icon ltr:mr-2 rtl:ml-2"
                  />
                  <div>
                    {{ content.availableUntil }}
                    {{ offerDetails?.data?.valid_to }}
                  </div>
                </div>
              </div>
              <div class="flex mt-8 mb-4">
                <div
                  v-if="offerDetails?.data?.entity?.type == 'restaurant'"
                  @click="openReservationModal"
                  class="
                    bg-purplePrimary
                    text-white
                    overflow-hidden
                    transition-all
                    duration-300
                    w-max
                    px-6
                    md:min-w-[170px]
                    cursor-pointer
                    h-10
                    ltr:mr-4
                    rtl:ml-4
                    text-base
                    flex
                    items-center
                    justify-center
                    rounded-3xl
                  "
                >
                  <div class="flex items-center">
                    <i class="fa fa-calendar-day ltr:mr-4 rtl:ml-4 text-lg"></i>
                    {{ content.reserve }}
                  </div>
                </div>
                <div
                  class="xl:container xl:mx-auto hidden xl:flex justify-start"
                >
                  <div
                    class="
                      fav-xl-btn
                      rounded-3xl
                      bg-yellowPrimary
                      xl:flex
                      hidden
                      w-max
                      h-[40px]
                      ltr:md:right-[3vw]
                      rtl:md:left-[3vw]
                      md:w-max
                      z-[2]
                      text-purplePrimary
                      items-center
                      px-3
                      cursor-pointer
                    "
                    @click="setFavorites"
                  >
                    <i
                      class="fa fav-btn fa-heart text-[20px] ltr:mr-2 rtl:ml-2"
                      :class="{ 'fav-icon-fill': isFavorite }"
                    ></i>
                    <div class="text-base">{{ content.addToFavorite }}</div>
                  </div>
                </div>
              </div>
              <div
                class="
                  xl:flex
                  mt-4
                  w-full
                  hidden
                  items-center
                  justify-between
                  md:border
                  rounded-3xl
                  border-black border-opacity-5
                "
              >
                <ReviewCollapse :reviewData="offerDetails?.data">
                  <div class="font-bold pb-2 pt-4 text-sm text-purplePrimary">
                    {{ content.ReviewThisItem }}
                  </div>
                  <div class="grid grid-cols-2 gap-x-2">
                    <div class="col-span-1">
                      <div class="font-bold my-2 text-sm">
                        {{ content.storeReviews }}
                      </div>
                      <div
                        class="py-1 border-t border-black border-opacity-5"
                      ></div>
                      <RadioButton
                        title="Very Good"
                        @select="setStoreReview"
                        :selectedItem="storeReview"
                        id="5"
                      />
                      <RadioButton
                        title="Good"
                        @select="setStoreReview"
                        :selectedItem="storeReview"
                        id="4"
                      />
                      <RadioButton
                        title="Ok"
                        @select="setStoreReview"
                        :selectedItem="storeReview"
                        id="3"
                      />
                      <RadioButton
                        title="Not Ok"
                        @select="setStoreReview"
                        :selectedItem="storeReview"
                        id="2"
                      />
                      <RadioButton
                        title="Too Bad"
                        @select="setStoreReview"
                        :selectedItem="storeReview"
                        id="1"
                      />
                    </div>
                    <div class="col-span-1">
                      <div class="font-bold my-2 text-sm">
                        {{ content.discountReviews }}
                      </div>
                      <div
                        class="py-1 border-t border-black border-opacity-5"
                      ></div>
                      <RadioButton
                        title="Very Good"
                        @select="setDiscountReview"
                        :selectedItem="discountReview"
                        id="5"
                      />
                      <RadioButton
                        title="Good"
                        @select="setDiscountReview"
                        :selectedItem="discountReview"
                        id="4"
                      />
                      <RadioButton
                        title="Ok"
                        @select="setDiscountReview"
                        :selectedItem="discountReview"
                        id="3"
                      />
                      <RadioButton
                        title="Not Ok"
                        @select="setDiscountReview"
                        :selectedItem="discountReview"
                        id="2"
                      />
                      <RadioButton
                        title="Too Bad"
                        @select="setDiscountReview"
                        :selectedItem="discountReview"
                        id="1"
                      />
                    </div>
                  </div>

                  <div
                    class="
                      mt-5
                      w-max
                      px-8
                      text-center
                      bg-yellowPrimary
                      py-2
                      rounded-3xl
                      text-purplePrimary
                      cursor-pointer
                    "
                    @click="recaptcha"
                  >
                    {{ content.submitReview }}
                  </div>
                </ReviewCollapse>
              </div>
            </div>
          </div>
          <hr class="my-6 xl:opacity-0" />
          <div class="text-lg py-8">{{ content.moreOffers }}</div>

          <div class="grid grid-cols-12 gap-5">
            <div
              v-for="offer in offerDetails?.other_offers"
              :key="offer.id"
              class="col-span-12 md:col-span-6 lg:col-span-4"
            >
              <OfferCard :offer="offer" />
            </div>
          </div>
        </div>
      </div>

      <div
        class="
          h-[25vh]
          xl:hidden
          w-full
          fixed
          bottom-0
          left-0
          favorite-underlay
          z-[2]
        "
      >
        <div
          class="
            rounded-3xl
            bg-yellowPrimary
            w-[58vw]
            h-[40px]
            fixed
            bottom-[2vh]
            ltr:right-[-5vw]
            rtl:left-[-5vw]
            ltr:md:right-[3vw]
            md:w-max
            rtl:md:left-[3vw]
            z-[2]
            text-purplePrimary
            flex
            items-center
            px-3
            cursor-pointer
          "
          @click="setFavorites"
        >
          <i
            class="fa fav-btn fa-heart text-[20px] ltr:mr-2 rtl:ml-2"
            :class="{ 'fav-icon-fill': isFavorite }"
          ></i>
          <div class="text-base">{{ content.addToFavorite }}</div>
        </div>
      </div>
    </div>
    <DetailsSkeleton v-else />
  </div>
</template>

<script>
import OfferCard from "/src/components/UI/Cards/OfferCard.vue";
import ReviewCollapse from "/src/components/UI/Filter/ReviewCollapse.vue";
import DetailsSkeleton from "/src/components/UI/DetailsSkeleton.vue";

import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore from "swiper";
import { EffectFade, Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/swiper.min.css";
SwiperCore.use([EffectFade, Pagination, Navigation]);
import recaptcha from "/src/assets/googleRecaptcha";

export default {
  props: ["id"],
  data() {
    return {
      imgs: "", // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0,
      lightBoxImages: [],
      reservationModal: false,
      resturantItem: {
        rest_id: null,
        number_of_people: 1,
        date: null,
        time: null,
      },
      navigation: {
        nextEl: ".next-offer",
        prevEl: ".prev-offer",
      },
      errors: {
        numberOfPeopleError: null,
        dateError: null,
        timeError: null,
      },
      alerts: {
        reserveSuccess: false,
        reservefail: false,
        message: false,
      },
      showReviewAlert: false,
      isFavorite: false,
      itemName: "",
      type: "offer",
      storeReview: 3,
      token: null,
      discountReview: 3,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
        renderBullet: function () {
          return `<span class="dot swiper-pagination-bullet" style="background: #f8f8f8 !important; margin-inline:3px;width:8px;height:8px" ></span>`;
        },
      },
    };
  },
  inject: ["content"],
  components: {
    Swiper,
    SwiperSlide,
    ReviewCollapse,
    OfferCard,
    DetailsSkeleton,
  },
  watch: {
    "$route.params.id": {
      handler: function (value) {
        if (this.$route.name == "offerDetails") {
          this.clearDetails();
          setTimeout(() => {
            this.getOfferDetails(value);
          }, 100);
        }
      },
      deep: true,
      immediate: true,
    },
    offerDetails() {
      this.itemName = this.type + "_" + this.id;
      this.lightBoxImages = [];
      if (this.offerDetails?.data?.gallery.length > 0) {
        if (this.lightBoxImages.length == 0) {
          this.offerDetails?.data?.gallery.forEach((item) => {
            this.lightBoxImages.push(item.image);
          });
        }
      } else if (this.offerDetails?.data?.gallery.length == 0) {
        this.lightBoxImages.push(this.offerDetails?.data?.image);
      }
    },
  },
  computed: {
    offerDetails() {
      return this.$store.getters["offers/offerDetails"];
    },
    favorites() {
      return this.$store.getters["favorites/favorites"];
    },
    isAuth() {
      return this.$store.getters["isAuth"];
    },
  },
  methods: {
    openReservationModal() {
      if (this.isAuth) {
        this.reservationModal = !this.reservationModal;
      } else {
        this.$router.push("/login");
      }
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },

    handleHide() {
      this.visible = false;
    },
    setFavorites() {
      let data = {
        item: this.offerDetails?.data,
        type: this.type,
      };
      this.$store.dispatch("favorites/setFavorites", data);

      this.checkIsFavorite();
    },
    async recaptcha() {
      let grecaptcha = window.grecaptcha;

      grecaptcha.ready(() => {
        grecaptcha
          .execute("6LfEVTAfAAAAAIwSMZ7zpAwgV0_Yc0g5id7ftZdJ", {
            action: "submit",
          })
          .then((token) => {
            this.token = token;
            this.submitReview();
          });
      });
    },
    clearDetails() {
      this.$store.dispatch("offers/clearOfferDetails");
    },
    async reserveTable() {
      this.resturantItem.rest_id = this.offerDetails?.data?.entity.id;
      this.errors.numberOfPeopleError = this.validateRequired(
        this.resturantItem.number_of_people
      );
      this.errors.dateError = this.validateRequired(this.resturantItem.date);
      this.errors.timeError = this.validateRequired(this.resturantItem.time);
      if (
        !this.errors.numberOfPeopleError &&
        !this.errors.dateError &&
        !this.errors.timeError
      ) {
        try {
          await this.$store.dispatch(
            "reservations/createReservation",
            this.resturantItem
          );
          this.alerts.reserveSuccess = true;
          this.alerts.message = this.content.tableReservedSuccessfully;
          this.reservationModal = false;
        } catch (error) {
          if (JSON.parse(error).status == 401) {
            this.alerts.reservefail = true;
            this.alerts.message = this.content.youHaveAlreadyMakeReservation;
            this.reservationModal = false;
          }
        }
      }
    },

    validateRequired(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else {
        return null;
      }
    },

    checkIsFavorite() {
      if (this.favorites[this.itemName]) {
        this.isFavorite = true;
      } else {
        this.isFavorite = false;
      }
    },
    async getOfferDetails() {
      this.clearDetails();
      await this.$store.dispatch("offers/offerDetails", this.id);
      await this.checkIsFavorite();
    },
    async submitReview() {
      let data = {
        itemId: this.offerDetails.data.id,
        entityId: this.offerDetails.data.entity.id,
        itemType: "offer",
        entityReview: this.storeReview,
        itemReview: this.discountReview,
        token: this.token,
      };
      if (this.showReviewAlert == false) {
        await this.$store.dispatch("reviews/newReview", data);
      }
      this.showReviewAlert = true;
      setTimeout(() => {
        this.showReviewAlert = false;
      }, 5000);
    },
    setDiscountReview(item) {
      this.discountReview = item;
    },
    setStoreReview(item) {
      this.storeReview = item;
    },
  },
  mounted() {
    recaptcha.load();
    this.getOfferDetails(this.id);

    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.add("hidden");
    this.checkIsFavorite();
  },
  unmounted() {
    recaptcha.unload();
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.remove("hidden");
    this.clearDetails();
  },
};
</script>

<style scoped>
.swiper-container {
  border-radius: 24px;
}
</style>