<template>
  <div v-if="result" class="min-h-[80vh]">
    <div class="flex items-center justify-between">
      <div>{{ result?.meta?.total }} {{ content.resultsHaveBeenFound }}</div>
    </div>
    <div class="grid grid-cols-12 gap-5 mt-8 mb-24">
      <div
        class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3"
        v-for="(item, index) in result?.data"
        :key="index"
      >
        <DiscountCard :discount="item" />
      </div>
    </div>

    <Pagination v-if="result?.meta?.last_page != 1" :data="result" />
  </div>
  <SearchSkeleton v-else></SearchSkeleton>
</template>

<script>
import DiscountCard from "/src/components/UI/Cards/DiscountCard.vue";
import SearchSkeleton from "/src/components/UI/SearchSkeleton.vue";

export default {
  props: ["result", "SearchSkeleton"],
  components: {
    DiscountCard,
    SearchSkeleton,
  },
  inject: ["content"],
};
</script>