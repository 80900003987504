<template>
  <div class="breadcrumb flex xl:container xl:mx-auto layout-padding my-4 h-8">
    <div
      class="font-bold text-[#707070] cursor-pointer"
      @click="$router.push('/')"
    >
      {{ content.home }} <i class="fa fa-chevron-right text-xs mx-1"></i>
    </div>
    <div class="font-bold mx-1 text-[#707070] select-none">
      {{ firstTitle }}
      <span v-if="secondTitle">
        <i class="fa fa-chevron-right text-xs mx-1"></i>
      </span>
    </div>
    <div v-if="secondTitle" class="flex font-bold text-[#707070] select-none">
      <div class="mx-1">
        {{ secondTitle }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["firstTitle", "secondTitle"],
  inject: ["content"],
};
</script>