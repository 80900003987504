<template>
  <div class="favorites relative" v-if="restaurantDetails">
    <div
      class="bg-purplePrimary h-[90px] xl:h-[290px] relative z-0 section-header"
      :style="`background:url(${restaurantDetails?.data?.image})`"
    >
      <div
        class="overlay w-full h-[90px] xl:h-[290px] absolute left-0 top-0"
      ></div>
      <div
        class="
          h-[70px]
          xl:h-[270px] xl:container xl:mx-auto
          layout-padding
          flex
          xl:flex-col xl:items-start
          items-center
          xl:justify-center
          justify-center
          font-bold
          text-white
        "
      >
        <i
          class="
            xl:hidden
            fa fa-chevron-left
            text-white
            absolute
            ltr:left-[3vw]
            rtl:right-[3vw]
            text-lg
            cursor-pointer
          "
          @click="$router.go(-1)"
        ></i>

        <div class="relative text-[20px] xl:text-[30px]">
          {{ restaurantDetails?.data?.title }}
        </div>
        <div
          class="
            hidden
            breadcrumb
            relative
            text-white
            xl:flex xl:container xl:mx-auto
            my-4
            h-8
          "
        >
          <div
            class="font-bold text-white cursor-pointer"
            @click="$router.push('/')"
          >
            {{ content.home }} <i class="fa fa-chevron-right"></i>
          </div>
          <div class="font-bold mx-1 text-white select-none">
            {{ content.restaurants }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        mt-[-25px]
        bg-grayBody
        relative
        z-[1]
        pt-1
        rounded-t-3xl
        xl:rounded-none
      "
    >
      <div class="opacity-0 h-[22px]"></div>
      <!-- <div class="xl:container xl:mx-auto">
        <FilterCollapse>
          <div class="font-bold mb-2 mt-4 text-sm">
            {{ content.categories }}
          </div>
          <div
            class="
              py-1
              border-t border-black border-opacity-5
              max-h-[200px]
              overflow-auto
            "
          >
            <RadioButton
              v-for="category in categories?.data"
              :key="category?.id"
              :title="category.title"
              @select="selectCategory"
              :selectedItem="selectedCategory"
              :id="category?.id"
            />
          </div>
          <div class="font-bold mb-2 mt-4 text-sm">
            {{ content.priceRange }}
          </div>
          <div class="py-1 border-t border-black border-opacity-5"></div>
          <div class="w-full flex justify-center">
            <div class="w-11/12 my-12">
              <PriceRange
                @update-range="updateRange"
                :maxPrice="1000"
                :clear="clear"
              />
            </div>
          </div>
          <div class="flex justify-between">
            <div
              @click="resetFilters"
              class="
                w-full
                text-center
                bg-gray-200
                py-2
                cursor-pointer
                rounded-3xl
                text-[#333]
                max-w-[140px]
              "
            >
              {{ content.clear }}
            </div>
            <div
              @click="filterRestaurants"
              class="
                w-full
                text-center
                bg-yellowPrimary
                py-2
                cursor-pointer
                rounded-3xl
                text-purplePrimary
                max-w-[140px]
              "
            >
              {{ content.apply }}
            </div>
          </div>
        </FilterCollapse>
      </div> -->
    </div>

    <div
      class="mt-5 mb-12"
      v-for="category in restaurantDetails?.data?.available_discounts"
      :key="category?.id"
    >
      <div
        class="flex items-center justify-between text-purplePrimary"
        v-if="category?.discounts.length > 0"
      >
        <div class="text-base font-bold layout-padding xl:container xl:mx-auto">
          {{ category?.category }}
        </div>
      </div>

      <div
        class="relative mt-5 xl:container xl:mx-auto layout-padding"
        v-if="category?.discounts.length > 0"
      >
        <div class="grid grid-cols-12 gap-5">
          <div
            v-for="discount in category?.discounts"
            :key="discount.id"
            class="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12"
          >
            <DiscountCard :discount="discount" />
          </div>
        </div>
      </div>
    </div>

    <div
      class="mt-5 mb-12"
      v-for="category in restaurantDetails?.data?.available_sales"
      :key="category?.id"
    >
      <div
        class="flex items-center justify-between text-purplePrimary"
        v-if="category?.sales.length > 0"
      >
        <div class="text-base font-bold layout-padding xl:container xl:mx-auto">
          {{ category?.category }}
        </div>
      </div>

      <div
        class="relative mt-5 xl:container xl:mx-auto layout-padding"
        v-if="category?.sales.length > 0"
      >
        
        <div class="grid grid-cols-12 gap-5">
          <div
            v-for="sale in category?.sales"
            :key="sale.id"
            class="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12"
          >
            <SalesCard :sale="sale" />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 mb-12">
      <div class="flex items-center justify-between text-purplePrimary">
        <div
          class="text-base font-bold layout-padding xl:container xl:mx-auto"
          v-if="restaurantDetails?.data?.available_offers.length > 0"
        >
          {{ content.offers }}
        </div>
      </div>

      <div
        class="relative mt-5 xl:container xl:mx-auto layout-padding"
        v-if="restaurantDetails?.data?.available_offers.length > 0"
      >
        <!-- <swiper
          :slides-per-view="2"
          :space-between="10"
          :breakpoints="breakpoints2"
        >
          <swiper-slide
            v-for="offer in restaurantDetails?.data?.available_offers"
            :key="offer.id"
          >
            <OfferCard :offer="offer" />
          </swiper-slide>
        </swiper> -->
        <div class="grid grid-cols-12 gap-5">
          <div
              v-for="offer in restaurantDetails?.data?.available_offers"
            :key="offer.id"
            class="lg:col-span-4 sm:col-span-6 col-span-12"
          >
            <OfferCard :offer="offer" />
          </div>
        </div>
      </div>
    </div>
    <div class="layout-padding xl:container xl:mx-auto">
      <div
        v-if="
          (restaurantDetails?.data?.available_discounts.length == 0 &&
            restaurantDetails?.data?.available_sales.length == 0 &&
            restaurantDetails?.data?.available_offers.length == 0) ||
          (restaurantDetails?.data?.available_discounts[0] == null &&
            restaurantDetails?.data?.available_sales[0] == null &&
            restaurantDetails?.data?.available_offers[0] == null)
        "
        class="bg-purple-100 border-l-4 border-purple-600 my-8 p-4"
        role="alert"
      >
        {{ content.noProducts }}
      </div>
    </div>
  </div>
  <MarketDetailsSkeleton v-else />
</template>

<script>
// import FilterCollapse from "/src/components/UI/Filter/FilterCollapse.vue";
import MarketDetailsSkeleton from "/src/components/UI/MarketDetailsSkeleton.vue";
// import PriceRange from "/src/components/UI/PriceRange.vue";
import DiscountCard from "/src/components/UI/Cards/DiscountCard.vue";
import SalesCard from "/src/components/UI/Cards/SalesCard.vue";
import OfferCard from "/src/components/UI/Cards/OfferCard.vue";
// import { Swiper, SwiperSlide } from "swiper/vue";
// import SwiperCore from "swiper";
// import { EffectFade, Pagination } from "swiper";
// import "swiper/swiper-bundle.css";
// import "swiper/components/effect-fade/effect-fade.min.css";
// import "swiper/swiper.min.css";
// import "swiper/components/navigation/navigation.min.css";
// SwiperCore.use([EffectFade, Pagination]);
export default {
  props: ["id"],
  data() {
    return {
      breakpoints: {
        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        20: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
      },
      breakpoints2: {
        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        20: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },
      entity_id: this.id,
      selectedCategory: "",
      categoryName: "",
      clear: false,
      filter: {
        min: 0,
        max: 0,
      },
    };
  },
  methods: {
    selectCategory(category, name) {
      this.selectedCategory = category;
      this.categoryName = name;
    },
    resetFilters() {
      this.clear = true;
      setTimeout(() => {
        this.clear = false;
      }, 500);

      this.filter.min = 0;
      this.filter.max = 0;
      this.selectedCategory = "";
      this.categoryName = "";
    },
    filterRestaurants() {
      if (this.selectedCategory != "") {
        this.$store.dispatch("restaurants/filterRestaurant", {
          min: this.filter.min,
          max: this.filter.max,
          category_id: this.selectedCategory,
          categoryName: this.categoryName,
          entity_id: this.entity_id,
        });
      } else {
        this.$store.dispatch("restaurants/restaurantDetails", this.id);
      }
    },
    updateRange(min, max) {
      this.filter.min = min;
      this.filter.max = max;
    },
  },
  inject: ["content"],
  components: {
    // FilterCollapse,
    MarketDetailsSkeleton,
    // PriceRange,
    // Swiper,
    // SwiperSlide,
    DiscountCard,
    SalesCard,
    OfferCard,
  },
  computed: {
    restaurantDetails() {
      return this.$store.getters["restaurants/restaurantDetails"];
    },
    categories() {
      return this.$store.getters["categories/categories"];
    },
  },

  mounted() {
    this.$store.dispatch("restaurants/restaurantDetails", this.id);

    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.add("hidden");
  },
  unmounted() {
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.remove("hidden");
    this.$store.dispatch("restaurants/resetData");
  },
};
</script>


<style scoped>
.swiper-container {
  padding-block: 20px;
}
</style>