<template>
  <teleport to="body">
    <div class="footer bg-white layout-padding " :class="deviceType=='phone'?'xl:block hidden':''" >
      <div class="footer-top xl:mx-auto xl:container layout-padding">
        <div class="grid grid-cols-12">
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 my-6">
            <div class="text-[#333] hidden lg:flex">
              {{ content.haveQuestions }}
            </div>

            <div class="text-2xl mt-6 lg:mt-3 text-bold uppercase">
              {{ content.contactUs }}
            </div>
          </div>
          <div
            class="
              col-span-12
              sm:col-span-6
              lg:col-span-3
              flex
              justify-start
              my-6
              lg:my-0sm:w-6/12
              lg:justify-center
              lg:border-r
              lg:border-opacity-10
            "
          >
            <div class="mx-4  ">
              <div class="text-bold text-purplePrimary uppercase">
                {{ content.address }}
              </div>
              <div
                class="mt-3 text-bold w-10/12"
                v-for="address in contacts?.addresses"
                :key="address.id"
              >
                {{ address.value }}
              </div>
            </div>
          </div>
          <div
            class="
              col-span-12
              sm:col-span-6
              lg:col-span-3
              flex
              justify-start
              my-6
              lg:my-0sm:w-6/12
              lg:justify-center
              lg:border-r
              lg:border-opacity-10
            "
          >
            <div>
              <div class="text-bold text-purplePrimary uppercase">
                {{ content.phoneNumber }}
              </div>

              <div
                class="mt-3 text-bold"
                v-for="phone in contacts?.phones"
                :key="phone.id"
              >
                {{ phone?.value }}
              </div>
            </div>
          </div>
          <div
            class="
              col-span-12
              sm:col-span-6
              lg:col-span-3
              flex
              justify-start
              my-6
              lg:my-0sm:w-6/12 lg:justify-end
            "
          >
            <div>
              <div class="text-bold text-purplePrimary uppercase">
                {{ content.email }}
              </div>

              <div
                class="mt-3 text-bold"
                v-for="email in contacts?.emails"
                :key="email.id"
              >
                {{ email?.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          footer
          min-h-[70px]
          w-full
          bg-white
          border-t border-black border-opacity-5
        "
      >
        <div
          class="
            footer-bottom
            xl:mx-auto xl:container
            py-3
            layout-padding
            flex
            min-h-[70px]
            xl:flex
            items-center
            h-full
            sm:justify-between
            flex-wrap
            justify-center
          "
        >
          <div
            class="
              text-xl text-purplePrimary text-center
              sm:text-left
              mb-2
              sm:mb-0
            "
          >
            <a
              :href="social.link"
              target="_blank"
              v-for="social in contacts?.socials"
              :key="social.id"
            >
              <i :class="social.icon" class="mr-2 text-xl"></i
            ></a>
          </div>
          <div class="text-bold">
            Kwe Harzana © {{ new Date().getFullYear() }} -Developed By <a href="https://blackace.tech" target="_blank"><strong>Black Ace</strong></a>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  computed: {
    contacts() {
      return this.$store.getters["contacts/contacts"];
    },
      deviceType(){
      return localStorage.getItem('deviceType')
    },
  },
  inject: ["content"],
};
</script>