<template>
  <div
    v-if="show"
    class="
      text-white
      px-6
      py-4
      border-0
      rounded
      fixed
      bottom-2
      left-[3vw]
      w-[94vw]
      mb-4
      bg-red-500
      z-50
    "
  >
    <span class="inline-block align-middle mr-8">
      {{ message }}
    </span>
    <button
      class="
        absolute
        bg-transparent
        text-2xl
        font-semibold
        leading-none
        right-0
        top-0
        mt-4
        mr-6
        outline-none
        focus:outline-none
      "
      @click="$emit('close')"
    >
      <span>×</span>
    </button>
  </div>
</template>

<script>
export default {
  props: ["show", "message"],
  inject: ["content"],
};
</script>