<template>
  <SuccessAlert
    :show="alerts.updateSuccess"
    @close="alerts.updateSuccess = false"
    :message="alerts.message"
  ></SuccessAlert>
  <ErrorAlert
    :show="alerts.EmailUsedAlert"
    @close="alerts.EmailUsedAlert = false"
    :message="alerts.message"
  ></ErrorAlert>
  <div class="myprofile xl:container xl:mx-auto">
    <div
      class="
        bg-purplePrimary
        xl:hidden
        h-[70px]
        rounded-b-3xl
        flex
        items-center
        justify-center
        text-[20px]
        font-bold
        text-white
        relative
      "
      :class="{ 'phone-web-title': deviceType !== 'phone' }"
    >
      <i
        class="
          fa fa-chevron-left
          text-white
          absolute
          ltr:left-[3vw]
          rtl:right-[3vw]
          text-lg
          cursor-pointer
        "
        @click="$router.push('/settings')"
      ></i>

      {{ content.myProfile }}
    </div>
    <div class="mt-8 mb-16 layout-padding">
      <div
        class="
          bg-white
          rounded-2xl
          w-full
          layout-padding-block layout-padding
          min-h-[300px]
        "
      >
        <div>
          <div class="mb-1 mt-4 text-sm">
            {{ content.fullname }}
          </div>
          <input
            type="text"
            :placeholder="content.fullname"
            v-model="updateData.fullname"
            :class="{ 'field-required': errors.nameError }"
          />
          <div class="text-red-500 mb-4">{{ errors.nameError }}</div>

          <div class="mb-1 mt-4 text-sm">
            {{ content.email }}
          </div>
          <input
            type="email"
            :placeholder="content.email"
            v-model="updateData.email"
            :class="{ 'field-required': errors.emailError }"
          />
          <div class="text-red-500 mb-4">{{ errors.emailError }}</div>
          <div class="mb-1 mt-4 text-sm">
            {{ content.phoneNumber }}
          </div>
          <input
            type="tel"
            :placeholder="content.phoneNumber"
            v-model="updateData.phone"
            :class="{ 'field-required': errors.phoneError }"
          />
          <div class="text-red-500 mb-4">{{ errors.phoneError }}</div>

          <!-- <div class="mb-1 mt-4 text-sm">
          {{ content.password }}
        </div>
        <input
          type="password"
          :placeholder="content.password"
          v-model="updateData.password"
          :class="{ 'field-required': errors.passwordError }"
        />
        <div class="text-red-500 mb-4">{{ errors.passwordError }}</div>
        <div class="mb-1 mt-4 text-sm">
          {{ content.confirmPassword }}
        </div>
        <input
          type="password"
          :placeholder="content.confirmPassword"
          v-model="updateData.confirmPassword"
          :class="{ 'field-required': errors.passwordError }"
        />
        <div class="text-red-500 mb-4">{{ errors.passwordError }}</div> -->

          <div class="flex justify-between items-center">
            <div
              @click.prevent="updateProfile"
              class="
                bg-purplePrimary
                text-white
                overflow-hidden
                transition-all
                duration-300
                px-3
                w-max
                cursor-pointer
                h-12
                mt-4
                flex
                items-center
                justify-center
                rounded-3xl
              "
            >
              {{ content.update }}
            </div>
            <div
              @click.prevent="logout"
              class="
                bg-red-500
                text-white
                overflow-hidden
                transition-all
                duration-300
                px-3
                w-max
                cursor-pointer
                h-12
                mt-4
                flex
                items-center
                justify-center
                rounded-3xl
              "
            >
              {{ content.logout }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  data() {
    return {
      updateData: {
        fullname: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
      },

      errors: {
        emailError: "",
        nameError: "",
        phoneError: "",
        passwordError: "",
      },

      alerts: {
        updateSuccess: false,
        EmailUsedAlert: false,
        message: "",
      },
    };
  },
  computed: {
    myProfile() {
      return this.$store.getters["account/myProfile"];
    },
    deviceType() {
      return localStorage.getItem("deviceType");
    },
  },
  methods: {
    async updateProfile() {
      let user = this.updateData;
      if (this.updateData.email != this.myProfile?.data?.email) {
        this.errors.emailError = this.validateEmail(this.updateData.email);
      } else {
        delete user.email;
      }
      this.errors.nameError = this.validateRequired(this.updateData.fullname);
      if (this.updateData.phone != this.myProfile?.data?.phone_number) {
        this.errors.phoneError = this.validatePhone(this.updateData.phone);
      } 
      else {
        delete user.phone;
      }
      if (
        !this.errors.emailError &&
        !this.errors.nameError &&
        !this.errors.phoneError
      ) {
        try {
          await this.$store.dispatch("account/updateProfile", user);
          this.alerts.updateSuccess = true;
          this.alerts.message = this.content.updateSuccess;
          await this.$store.dispatch("account/myProfile");
        } catch (error) {
          let errors = JSON.parse(JSON.parse(error).response).errors;
          if (errors.email) {
            if (errors.email[0] == "Email already exists") {
              this.alerts.EmailUsedAlert = true;
              this.alerts.message = this.content.emailAlreadyUsed;
            }
            if (errors.email[0] == "Email is invalid") {
              this.alerts.EmailUsedAlert = true;
              this.alerts.message = this.content.EmailIsInvalid;
            }
          }
        }
      }
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
    validateRequired(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else {
        return null;
      }
    },
    validateEmail(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else if (!inputed.includes("@") || !inputed.includes(".")) {
        return this.content.emailFormat;
      } else {
        return null;
      }
    },
    validatePhone(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else if (!inputed.startsWith(0)) {
        return this.content.phoneFormat;
      } else if (inputed.length < 11 || inputed.length > 11) {
        return this.content.phoneFormat;
      } else {
        return null;
      }
    },
  },
  watch: {
    myProfile() {
      if (this.myProfile) {
        this.updateData.fullname = this.myProfile?.data?.fullname;
        this.updateData.email = this.myProfile?.data?.email;
        this.updateData.phone = this.myProfile?.data?.phone_number;
      }
    },
  },
  mounted() {
    this.$store.dispatch("account/myProfile");
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.add("hidden");
  },
  unmounted() {
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.remove("hidden");
  },
};
</script>