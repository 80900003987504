<template>
  <div class="about xl:container xl:mx-auto">
    <div
      class="
        bg-purplePrimary
        xl:hidden
        h-[70px]
        rounded-b-3xl
        flex
        items-center
        justify-center
        text-[20px]
        font-bold
        text-white
        relative
      "
      :class="{ 'phone-web-title': deviceType !== 'phone' }"
    >
      <i
        class="
          fa fa-chevron-left
          text-white
          absolute
          ltr:left-[3vw]
          rtl:right-[3vw]
          text-lg
          cursor-pointer
        "
        @click="$router.push('/settings')"
      ></i>

      {{ content.reservations }}
    </div>
    <!-- <div class="xl:block hidden">
      <div
        class="
          xl:flex
          hidden
          layout-padding
          text-[24px]
          font-bold
          mt-8
          text-purplePrimary
        "
      >
        {{ about?.data?.title }}
      </div>
      <Breadcrumb :firstTitle="about?.data?.title" />
    </div> -->
    <div class="mt-8 mb-16 layout-padding">
      <div v-for="reservation in reservations?.data" :key="reservation.id">
        <ReservationCard :reservation="reservation" />
      </div>
    </div>
  </div>
</template>

<script>
import ReservationCard from "/src/components/UI/Cards/ReservationCard.vue";
export default {
  inject: ["content"],
  components: {
    ReservationCard,
  },
  computed: {
    reservations() {
      return this.$store.getters["reservations/reservations"];
    },
    deviceType() {
      return localStorage.getItem("deviceType");
    },
  },
  mounted() {
    this.$store.dispatch("reservations/reservations");
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.add("hidden");
  },
  unmounted() {
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.remove("hidden");
  },
};
</script>