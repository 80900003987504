<template>
  <div
    class="faq-collapse rounded-2xl py-2 bg-white mb-4"
    :class="{ 'faq-opened': openedFaq == id }"
  >
    <div
      class="flex w-full justify-between px-4 items-center"
      @click="$emit('open', id)"
    >
      <div class="font-bold">{{ faq?.question }}</div>
      <div
        class="
          w-[38px]
          cursor-pointer
          h-[38px]
          rounded-full
          bg-yellowPrimary
          flex
          items-center
          justify-center
        "
      >
        <i
          class="fa fa-chevron-down text-purplePrimary"
          :class="{ 'rotate-180 transition-all duration-300': openedFaq == id }"
        ></i>
      </div>
    </div>
    <div class="px-4 py-4 w-11/12 font-medium">
      {{ faq?.answer }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["openedFaq", "id", "faq"],
};
</script>