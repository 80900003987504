<template>
  <div class="favorites relative">
    <div
      class="bg-purplePrimary h-[90px] relative z-0 section-header xl:hidden"
      :style="`background:url(${offerDetails?.data?.entity?.image})`"
    >
      <div class="overlay w-full h-[90px] absolute left-0 top-0"></div>
      <div
        class="
          h-[70px]
          flex
          items-center
          justify-center
          text-[20px]
          font-bold
          text-white
        "
      >
        <i
          class="
            fa fa-chevron-left
            text-white
            absolute
            ltr:left-[3vw]
            rtl:right-[3vw]
            text-lg
            cursor-pointer
          "
          @click="$router.go(-1)"
        ></i>

        <div class="relative capitalize">
          {{ offerDetails?.data?.entity?.title }} {{ content.offers }}
        </div>
      </div>
    </div>
    <div class="hidden xl:block">
      <div
        class="
          xl:flex xl:container xl:mx-auto
          hidden
          layout-padding
          text-[24px]
          font-bold
          mt-8
          text-purplePrimary
        "
      >
        {{ content.offers }}
      </div>
      <Breadcrumb
        :firstTitle="content.offers"
        :secondTitle="offerDetails?.data?.entity?.title"
      />
    </div>
    <div v-if="offerDetails">
      <div
        class="
          mt-[-25px]
          mb-28
          xl:mb-12
          bg-grayBody
          relative
          z-[1]
          pt-1
          rounded-t-3xl
          xl:rounded-none
        "
      >
        <div class="home-discounts xl:container xl:mx-auto layout-padding">
          <div class="grid grid-cols-12 gap-5 mt-4">
            <div
              v-for="offer in offerDetails?.other_offers"
              :key="offer.id"
              class="col-span-12 md:col-span-6 lg:col-span-4"
            >
              <OfferCard :offer="offer" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <AllOffersSkeleton v-else />
  </div>
</template>

<script>
import OfferCard from "/src/components/UI/Cards/OfferCard.vue";
import AllOffersSkeleton from "/src/components/UI/AllOffersSkeleton.vue";

export default {
  props: ["id"],
  data() {
    return {
      isFavorite: false,
      itemName: "",
      type: "offer",
      storeReview: 3,
      discountReview: 3,
    };
  },
  inject: ["content"],
  components: {
    OfferCard,
    AllOffersSkeleton,
  },
  watch: {
    "$route.params.id": {
      handler: function (value) {
        if (this.$route.name == "offerDetails") {
          this.getOfferDetails(value);
        }
      },
      deep: true,
      immediate: true,
    },
    offerDetails() {
      this.itemName = this.type + "_" + this.id;
    },
  },
  computed: {
    offerDetails() {
      return this.$store.getters["offers/offerDetails"];
    },
    favorites() {
      return this.$store.getters["favorites/favorites"];
    },
  },
  methods: {
    setFavorites() {
      let data = {
        item: this.offerDetails?.data,
        type: this.type,
      };
      this.$store.dispatch("favorites/setFavorites", data);

      this.checkIsFavorite();
    },
    clearDetails() {
      this.$store.dispatch("offers/clearOfferDetails");
    },

    checkIsFavorite() {
      if (this.favorites[this.itemName]) {
        this.isFavorite = true;
      } else {
        this.isFavorite = false;
      }
    },
    async getOfferDetails() {
      this.clearDetails();
      await this.$store.dispatch("offers/offerDetails", this.id);
      await this.checkIsFavorite();
    },
  },
  mounted() {
    this.getOfferDetails(this.id);
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.add("hidden");
    this.checkIsFavorite();
  },
  unmounted() {
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.remove("hidden");
    this.clearDetails();
  },
};
</script>

<style scoped>
.swiper-container {
  border-radius: 24px;
}
</style>