<template>
  <div v-if="result">
    <div class="flex items-center justify-between">
      <div>{{ result?.meta?.total }} {{ content.resultsHaveBeenFound }}</div>
    </div>
    <div class="grid grid-cols-12 gap-5 mt-8 mb-24">
      <div
        class="col-span-12 sm:col-span-6 lg:col-span-4"
        v-for="(item, index) in result?.data"
        :key="index" :class="
              item?.discounted_items > 0 ||
                item?.sales_items > 0 ||
                item?.offers_items > 0?'block':'hidden'
                
            "
      >
        <SupermarketsCard :supermarket="item" />
      </div>
    </div>

    <Pagination v-if="result?.meta?.last_page != 1" :data="result" />
  </div>
  <SearchSkeleton v-else></SearchSkeleton>
</template>

<script>
import SupermarketsCard from "/src/components/UI/Cards/SupermarketsCard.vue";
import SearchSkeleton from "/src/components/UI/SearchSkeleton.vue";

export default {
  props: ["result"],
  components: {
    SupermarketsCard,
    SearchSkeleton,
  },
  inject: ["content"],
};
</script>