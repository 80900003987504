<template>
  <div
    class="
      bg-[url(/static/images/splash.png)]
      splash-image
      h-[100vh]
      w-[100vw]
      bg-center
    "
  ></div>
</template>