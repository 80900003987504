export default {
  addFavorites: (state, payload) => {
    state.favorites = { ...state.favorites, [payload.name]: { payload } };
  },
  removeFavorites: (state, payload) => {
    delete state.favorites[payload];
    state.favorites = { ...state.favorites };
  },
  setAllFavorites: (state, payload) => {
    state.favorites = payload;
  },
  setFavoritesCount: (state) => {
    state.favoritesCount = Object.keys(state.favorites).length;
  },
};
