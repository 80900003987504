<template>
  <div class="about xl:container xl:mx-auto">
    <div
      class="
        xl:hidden
        bg-purplePrimary
        h-[70px]
        rounded-b-3xl
        flex
        items-center
        justify-center
        text-[20px]
        font-bold
        text-white
        relative
      "
      :class="{ 'phone-web-title': deviceType !== 'phone' }"
    >
      <i
        class="
          fa fa-chevron-left
          text-white
          absolute
          ltr:left-[3vw]
          rtl:right-[3vw]
          text-lg
          cursor-pointer
        "
        @click="$router.push('/settings')"
      ></i>

      {{ content.FAQ }}
    </div>
    <!-- <div class="xl:block hidden">
      <div
        class="
          xl:flex
          hidden
          layout-padding
          text-[24px]
          font-bold
          mt-8
          text-purplePrimary
        "
      >
        {{ content.faq }}
      </div>
      <Breadcrumb :firstTitle="content.faq" />
    </div> -->
    <div class="mt-8 mb-16 layout-padding">
      <FaqCollapse
        v-for="(faq, index) in faqs?.data"
        :key="index"
        :openedFaq="openedFaq"
        @open="OpenFaq"
        :id="index"
        :faq="faq"
      />
    </div>
  </div>
</template>

<script>
import FaqCollapse from "/src/components/UI/Cards/FaqCollapse.vue";
export default {
  data() {
    return {
      openedFaq: 0,
    };
  },
  inject: ["content"],
  components: { FaqCollapse },
  computed: {
    faqs() {
      return this.$store.getters["faqs/faqs"];
    },
    deviceType() {
      return localStorage.getItem("deviceType");
    },
  },
  methods: {
    OpenFaq(index) {
      if (this.openedFaq == index) {
        this.openedFaq = -1;
      } else {
        this.openedFaq = index;
      }
    },
  },
  mounted() {
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.add("hidden");
  },
  unmounted() {
    let mobileNav = document.getElementById("mobile-nav");
    mobileNav.classList.remove("hidden");
  },
};
</script>