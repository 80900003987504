<template>
  <div class="favorites mb-28 xl:mb-8 xl:container xl:mx-auto">
    <div
      class="
        xl:hidden
        bg-purplePrimary
        h-[70px]
        rounded-b-3xl
        flex
        items-center
        justify-center
        text-[20px]
        font-bold
        text-white
        relative
      "
      :class="{ 'phone-web-title': deviceType !== 'phone' }"
    >
      <i
        class="
          fa fa-chevron-left
          text-white
          absolute
          ltr:left-[3vw]
          rtl:right-[3vw]
          text-lg
          cursor-pointer
        "
        @click="$router.go(-1)"
      ></i>

      {{ content.categories }}
    </div>
    <div class="xl:block hidden">
      <div class="layout-padding text-[24px] font-bold mt-8 text-purplePrimary">
        {{ content.categories }}
      </div>
      <Breadcrumb :firstTitle="content.categories" />
    </div>
    <div class="xl:min-h-[70vh]">
      <div class="mt-8 mb-8 layout-padding grid grid-cols-12 gap-7 lg:gap-10">
        <CategoryCard
          v-for="category in categories?.data"
          :key="category.id"
          :category="category"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CategoryCard from "/src/components/UI/Cards/CategoryCard.vue";
export default {
  data() {
    return {
      willSave: 0,
      offersCount: 0,
    };
  },
  inject: ["content"],
  components: { CategoryCard },

  computed: {
    categories() {
      return this.$store.getters["categories/categories"];
    },
    deviceType() {
      return localStorage.getItem("deviceType");
    },
  },
};
</script>