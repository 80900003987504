import http from "../../request";

export default {
  sales: async (context) => {
    let response = await http.get("sales", localStorage.getItem("lang"));
    context.commit("setSales", response);
  },
  saleDetails: async (context, payload) => {
    let response = await http.get(
      `sales/${payload}`,
      localStorage.getItem("lang")
    );
    context.commit("setSaleDetails", response);
  },
  clearSaleDetails: (context) => {
    context.commit("setSaleDetails", null);
  },
};
